import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { RepoUrlPickerState } from './types';

export const GerritRepoPicker = (props: { onChange: (state: RepoUrlPickerState) => void; state: RepoUrlPickerState; rawErrors: string[] }) => {
  const { onChange, rawErrors, state } = props;
  const { workspace, owner } = state;
  return (
    <>
      <FormControl margin="normal" error={rawErrors?.length > 0 && !workspace}>
        <InputLabel htmlFor="ownerInput">Owner</InputLabel>
        <Input id="ownerInput" onChange={e => onChange({ owner: e.target.value })} value={owner} />
        <FormHelperText>The owner of the project (optional)</FormHelperText>
      </FormControl>
      <FormControl margin="normal" required error={rawErrors?.length > 0 && !workspace}>
        <InputLabel htmlFor="parentInput">Parent</InputLabel>
        <Input id="parentInput" onChange={e => onChange({ workspace: e.target.value })} value={workspace} />
        <FormHelperText>The project parent that the repo will belong to</FormHelperText>
      </FormControl>
    </>
  );
};
