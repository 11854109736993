import { useElementFilter } from '@backstage/core-plugin-api';
import { LAYOUTS_KEY, LAYOUTS_WRAPPER_KEY } from '../layouts/keys';
import { LayoutOptions } from '../layouts';

/**
 * Hook that returns all custom field extensions from the current outlet.
 * @public
 */
export const useCustomLayouts = <TComponentDataType = LayoutOptions>(outlet: React.ReactNode) => {
  return useElementFilter(outlet, elements =>
    elements
      .selectByComponentData({
        key: LAYOUTS_WRAPPER_KEY,
      })
      .findComponentData<TComponentDataType>({
        key: LAYOUTS_KEY,
      }),
  );
};
