import type { FieldValidation } from '@rjsf/utils';
import { KubernetesValidatorFunctions } from '@backstage/catalog-model';
import { ApiHolder, configApiRef } from '@backstage/core-plugin-api';
import { JsonObject } from '@backstage/config';

const validateNamespaceName = async (fetchUrl: string, value: string): Promise<{ nsRegexp: string; result: boolean }> => {
  const resp = await fetch(`${fetchUrl}`).then(res => res.json());
  const namespaceRegexp = new RegExp(resp.nsRegexp);

  return { nsRegexp: resp.nsRegexp, result: namespaceRegexp.test(value) };
};

export const namespacePickerValidation = async (value: string, validation: FieldValidation, { apiHolder, formData }: { apiHolder: ApiHolder; formData: JsonObject }) => {
  const configApi = apiHolder.get(configApiRef);
  const backendUrl = configApi?.getString('backend.baseUrl');
  const fetchUrl = `${backendUrl}/api/argocd/getNamespaces/${formData?.argocdInstanceName}`;
  const { nsRegexp, result } = await validateNamespaceName(fetchUrl, value);

  if (!result) {
    validation.addError(`Namespace name is not match allowed pattern "${nsRegexp}"`);
  }

  if (!KubernetesValidatorFunctions.isValidObjectName(value)) {
    validation.addError(
      'Must start and end with an alphanumeric character, and contain only alphanumeric characters, hyphens, underscores, and periods. Maximum length is 63 characters.',
    );
  }
};
