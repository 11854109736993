import { BackstageTheme } from '@backstage/theme';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import { useTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useAsync } from '@react-hookz/web';
import Cancel from '@mui/icons-material/Cancel';
import Retry from '@mui/icons-material/Repeat';
import Toc from '@mui/icons-material/Toc';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import MoreVert from '@mui/icons-material/MoreVert';
import React, { useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { scaffolderApiRef } from '@backstage/plugin-scaffolder-react';

type ContextMenuProps = {
  cancelEnabled?: boolean;
  logsVisible?: boolean;
  buttonBarVisible?: boolean;
  onStartOver?: () => void;
  onToggleLogs?: (state: boolean) => void;
  onToggleButtonBar?: (state: boolean) => void;
  taskId?: string;
};

const useStyles = makeStyles<BackstageTheme, { fontColor: string }>(() => ({
  button: {
    color: ({ fontColor }) => fontColor,
  },
}));

export const ContextMenu = (props: ContextMenuProps) => {
  const { cancelEnabled, logsVisible, buttonBarVisible, onStartOver, onToggleLogs, onToggleButtonBar, taskId } = props;
  const { getPageTheme } = useTheme<Theme>();
  const pageTheme = getPageTheme({ themeId: 'website' });
  const classes = useStyles({ fontColor: pageTheme.fontColor });
  const scaffolderApi = useApi(scaffolderApiRef);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();

  const [{ status: cancelStatus }, { execute: cancel }] = useAsync(async () => {
    if (taskId) {
      await scaffolderApi.cancelTask(taskId);
    }
  });

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={(event: React.SyntheticEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget);
        }}
        data-testid="menu-button"
        className={classes.button}
        size="large"
      >
        <MoreVert />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(undefined)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuList>
          <MenuItem onClick={() => onToggleLogs?.(!logsVisible)}>
            <ListItemIcon>
              <Toc fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={logsVisible ? 'Hide Logs' : 'Show Logs'} />
          </MenuItem>
          <MenuItem onClick={() => onToggleButtonBar?.(!buttonBarVisible)}>
            <ListItemIcon>
              <ControlPointIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={buttonBarVisible ? 'Hide Button Bar' : 'Show Button Bar'} />
          </MenuItem>
          <MenuItem onClick={onStartOver}>
            <ListItemIcon>
              <Retry fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Start Over" />
          </MenuItem>
          <MenuItem onClick={cancel} disabled={!cancelEnabled || cancelStatus !== 'not-executed'} data-testid="cancel-task">
            <ListItemIcon>
              <Cancel fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Cancel" />
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  );
};
