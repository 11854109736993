import { Components } from '@mui/material/styles';
import { darken } from '@mui/material/styles';
import colors from '../colors';

export const DataDisplay: Pick<Components, 'MuiChip' | 'MuiTooltip' | 'MuiAlert' | 'MuiDialog' | 'MuiMenuItem' | 'MuiStepper'> = {
  MuiChip: {
    defaultProps: {
      variant: 'outlined',
    },
    styleOverrides: {
      root: ({ theme }: any) => {
        const shadowColor = theme.palette.mode === 'dark' ? colors.atomicColors.black : colors.backgrounds.dark.contrastText;
        return {
          marginRight: theme.spacing(1),
          marginBottom: theme.spacing(1),
          padding: '5px 15px',
          boxShadow: `0 0 10px ${shadowColor}`,
        };
      },
      outlined: ({ theme }: any) => ({
        color: theme.palette.text.primary,
        border: `1px solid ${colors.system.teal.main}`,
        boxShadow: 'none',
        backgroundColor: theme.palette.mode === 'dark' ? colors.backgrounds.dark.main : colors.backgrounds.light.main,
      }),
      label: ({ theme }: any) => ({
        lineHeight: theme.spacing(2.5),
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.spacing(1.75),
      }),
      labelSmall: ({ theme }: any) => ({
        fontSize: theme.spacing(1.5),
      }),
      deleteIcon: ({ theme }: any) => ({
        color: theme.palette.grey[500],
        width: theme.spacing(3),
        height: theme.spacing(3),
        margin: `0 ${theme.spacing(0.75)} 0 -${theme.spacing(0.75)}`,
      }),
      deleteIconSmall: ({ theme }: any) => ({
        width: theme.spacing(2),
        height: theme.spacing(2),
        margin: `0 ${theme.spacing(0.5)} 0 -${theme.spacing(0.5)}`,
      }),
    },
  },
  MuiTooltip: {
    defaultProps: {
      arrow: true,
    },
    styleOverrides: {
      tooltip: {
        backgroundColor: darken(colors.backgrounds.dark.dark, 0.35),
        padding: '8px 16px',
        fontSize: '10px',
      },
      arrow: {
        color: darken(colors.backgrounds.dark.dark, 0.35),
      },
    },
  },
  MuiAlert: {
    defaultProps: {
      variant: 'standard',
    },
    styleOverrides: {
      root: {},
      standardSuccess: ({ theme }: any) => ({
        backgroundColor: theme.palette.mode === 'light' ? colors.system.blue.contrastText : colors.backgrounds.dark.light,
        borderTop: theme.palette.mode === 'light' ? 'none' : `3px solid ${colors.system.blue.dark}`,
        borderLeft: theme.palette.mode === 'light' ? `3px solid ${colors.system.blue.dark}` : 'none',
        color: theme.palette.mode === 'light' ? colors.backgrounds.dark.dark : colors.backgrounds.light.main,
      }),
      standardInfo: ({ theme }: any) => ({
        backgroundColor: theme.palette.mode === 'light' ? colors.system.blue.contrastText : colors.backgrounds.dark.light,
        borderTop: theme.palette.mode === 'light' ? 'none' : `3px solid ${colors.system.blue.dark}`,
        borderLeft: theme.palette.mode === 'light' ? `3px solid ${colors.system.blue.dark}` : 'none',
        color: theme.palette.mode === 'light' ? colors.backgrounds.dark.dark : colors.backgrounds.light.main,
      }),
      standardWarning: ({ theme }: any) => ({
        backgroundColor: theme.palette.mode === 'light' ? colors.system.blue.contrastText : colors.backgrounds.dark.light,
        borderTop: theme.palette.mode === 'light' ? 'none' : `3px solid ${colors.system.blue.dark}`,
        borderLeft: theme.palette.mode === 'light' ? `3px solid ${colors.system.blue.dark}` : 'none',
        color: theme.palette.mode === 'light' ? colors.backgrounds.dark.dark : colors.backgrounds.light.main,
      }),
      standardError: ({ theme }: any) => ({
        backgroundColor: theme.palette.mode === 'light' ? colors.system.blue.contrastText : colors.backgrounds.dark.light,
        borderTop: theme.palette.mode === 'light' ? 'none' : `3px solid ${colors.system.blue.dark}`,
        borderLeft: theme.palette.mode === 'light' ? `3px solid ${colors.system.blue.dark}` : 'none',
        color: theme.palette.mode === 'light' ? colors.backgrounds.dark.dark : colors.backgrounds.light.main,
      }),
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        backgroundImage: 'none',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: colors.primary.main,
          color: colors.backgrounds.light.main,
          '&:hover': {
            backgroundColor: colors.primary.light,
          },
        },
      },
    },
  },
  MuiStepper: {
    styleOverrides: {
      root: {
        padding: '1rem',
      },
    },
  },
};
