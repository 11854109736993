import { DateTime, Interval } from 'luxon';
import humanizeDuration from 'humanize-duration';
import React from 'react';
import Typography from '@mui/material/Typography';

export const CreatedAtColumn = ({ createdAt }: { createdAt: string }) => {
  const createdAtTime = DateTime.fromISO(createdAt);
  const formatted = Interval.fromDateTimes(createdAtTime, DateTime.local()).toDuration().valueOf();

  return <Typography paragraph>{humanizeDuration(formatted, { round: true })} ago</Typography>;
};
