import { ANNOTATION_SOURCE_LOCATION, Entity, parseLocationRef } from '@backstage/catalog-model';
import { ScmIntegrationRegistry } from '@backstage/integration';

/** @public */
export type EntitySourceLocation = {
  locationTargetUrl: string;
  integrationType?: string;
};

/** @public */
export function getEntitySourceLocation(entity: Entity, scmIntegrationsApi: ScmIntegrationRegistry): EntitySourceLocation | undefined {
  const sourceLocation = entity.metadata.annotations?.[ANNOTATION_SOURCE_LOCATION];

  if (!sourceLocation) {
    return undefined;
  }

  try {
    const sourceLocationRef = parseLocationRef(sourceLocation);
    const integration = scmIntegrationsApi.byUrl(sourceLocationRef.target);
    return {
      locationTargetUrl: sourceLocationRef.target,
      integrationType: integration?.type,
    };
  } catch {
    return undefined;
  }
}
