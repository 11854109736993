import { Components } from '@mui/material/styles';
import colors from '../colors';

const { light, dark } = colors.backgrounds;

export const Input: Pick<Components, 'MuiInput' | 'MuiInputBase' | 'MuiTextField' | 'MuiInputLabel' | 'MuiCheckbox' | 'MuiFormControlLabel' | 'MuiRadio' | 'MuiSwitch'> = {
  MuiInputBase: {
    styleOverrides: {
      root: {
        input: {
          padding: '6px 8px 10px 12px',
          fontSize: '14px',
        },
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'outlined',
    },
    styleOverrides: {
      root: {
        '& legend': {
          display: 'none',
        },
      },
    },
  },
  MuiInputLabel: {
    defaultProps: {
      shrink: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        position: 'relative',
        fontSize: '12px',
        fontFamily: 'Inter',
        fontWeight: 500,
        lineHeight: '16px',
        transform: 'translate(14px, -9px) scale(1)',
        color: theme.palette.mode === 'dark' ? light.main : dark.light,
        '&.Mui-focused': {
          color: theme.palette.mode === 'dark' ? light.main : dark.light,
        },
        '&.Mui-disabled': {
          color: theme.palette.mode === 'dark' ? light.main : dark.light,
        },
      }),
    },
  },
  MuiInput: {
    defaultProps: {
      type: 'contained',
    },
    styleOverrides: {
      root: {
        borderRadius: '8px',
      },
    },
  },
  MuiCheckbox: {
    defaultProps: {
      size: 'small',
    },
    styleOverrides: {
      root: {
        '&.MuiButtonBase-root': {
          padding: '1rem',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
  MuiRadio: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        fontSize: '12px',
      },
    },
  },
  MuiSwitch: {
    defaultProps: {
      disableRipple: true,
      size: 'small',
    },
    styleOverrides: {
      root: ({ theme }) => ({
        width: '24px',
        height: '16px',
        padding: '0',
        margin: '0 0.5rem',
        '& .v5-MuiSwitch-thumb': {
          width: '12px',
          height: '12px',
          backgroundColor: colors.backgrounds.light.main,
        },
        '& .v5-MuiSwitch-switchBase': {
          padding: '2px',
          '&.Mui-checked': {
            transform: 'translateX(8px)',
            '&+.v5-MuiSwitch-track': {
              backgroundColor: colors.primary.main,
              opacity: 1,
              border: 'none',
            },
            '&.Mui-disabled': {
              '&+.v5-MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'light' ? colors.backgrounds.light.light : colors.atomicColors.darkGrey,
              },
            },
          },
          '&.Mui-disabled': {
            '& .v5-MuiSwitch-thumb': {
              backgroundColor: colors.atomicColors.darkGrey,
              // border: `1px solid ${colors.backgrounds.light.light}`
            },
            '&+.v5-MuiSwitch-track': {
              backgroundColor: theme.palette.mode === 'light' ? colors.backgrounds.light.light : colors.atomicColors.darkGrey,
            },
          },
        },
      }),
      track: ({ theme }) => ({
        backgroundColor: 'inherit',
        border: `1px solid ${theme.palette.mode === 'light' ? colors.backgrounds.dark.light : colors.backgrounds.light.light}`,
        opacity: 1,
      }),
    },
  },
};
