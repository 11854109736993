import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import makeStyles from '@mui/styles/makeStyles';
import Clear from '@mui/icons-material/Clear';
import Search from '@mui/icons-material/Search';
import React, { useState } from 'react';
import useDebounce from 'react-use/lib/useDebounce';
import { useEntityList } from '../../hooks';
import { EntityTextFilter } from '../../filters';

/** @public */
export type CatalogReactEntitySearchBarClassKey = 'searchToolbar' | 'input';

const useStyles = makeStyles(
  () => ({
    searchToolbar: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    input: {},
  }),
  {
    name: 'CatalogReactEntitySearchBar',
  },
);

/**
 * Renders search bar for filtering the entity list.
 * @public
 */
export const EntitySearchBar = () => {
  const classes = useStyles();

  const { filters, updateFilters } = useEntityList();
  const [search, setSearch] = useState(filters.text?.value ?? '');

  useDebounce(
    () => {
      updateFilters({
        text: search.length ? new EntityTextFilter(search) : undefined,
      });
    },
    250,
    [search, updateFilters],
  );

  return (
    <Toolbar className={classes.searchToolbar}>
      <FormControl>
        <Input
          aria-label="search"
          id="input-with-icon-adornment"
          className={classes.input}
          placeholder="Search"
          autoComplete="off"
          onChange={event => setSearch(event.target.value)}
          value={search}
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="clear search" onClick={() => setSearch('')} edge="end" disabled={search.length === 0} size="large">
                <Clear />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </Toolbar>
  );
};
