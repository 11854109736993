import { z } from 'zod';
import { makeFieldSchemaFromZod } from '../utils';

/**
 * @public
 */
export const EntityTagsPickerFieldSchema = makeFieldSchemaFromZod(
  z.array(z.string()),
  z.object({
    kinds: z.array(z.string()).optional().describe('List of kinds of entities to derive tags from'),
    showCounts: z.boolean().optional().describe('Whether to show usage counts per tag'),
    helperText: z.string().optional().describe('Helper text to display'),
  }),
);

export const EntityTagsPickerSchema = EntityTagsPickerFieldSchema.schema;

export type EntityTagsPickerProps = typeof EntityTagsPickerFieldSchema.type;

/**
 * The input props that can be specified under `ui:options` for the
 * `EntityTagsPicker` field extension.
 *
 * @public
 */
export type EntityTagsPickerUiOptions = typeof EntityTagsPickerFieldSchema.uiOptionsType;
