import { createVersionedContext, createVersionedValueMap } from '@backstage/version-bridge';
import React, { createContext, ReactNode, useContext } from 'react';

/**
 * Type of `SidebarPinStateContext`
 *
 * @public
 * @deprecated Use `SidebarPinState` instead.
 */
export type SidebarPinStateContextType = {
  isPinned: boolean;
  toggleSidebarPinState: () => any;
  isMobile?: boolean;
};

/**
 * The pin state of the sidebar.
 *
 * @public
 */
export type SidebarPinState = {
  /**
   * Whether or not the sidebar is pinned to the `open` state. When `isPinned`
   * is `false`, the sidebar opens and closes on hover. When `true`, the
   * sidebar is permanently opened, regardless of user interaction.
   */
  isPinned: boolean;

  /**
   * A function to toggle the pin state of the sidebar.
   */
  toggleSidebarPinState: () => any;

  /**
   * Whether or not the sidebar is or should be rendered in a mobile-optimized
   * way.
   */
  isMobile?: boolean;
};

const defaultSidebarPinStateContext = {
  isPinned: true,
  toggleSidebarPinState: () => {},
  isMobile: false,
};

/**
 * Contains the state on how the `Sidebar` is rendered
 *
 * @public @deprecated
 * Use `<SidebarPinStateContextProvider>` + `useSidebarPinState()` instead.
 */
export const LegacySidebarPinStateContext = createContext<SidebarPinStateContextType>(defaultSidebarPinStateContext);

const VersionedSidebarPinStateContext = createVersionedContext<{
  1: SidebarPinState;
}>('sidebar-pin-state-context');

/**
 * Provides state for how the `Sidebar` is rendered
 *
 * @public
 */
export function SidebarPinStateProvider(props: { children: ReactNode; value: SidebarPinStateContextType }) {
  const { children, value } = props;
  return (
    <LegacySidebarPinStateContext.Provider value={value}>
      <VersionedSidebarPinStateContext.Provider value={createVersionedValueMap({ 1: value })}>{children}</VersionedSidebarPinStateContext.Provider>
    </LegacySidebarPinStateContext.Provider>
  );
}

/**
 * Hook to read and update sidebar pin state, which controls whether or not the
 * sidebar is pinned open.
 *
 * @public
 */
export const useSidebarPinState = (): SidebarPinState => {
  const versionedPinStateContext = useContext(VersionedSidebarPinStateContext);
  const legacyPinStateContext = useContext(LegacySidebarPinStateContext);

  // Invoked from outside a SidebarPinStateProvider: check for the legacy
  // context's value, but otherwise return the default.
  if (versionedPinStateContext === undefined) {
    return legacyPinStateContext || defaultSidebarPinStateContext;
  }

  const pinStateContext = versionedPinStateContext.atVersion(1);
  if (pinStateContext === undefined) {
    throw new Error('No context found for version 1.');
  }

  return pinStateContext;
};
