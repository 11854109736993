import { Entity } from '@backstage/catalog-model';
import { IconButton, Tooltip, withStyles } from '@material-ui/core';
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import React, { ComponentProps } from 'react';
import { useStarredEntity } from '../../hooks';

/** @public */
export type FavoriteEntityProps = ComponentProps<typeof IconButton> & {
  entity: Entity;
};

const YellowStar = withStyles({
  root: {
    color: '#f3ba37',
  },
})(Star);

/**
 * IconButton for showing if a current entity is starred and adding/removing it from the favorite entities
 * @param props - MaterialUI IconButton props extended by required `entity` prop
 * @public
 */
export const FavoriteEntity = (props: FavoriteEntityProps) => {
  const { toggleStarredEntity, isStarredEntity } = useStarredEntity(props.entity);
  return (
    <IconButton aria-label="favorite" color="inherit" {...props} onClick={() => toggleStarredEntity()}>
      <Tooltip title={isStarredEntity ? 'Remove from favorites' : 'Add to favorites'}>{isStarredEntity ? <YellowStar /> : <StarBorder />}</Tooltip>
    </IconButton>
  );
};
