import { createUnifiedTheme, genPageTheme, shapes } from '@backstage/theme';
import colorSet from './palettes/colors';
import lightThemePalette from './palettes/lightThemePalette';

const tmaticLightTheme = createUnifiedTheme({
  palette: {
    ...lightThemePalette,
  },

  defaultPageTheme: 'home',
  fontFamily: 'Inter',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({
      colors: [colorSet.system.blue.dark, colorSet.system.red.dark],
      shape: shapes.round,
    }),
  },
});

export default tmaticLightTheme;
