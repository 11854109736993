import React, { ChangeEvent, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { AutocompleteRenderGetTagProps, AutocompleteRenderInputParams } from '@mui/material/Autocomplete';

import { useSearch } from '../../context';
import { useAsyncFilterValues, useDefaultFilterValue } from './hooks';
import { SearchFilterComponentProps } from './SearchFilter';

/**
 * @public
 */
export type SearchAutocompleteFilterProps = SearchFilterComponentProps & {
  filterSelectedOptions?: boolean;
  limitTags?: number;
  multiple?: boolean;
};

/**
 * @public
 */
export const AutocompleteFilter = (props: SearchAutocompleteFilterProps) => {
  const { className, defaultValue, name, values: givenValues, valuesDebounceMs, label, filterSelectedOptions, limitTags, multiple } = props;
  const [inputValue, setInputValue] = useState<string>('');
  useDefaultFilterValue(name, defaultValue);
  const asyncValues = typeof givenValues === 'function' ? givenValues : undefined;
  const defaultValues = typeof givenValues === 'function' ? undefined : givenValues;
  const { value: values, loading } = useAsyncFilterValues(asyncValues, inputValue, defaultValues, valuesDebounceMs);
  const { filters, setFilters } = useSearch();
  const filterValue = (filters[name] as string | string[] | undefined) || (multiple ? [] : null);

  // Set new filter values on input change.
  const handleChange = (_: ChangeEvent<{}>, newValue: string | string[] | null) => {
    setFilters(prevState => {
      const { [name]: filter, ...others } = prevState;

      if (newValue) {
        return { ...others, [name]: newValue };
      }
      return { ...others };
    });
  };

  // Provide the input field.
  const renderInput = (params: AutocompleteRenderInputParams) => <TextField {...params} name="search" variant="outlined" label={label} fullWidth />;

  // Render tags as primary-colored chips.
  const renderTags = (tagValue: string[], getTagProps: AutocompleteRenderGetTagProps) =>
    tagValue.map((option: string, index: number) => <Chip label={option} color="primary" {...getTagProps({ index })} />);

  return (
    <Autocomplete
      filterSelectedOptions={filterSelectedOptions}
      limitTags={limitTags}
      multiple={multiple}
      className={className}
      id={`${multiple ? 'multi-' : ''}select-filter-${name}--select`}
      options={values || []}
      loading={loading}
      value={filterValue}
      onChange={handleChange}
      onInputChange={(_, newValue) => setInputValue(newValue)}
      renderInput={renderInput}
      renderTags={renderTags}
    />
  );
};
