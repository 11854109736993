import { BackstageTheme } from '@backstage/theme';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { FileBrowser } from '../FileBrowser';
import { useDirectoryEditor } from './DirectoryEditorContext';

const useStyles = makeStyles(() => {
  const theme = useTheme<BackstageTheme>();
  return {
    button: {
      padding: theme.spacing(1),
    },
    buttons: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    buttonsGap: {
      flex: '1 1 auto',
    },
    buttonsDivider: {
      marginBottom: theme.spacing(1),
    },
  };
});

/** The local file browser for the template editor */
export function TemplateEditorBrowser(props: { onClose?: () => void }) {
  const classes = useStyles();
  const directoryEditor = useDirectoryEditor();
  const changedFiles = directoryEditor.files.filter(file => file.dirty);

  const handleClose = () => {
    if (!props.onClose) {
      return;
    }
    if (changedFiles.length > 0) {
      // eslint-disable-next-line no-alert
      const accepted = window.confirm('Are you sure? Unsaved changes will be lost');
      if (!accepted) {
        return;
      }
    }
    props.onClose();
  };

  return (
    <>
      <div className={classes.buttons}>
        <Tooltip title="Save all files">
          <IconButton className={classes.button} disabled={directoryEditor.files.every(file => !file.dirty)} onClick={() => directoryEditor.save()} size="large">
            <SaveIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Reload directory">
          <IconButton className={classes.button} onClick={() => directoryEditor.reload()} size="large">
            <RefreshIcon />
          </IconButton>
        </Tooltip>
        <div className={classes.buttonsGap} />
        <Tooltip title="Close directory">
          <IconButton className={classes.button} onClick={handleClose} size="large">
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Divider className={classes.buttonsDivider} />
      <FileBrowser selected={directoryEditor.selectedFile?.path ?? ''} onSelect={directoryEditor.setSelectedFile} filePaths={directoryEditor.files.map(file => file.path)} />
    </>
  );
}
