import { TemplateEntityV1beta3 } from '@backstage/plugin-scaffolder-common';
import React from 'react';
import { Content, ContentHeader, ItemCardGrid } from '@backstage/core-components';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { TemplateCardProps, TemplateCard } from '../TemplateCard';
import { IconComponent } from '@backstage/core-plugin-api';

/**
 * The props for the {@link TemplateGroup} component.
 * @alpha
 */
export interface TemplateGroupProps {
  templates: {
    template: TemplateEntityV1beta3;
    additionalLinks?: {
      icon: IconComponent;
      text: string;
      url: string;
    }[];
  }[];
  onSelected: (template: TemplateEntityV1beta3) => void;
  title: React.ReactNode;
  components?: {
    CardComponent?: React.ComponentType<TemplateCardProps>;
  };
}

/**
 * The `TemplateGroup` component is used to display a group of templates with a title.
 * @alpha
 */
export const TemplateGroup = (props: TemplateGroupProps) => {
  const { templates, title, components: { CardComponent } = {}, onSelected } = props;
  const titleComponent = typeof title === 'string' ? <ContentHeader title={title} /> : title;

  if (templates.length === 0) {
    return null;
  }

  const Card = CardComponent || TemplateCard;

  return (
    <Content>
      {titleComponent}
      <ItemCardGrid>
        {templates.map(({ template, additionalLinks }) => (
          <Card key={stringifyEntityRef(template)} additionalLinks={additionalLinks} template={template} onSelected={onSelected} />
        ))}
      </ItemCardGrid>
    </Content>
  );
};
