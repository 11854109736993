import { stringifyEntityRef } from '@backstage/catalog-model';
import { StorageApi } from '@backstage/core-plugin-api';
import { isArray, isString } from 'lodash';

/**
 * Migrate the starred entities from the old format (entity:<kind>:<namespace>:<name>) from the
 * old storage location (/settings/starredEntities) to entity references in the new location
 * (/starredEntities/entityRefs).
 *
 * This will only be executed once since the old location is cleared.
 *
 * @param storageApi - the StorageApi to migrate
 */
export async function performMigrationToTheNewBucket({ storageApi }: { storageApi: StorageApi }) {
  const source = storageApi.forBucket('settings');
  const target = storageApi.forBucket('starredEntities');

  const oldStarredEntities = source.snapshot('starredEntities').value;

  if (!isArray(oldStarredEntities)) {
    // nothing to do
    return;
  }
  const targetEntities = new Set(target.snapshot<string[]>('entityRefs').value ?? []);

  oldStarredEntities
    .filter(isString)
    // extract the old format 'entity:<kind>:<namespace>:<name>'
    .map(old => old.split(':'))
    // check if the format is valid
    .filter(split => split.length === 4 && split[0] === 'entity')
    // convert to entity references
    .map(([_, kind, namespace, name]) => stringifyEntityRef({ kind, namespace, name }))
    .forEach(e => targetEntities.add(e));

  await target.set('entityRefs', Array.from(targetEntities));

  await source.remove('starredEntities');
}
