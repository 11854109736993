import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BackstageTheme } from '@backstage/theme';
import { DependencyGraphTypes as Types } from './types';

/** @public */
export type DependencyGraphDefaultNodeClassKey = 'node' | 'text';

const useStyles = makeStyles(
  (theme: BackstageTheme) => ({
    node: {
      fill: theme.palette.primary.light,
      stroke: theme.palette.primary.light,
    },
    text: {
      fill: theme.palette.primary.contrastText,
    },
  }),
  { name: 'BackstageDependencyGraphDefaultNode' },
);

/** @public */
export function DefaultNode({ node: { id } }: Types.RenderNodeProps) {
  const classes = useStyles();
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  const idRef = React.useRef<SVGTextElement | null>(null);

  React.useLayoutEffect(() => {
    // set the width to the length of the ID
    if (idRef.current) {
      let { height: renderedHeight, width: renderedWidth } = idRef.current.getBBox();
      renderedHeight = Math.round(renderedHeight);
      renderedWidth = Math.round(renderedWidth);

      if (renderedHeight !== height || renderedWidth !== width) {
        setWidth(renderedWidth);
        setHeight(renderedHeight);
      }
    }
  }, [width, height]);

  const padding = 10;
  const paddedWidth = width + padding * 2;
  const paddedHeight = height + padding * 2;

  return (
    <g>
      <rect className={classes.node} width={paddedWidth} height={paddedHeight} rx={10} />
      <text ref={idRef} className={classes.text} y={paddedHeight / 2} x={paddedWidth / 2} textAnchor="middle" alignmentBaseline="middle">
        {id}
      </text>
    </g>
  );
}
