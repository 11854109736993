import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React, { PropsWithChildren } from 'react';
import { CopyTextButton } from '../CopyTextButton';
import { WarningPanel } from '../WarningPanel';

/** @public */
export type ErrorPanelClassKey = 'text' | 'divider';

const useStyles = makeStyles(
  theme => ({
    text: {
      fontFamily: 'monospace',
      whiteSpace: 'pre',
      overflowX: 'auto',
      marginRight: theme.spacing(2),
    },
    divider: {
      margin: theme.spacing(2),
    },
  }),
  { name: 'BackstageErrorPanel' },
);

type ErrorListProps = {
  error: string;
  message: string;
  request?: string;
  stack?: string;
  json?: string;
};

const ErrorList = ({ error, message, stack, children }: PropsWithChildren<ErrorListProps>) => {
  const classes = useStyles();

  return (
    <List dense>
      <ListItem alignItems="flex-start">
        <ListItemText classes={{ secondary: classes.text }} primary="Error" secondary={error} />
        <CopyTextButton text={error} />
      </ListItem>

      <ListItem alignItems="flex-start">
        <ListItemText classes={{ secondary: classes.text }} primary="Message" secondary={message} />
        <CopyTextButton text={message} />
      </ListItem>

      {stack && (
        <ListItem alignItems="flex-start">
          <ListItemText classes={{ secondary: classes.text }} primary="Stack Trace" secondary={stack} />
          <CopyTextButton text={stack} />
        </ListItem>
      )}

      {children}
    </List>
  );
};

/** @public */
export type ErrorPanelProps = {
  error: Error;
  defaultExpanded?: boolean;
  title?: string;
};

/**
 * Renders a warning panel as the effect of an error.
 *
 * @public
 */
export function ErrorPanel(props: PropsWithChildren<ErrorPanelProps>) {
  const { title, error, defaultExpanded, children } = props;
  return (
    <WarningPanel severity="error" title={title ?? error.message} defaultExpanded={defaultExpanded}>
      <ErrorList error={error.name} message={error.message} stack={error.stack} children={children} />
    </WarningPanel>
  );
}
