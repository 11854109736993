import React from 'react';
import capitalize from 'lodash/capitalize';
import { Progress } from '@backstage/core-components';
import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Autocomplete } from '@material-ui/lab';
import { useEntityTypeFilter } from '@backstage/plugin-catalog-react';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/**
 * The Category Picker that is rendered on the left side for picking
 * categories and filtering the template list.
 * @alpha
 */
export const TemplateCategoryPicker = () => {
  const alertApi = useApi(alertApiRef);
  const { error, loading, availableTypes, selectedTypes, setSelectedTypes } = useEntityTypeFilter();

  if (loading) return <Progress />;

  if (error) {
    alertApi.post({
      message: `Failed to load entity types with error: ${error}`,
      severity: 'error',
    });
    return null;
  }

  if (!availableTypes) return null;

  return (
    <Box pb={1} pt={1}>
      <Typography variant="button" component="label" htmlFor="categories-picker">
        Categories
      </Typography>
      <Autocomplete
        multiple
        id="categories-picker"
        options={availableTypes}
        value={selectedTypes}
        onChange={(_: object, value: string[]) => setSelectedTypes(value)}
        renderOption={(option, { selected }) => <FormControlLabel control={<Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />} label={capitalize(option)} />}
        size="small"
        popupIcon={<ExpandMoreIcon />}
        renderInput={params => <TextField {...params} variant="outlined" />}
      />
    </Box>
  );
};
