import { LAYOUTS_KEY, LAYOUTS_WRAPPER_KEY } from './keys';
import { attachComponentData, Extension } from '@backstage/core-plugin-api';
import type { FormProps as SchemaFormProps } from '@rjsf/core-v5';
import React from 'react';

/**
 * The field template from `@rjsf/core` which is a react component that gets passed `@rjsf/core` field related props.
 *
 * @public
 */
export type LayoutTemplate<T = any> = NonNullable<SchemaFormProps<T>['uiSchema']>['ui:ObjectFieldTemplate'];

/**
 * The type of layouts that is passed to the TemplateForms
 *
 * @public
 */
export interface LayoutOptions<P = any> {
  name: string;
  component: LayoutTemplate<P>;
}

/**
 * A type used to wrap up the FieldExtension to embed the ReturnValue and the InputProps
 * @public
 */
export type LayoutComponent<_TInputProps> = () => null;

/**
 * Method for creating custom Layouts that can be used in the scaffolder frontend form
 *
 * @public
 */
export function createScaffolderLayout<TInputProps = unknown>(options: LayoutOptions): Extension<LayoutComponent<TInputProps>> {
  return {
    expose() {
      const LayoutDataHolder: any = () => null;

      attachComponentData(LayoutDataHolder, LAYOUTS_KEY, options);

      return LayoutDataHolder;
    },
  };
}

/**
 * The wrapping component for defining scaffolder layouts as children
 *
 * @public
 */
export const ScaffolderLayouts: React.ComponentType<React.PropsWithChildren<{}>> = (): JSX.Element | null => null;

attachComponentData(ScaffolderLayouts, LAYOUTS_WRAPPER_KEY, true);
