import { useApi } from '@backstage/core-plugin-api';
import React from 'react';

import useAsync from 'react-use/lib/useAsync';

import { catalogApiRef, EntityRefLink } from '@backstage/plugin-catalog-react';
import { parseEntityRef, UserEntity } from '@backstage/catalog-model';
import Typography from '@mui/material/Typography';

export const OwnerEntityColumn = ({ entityRef }: { entityRef?: string }) => {
  const catalogApi = useApi(catalogApiRef);

  const { value, loading, error } = useAsync(() => catalogApi.getEntityByRef(entityRef || ''), [catalogApi, entityRef]);

  if (!entityRef) {
    return <Typography paragraph>Unknown</Typography>;
  }

  if (loading || error) {
    return null;
  }

  return <EntityRefLink entityRef={parseEntityRef(entityRef)} title={(value as UserEntity)?.spec?.profile?.displayName ?? value?.metadata.name} />;
};
