import { Entity } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteEntityDialog } from './DeleteEntityDialog';
import { useRouteRef } from '@backstage/core-plugin-api';
import { rootRouteRef } from '../../routes';

/**
 * Returns true if the given entity has the orphan annotation given by the
 * catalog.
 *
 * @public
 */
export function isOrphan(entity: Entity): boolean {
  return entity?.metadata?.annotations?.['backstage.io/orphan'] === 'true';
}

/**
 * Displays a warning alert if the entity is marked as orphan with the ability
 * to delete said entity.
 *
 * @public
 */
export function EntityOrphanWarning() {
  const navigate = useNavigate();
  const catalogLink = useRouteRef(rootRouteRef);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const { entity } = useEntity();

  const cleanUpAfterRemoval = async () => {
    setConfirmationDialogOpen(false);
    navigate(catalogLink());
  };

  return (
    <>
      <Alert severity="warning" onClick={() => setConfirmationDialogOpen(true)}>
        This entity is not referenced by any location and is therefore not receiving updates. Click here to delete.
      </Alert>
      <DeleteEntityDialog open={confirmationDialogOpen} entity={entity!} onConfirm={cleanUpAfterRemoval} onClose={() => setConfirmationDialogOpen(false)} />
    </>
  );
}
