import React from 'react';
import { CodeSnippet } from '@backstage/core-components';
import { useTheme } from '@material-ui/core/styles';
import { BackstageTheme } from '@backstage/theme';

/** @public */
export type TrpcApiDefinitionWidgetProps = {
  definition: string;
};

/** @public */
export const TrpcApiDefinitionWidget = (props: TrpcApiDefinitionWidgetProps) => {
  const { definition } = props;
  const theme = useTheme<BackstageTheme>();
  return <CodeSnippet customStyle={{ backgroundColor: theme.palette.background.default }} text={definition} language="typescript" showCopyCodeButton />;
};
