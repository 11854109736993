import { IconComponent, useApp } from '@backstage/core-plugin-api';
import { Button } from '@material-ui/core';
import WebIcon from '@material-ui/icons/Web';
import React from 'react';
import { ScaffolderTaskOutput } from '../../../api';

export const TextOutputs = (props: { output: ScaffolderTaskOutput; index?: number; setIndex?: (index: number | undefined) => void }) => {
  const {
    output: { text = [] },
    index,
    setIndex,
  } = props;

  const app = useApp();

  const iconResolver = (key?: string): IconComponent => app.getSystemIcon(key!) ?? WebIcon;

  return (
    <>
      {text
        .filter(({ content }) => content !== undefined)
        .map(({ title, icon }, i) => {
          const Icon = iconResolver(icon);
          return (
            <Button
              key={i}
              startIcon={<Icon />}
              component="div"
              color="primary"
              onClick={() => setIndex?.(index !== i ? i : undefined)}
              variant={index === i ? 'outlined' : undefined}
            >
              {title}
            </Button>
          );
        })}
    </>
  );
};
