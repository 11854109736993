import { StatusError, StatusOK, StatusPending } from '@backstage/core-components';
import React from 'react';

export const TaskStatusColumn = ({ status }: { status: string }) => {
  switch (status) {
    case 'processing':
      return <StatusPending>{status}</StatusPending>;
    case 'completed':
      return <StatusOK>{status}</StatusOK>;
    case 'error':
    default:
      return <StatusError>{status}</StatusError>;
  }
};
