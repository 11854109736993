import React, { useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import { GetEntityFacetsRequest } from '@backstage/catalog-client';
import { makeValidator } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { EntityTagsPickerProps } from './schema';

export { EntityTagsPickerSchema } from './schema';

/**
 * The underlying component that is rendered in the form for the `EntityTagsPicker`
 * field extension.
 *
 * @public
 */
export const EntityTagsPicker = (props: EntityTagsPickerProps) => {
  const { formData, onChange, uiSchema } = props;
  const catalogApi = useApi(catalogApiRef);
  const [tagOptions, setTagOptions] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState(false);
  const tagValidator = makeValidator().isValidTag;
  const kinds = uiSchema['ui:options']?.kinds;
  const showCounts = uiSchema['ui:options']?.showCounts;
  const helperText = uiSchema['ui:options']?.helperText;

  const { loading, value: existingTags } = useAsync(async () => {
    const facet = 'metadata.tags';
    const tagsRequest: GetEntityFacetsRequest = { facets: [facet] };
    if (kinds) {
      tagsRequest.filter = { kind: kinds };
    }

    const { facets } = await catalogApi.getEntityFacets(tagsRequest);

    const tagFacets = Object.fromEntries(facets[facet].map(({ value, count }) => [value, count]));

    setTagOptions(Object.keys(tagFacets).sort((a, b) => (showCounts ? tagFacets[b] - tagFacets[a] : a.localeCompare(b))));

    return tagFacets;
  });

  const setTags = (_: React.ChangeEvent<{}>, values: string[] | null) => {
    // Reset error state in case all tags were removed
    let hasError = false;
    let addDuplicate = false;
    const currentTags = formData || [];

    // If adding a new tag
    if (values?.length && currentTags.length < values.length) {
      const newTag = (values[values.length - 1] = values[values.length - 1].toLocaleLowerCase('en-US').trim());
      hasError = !tagValidator(newTag);
      addDuplicate = currentTags.indexOf(newTag) !== -1;
    }

    setInputError(hasError);
    setInputValue(!hasError ? '' : inputValue);
    if (!hasError && !addDuplicate) {
      onChange(values || []);
    }
  };

  // Initialize field to always return an array
  useEffectOnce(() => onChange(formData || []));

  return (
    <FormControl margin="normal">
      <Autocomplete
        multiple
        freeSolo
        filterSelectedOptions
        onChange={setTags}
        value={formData || []}
        inputValue={inputValue}
        loading={loading}
        options={tagOptions}
        ChipProps={{ size: 'small' }}
        renderOption={option => (showCounts ? `${option} (${existingTags?.[option]})` : option)}
        renderInput={params => (
          <TextField
            {...params}
            label="Tags"
            onChange={e => setInputValue(e.target.value)}
            error={inputError}
            helperText={helperText ?? "Add any relevant tags, hit 'Enter' to add new tags. Valid format: [a-z0-9+#] separated by [-], at most 63 characters"}
          />
        )}
      />
    </FormControl>
  );
};
