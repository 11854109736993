import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { CustomFileInput, CustomFileInputSchema } from './components';

/**
 * EntityTagsPickerFieldExtension
 * @public
 */
export const CustomFileInputExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: CustomFileInput,
    name: 'CustomFileInput',
    schema: CustomFileInputSchema,
  }),
);
