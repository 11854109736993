import { IconComponent } from '@backstage/core-plugin-api';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import { BackstageTheme } from '@backstage/theme';
import SettingsIcon from '@mui/icons-material/Settings';
import React, { Fragment } from 'react';

import AllIcon from '@mui/icons-material/FontDownload';

const useStyles = makeStyles(
  () => {
    const theme = useTheme<BackstageTheme>();
    return {
      root: {
        backgroundColor: 'rgba(0, 0, 0, .11)',
        boxShadow: 'none',
        margin: theme.spacing(1, 0, 1, 0),
      },
      title: {
        margin: theme.spacing(1, 0, 0, 1),
        textTransform: 'uppercase',
        fontSize: 12,
        fontWeight: 'bold',
      },
      listIcon: {
        minWidth: 30,
        color: theme.palette.text.primary,
      },
      menuItem: {
        minHeight: theme.spacing(6),
      },
      groupWrapper: {
        margin: theme.spacing(1, 1, 2, 1),
      },
    };
  },
  {
    name: 'ScaffolderReactOwnerListPicker',
  },
);

export type ButtonGroup = {
  name: string;
  items: {
    id: 'owned' | 'starred' | 'all';
    label: string;
    icon?: IconComponent;
  }[];
};

function getFilterGroups(): ButtonGroup[] {
  return [
    {
      name: 'Task Owner',
      items: [
        {
          id: 'owned',
          label: 'Owned',
          icon: SettingsIcon as any,
        },
        {
          id: 'all',
          label: 'All',
          icon: AllIcon as any,
        },
      ],
    },
  ];
}

export const OwnerListPicker = (props: { filter: string; onSelectOwner: (id: 'owned' | 'all') => void }) => {
  const { filter, onSelectOwner } = props;
  const classes = useStyles();

  const filterGroups = getFilterGroups();
  return (
    <Card className={classes.root}>
      {filterGroups.map(group => (
        <Fragment key={group.name}>
          <Typography variant="subtitle2" component="span" className={classes.title}>
            {group.name}
          </Typography>
          <Card className={classes.groupWrapper}>
            <List disablePadding dense role="menu">
              {group.items.map(item => (
                <MenuItem
                  key={item.id}
                  // button
                  divider
                  ContainerProps={{ role: 'menuitem' }}
                  onClick={() => onSelectOwner(item.id as 'owned' | 'all')}
                  selected={item.id === filter}
                  className={classes.menuItem}
                  data-testid={`owner-picker-${item.id}`}
                >
                  {item.icon && (
                    <ListItemIcon className={classes.listIcon}>
                      <item.icon fontSize="small" />
                    </ListItemIcon>
                  )}
                  <ListItemText>
                    <Typography variant="body1">{item.label}</Typography>
                  </ListItemText>
                </MenuItem>
              ))}
            </List>
          </Card>
        </Fragment>
      ))}
    </Card>
  );
};
