import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const devopsPlugin = createPlugin({
  id: 'devops',
  routes: {
    root: rootRouteRef,
  },
});

export const DevopsPage = devopsPlugin.provide(
  createRoutableExtension({
    name: 'DevopsPage',
    component: () => import('./components/DefaultDevOpsPage').then(m => m.DefaultDevOpsPage),
    mountPoint: rootRouteRef,
  }),
);
