import type { Config } from '@backstage/config';
import { UseFormRegisterReturn } from 'react-hook-form';

/**
 * A helper that converts the result of a render('name', opts) to make it compatible with material-ui.
 *
 * See also https://github.com/react-hook-form/react-hook-form/issues/4629#issuecomment-815840872
 * TODO: remove when updating to material-ui v5 (https://github.com/mui-org/material-ui/pull/23174)
 *
 * @param renderResult - the result of a render('name', opts)
 */
export function asInputRef(renderResult: UseFormRegisterReturn) {
  const { ref, ...rest } = renderResult;
  return {
    inputRef: ref,
    ...rest,
  };
}

export function getCatalogFilename(config: Config): string {
  return config.getOptionalString('catalog.import.entityFilename') ?? 'catalog-info.yaml';
}

export function getBranchName(config: Config): string {
  return config.getOptionalString('catalog.import.pullRequestBranchName') ?? 'backstage-integration';
}
