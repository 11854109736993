import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const configuratorPlugin = createPlugin({
  id: 'configurator',
  routes: {
    root: rootRouteRef,
  },
});

export const ConfiguratorPage = configuratorPlugin.provide(
  createRoutableExtension({
    name: 'ConfiguratorPage',
    component: () => import('./pages/ConfiguratorPage').then(m => m.ConfiguratorPage),
    mountPoint: rootRouteRef,
  }),
);
