import Typography from '@material-ui/core/Typography';
import React, { ComponentClass, Component, ErrorInfo, PropsWithChildren } from 'react';
import { LinkButton, ErrorPanel } from '../../components';

type SlackChannel = {
  name: string;
  href?: string;
};

/** @public */
export type ErrorBoundaryProps = PropsWithChildren<{
  slackChannel?: string | SlackChannel;
  onError?: (error: Error, errorInfo: string) => null;
}>;

type State = {
  error?: Error;
  errorInfo?: ErrorInfo;
};

const SlackLink = (props: { slackChannel?: string | SlackChannel }) => {
  const { slackChannel } = props;

  if (!slackChannel) {
    return null;
  } else if (typeof slackChannel === 'string') {
    return <Typography>Please contact {slackChannel} for help.</Typography>;
  } else if (!slackChannel.href) {
    return <Typography>Please contact {slackChannel.name} for help.</Typography>;
  }

  return (
    <LinkButton to={slackChannel.href} variant="contained">
      {slackChannel.name}
    </LinkButton>
  );
};

/** @public */
export const ErrorBoundary: ComponentClass<ErrorBoundaryProps, State> = class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: undefined,
      errorInfo: undefined,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error(`ErrorBoundary, error: ${error}, info: ${errorInfo}`);
    this.setState({ error, errorInfo });
  }

  render() {
    const { slackChannel, children } = this.props;
    const { error } = this.state;

    if (!error) {
      return children;
    }

    return (
      <ErrorPanel title="Something Went Wrong" error={error}>
        <SlackLink slackChannel={slackChannel} />
      </ErrorPanel>
    );
  }
};
