import { Entity, CompoundEntityRef, stringifyEntityRef } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { useCallback, useEffect, useState } from 'react';
import { starredEntitiesApiRef } from '../apis';

function getEntityRef(entityOrRef: Entity | CompoundEntityRef | string): string {
  return typeof entityOrRef === 'string' ? entityOrRef : stringifyEntityRef(entityOrRef);
}

/** @public */
export function useStarredEntity(entityOrRef: Entity | CompoundEntityRef | string): {
  toggleStarredEntity: () => void;
  isStarredEntity: boolean;
} {
  const starredEntitiesApi = useApi(starredEntitiesApiRef);

  const [isStarredEntity, setIsStarredEntity] = useState(false);

  useEffect(() => {
    const subscription = starredEntitiesApi.starredEntitie$().subscribe({
      next(starredEntities: Set<string>) {
        setIsStarredEntity(starredEntities.has(getEntityRef(entityOrRef)));
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [entityOrRef, starredEntitiesApi]);

  const toggleStarredEntity = useCallback(() => starredEntitiesApi.toggleStarred(getEntityRef(entityOrRef)).then(), [entityOrRef, starredEntitiesApi]);

  return {
    toggleStarredEntity,
    isStarredEntity,
  };
}
