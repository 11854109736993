import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const performanceManagementPlugin = createPlugin({
  id: 'performance-management',
  routes: {
    root: rootRouteRef,
  },
});

export const MetricsListPageComponent = performanceManagementPlugin.provide(
  createRoutableExtension({
    name: 'MetricsListPageComponent',
    component: () => import('./components/MetricsListPageComponent').then(m => m.MetricsListPageComponent),
    mountPoint: rootRouteRef,
  }),
);

export const MetricCrudPageComponent = performanceManagementPlugin.provide(
  createRoutableExtension({
    name: 'MetricCrudPageComponent',
    component: () => import('./components/MetricCrudPageComponent').then(m => m.MetricCrudPageComponent),
    mountPoint: rootRouteRef,
  }),
);

export const ComponentMetricsCard = performanceManagementPlugin.provide(
  createRoutableExtension({
    name: 'ComponentMetricsCard',
    component: () => import('./components/ComponentMetricsCard').then(m => m.ComponentMetricsCard),
    mountPoint: rootRouteRef,
  }),
);
