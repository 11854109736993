import React, { useEffect } from 'react';
import { useSearch } from '@backstage/plugin-search-react';
import { BackstageTheme } from '@backstage/theme';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles(() => {
  const theme = useTheme<BackstageTheme>();
  return {
    tabs: {
      borderBottom: `1px solid ${theme.palette.textVerySubtle}`,
    },
    tab: {
      height: '50px',
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(13),
      color: theme.palette.text.primary,
      minWidth: '130px',
    },
  };
});

/**
 * @public
 */
export type SearchTypeTabsProps = {
  types: Array<{
    value: string;
    name: string;
  }>;
  defaultValue?: string;
};

export const SearchTypeTabs = (props: SearchTypeTabsProps) => {
  const classes = useStyles();
  const { setPageCursor, setTypes, types } = useSearch();
  const { defaultValue, types: givenTypes } = props;

  const changeTab = (_: React.ChangeEvent<{}>, newType: string) => {
    setTypes(newType !== '' ? [newType] : []);
    setPageCursor(undefined);
  };

  // Handle any provided defaultValue
  useEffect(() => {
    if (defaultValue) {
      setTypes([defaultValue]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const definedTypes = [
    {
      value: '',
      name: 'All',
    },
    ...givenTypes,
  ];

  return (
    <Tabs aria-label="List of search types tabs" className={classes.tabs} indicatorColor="primary" value={types.length === 0 ? '' : types[0]} onChange={changeTab}>
      {definedTypes.map((type, idx) => (
        <Tab key={idx} className={classes.tab} label={type.name} value={type.value} />
      ))}
    </Tabs>
  );
};
