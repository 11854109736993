import React from 'react';
import { NextFieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react/alpha';
import TextField from '@mui/material/TextField';

export { NamespacePickerSchema } from './schema';

export const NamespacePicker = (props: NextFieldExtensionComponentProps<string>) => {
  const {
    onChange,
    required,
    schema: { title = 'Name', description = 'Unique name of the component' },
    rawErrors,
    formData,
    uiSchema: { 'ui:autofocus': autoFocus } = {},
    idSchema,
    placeholder,
  } = props;

  return (
    <TextField
      id={idSchema?.$id as string}
      label={title}
      placeholder={placeholder as string}
      helperText={description}
      required={required}
      value={formData ?? ''}
      onChange={({ target: { value } }) => onChange(value)}
      margin="normal"
      error={rawErrors?.length > 0 && !formData}
      inputProps={{ autoFocus }}
    />
  );
};
