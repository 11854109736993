import React, { ReactNode } from 'react';
import { AnalyticsContext } from '@backstage/core-plugin-api';
import { ResultHighlight, SearchDocument } from '@backstage/plugin-search-common';
import { HighlightedSearchResultText } from '../HighlightedSearchResultText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from '@backstage/core-components';

/**
 * Props for {@link DefaultResultListItem}
 *
 * @public
 */
export type DefaultResultListItemProps = {
  icon?: ReactNode;
  secondaryAction?: ReactNode;
  result?: SearchDocument;
  highlight?: ResultHighlight;
  rank?: number;
  lineClamp?: number;
  toggleModal?: () => void;
};

/**
 * A default result list item.
 *
 * @public
 */
export const DefaultResultListItemComponent = ({ result, highlight, icon, secondaryAction, lineClamp = 5 }: DefaultResultListItemProps) => {
  if (!result) return null;

  return (
    <>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText
        primaryTypographyProps={{ variant: 'h6' }}
        primary={
          <Link noTrack to={result.location}>
            {highlight?.fields.title ? (
              <HighlightedSearchResultText text={highlight?.fields.title || ''} preTag={highlight?.preTag || ''} postTag={highlight?.postTag || ''} />
            ) : (
              result.title
            )}
          </Link>
        }
        secondary={
          <Typography
            component="span"
            style={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: lineClamp,
              overflow: 'hidden',
            }}
            color="textSecondary"
            variant="body2"
          >
            {highlight?.fields.text ? <HighlightedSearchResultText text={highlight.fields.text} preTag={highlight.preTag} postTag={highlight.postTag} /> : result.text}
          </Typography>
        }
      />
      {secondaryAction && <Box alignItems="flex-end">{secondaryAction}</Box>}
    </>
  );
};

/**
 * @public
 */
const HigherOrderDefaultResultListItem = (props: DefaultResultListItemProps) => {
  return (
    <AnalyticsContext
      attributes={{
        pluginId: 'search',
        extension: 'DefaultResultListItem',
      }}
    >
      <DefaultResultListItemComponent {...props} />
    </AnalyticsContext>
  );
};

export { HigherOrderDefaultResultListItem as DefaultResultListItem };
