import { BackstageTheme } from '@backstage/theme';
import { Entity } from '@backstage/catalog-model';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef, humanizeEntityRef } from '@backstage/plugin-catalog-react';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import React, { useCallback, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import yaml from 'yaml';
import { type LayoutOptions } from '@backstage/plugin-scaffolder-react';
import { NextFieldExtensionOptions } from '@backstage/plugin-scaffolder-react/alpha';
import { TemplateEditorForm } from './TemplateEditorForm';
import { TemplateEditorTextArea } from '../../components/TemplateEditorPage/TemplateEditorTextArea';

const EXAMPLE_TEMPLATE_PARAMS_YAML = `# Edit the template parameters below to see how they will render in the scaffolder form UI
parameters:
  - title: Fill in some steps
    required:
      - name
    properties:
      name:
        title: Name
        type: string
        description: Unique name of the component
      owner:
        title: Owner
        type: string
        description: Owner of the component
        ui:field: OwnerPicker
        ui:options:
          catalogFilter:
            kind: Group
  - title: Choose a location
    required:
      - repoUrl
    properties:
      repoUrl:
        title: Repository Location
        type: string
        ui:field: RepoUrlPicker
        ui:options:
          allowedHosts:
            - github.com
steps:
  - id: fetch-base
    name: Fetch Base
    action: fetch:template
    input:
      url: ./template
      values:
        name: \${{parameters.name}}
`;

type TemplateOption = {
  label: string;
  value: Entity;
};

const useStyles = makeStyles(() => ({
  root: {
    gridArea: 'pageContent',
    display: 'grid',
    gridTemplateAreas: `
      "controls controls"
      "textArea preview"
    `,
    gridTemplateRows: 'auto 1fr',
    gridTemplateColumns: '1fr 1fr',
  },
  controls: {
    gridArea: 'controls',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    margin: useTheme<BackstageTheme>().spacing(1),
  },
  textArea: {
    gridArea: 'textArea',
  },
  preview: {
    gridArea: 'preview',
  },
}));

export const TemplateFormPreviewer = ({
  defaultPreviewTemplate = EXAMPLE_TEMPLATE_PARAMS_YAML,
  customFieldExtensions = [],
  onClose,
  layouts = [],
}: {
  defaultPreviewTemplate?: string;
  customFieldExtensions?: NextFieldExtensionOptions<any, any>[];
  onClose?: () => void;
  layouts?: LayoutOptions[];
}) => {
  const classes = useStyles();
  const alertApi = useApi(alertApiRef);
  const catalogApi = useApi(catalogApiRef);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [errorText, setErrorText] = useState<string>();
  const [templateOptions, setTemplateOptions] = useState<TemplateOption[]>([]);
  const [templateYaml, setTemplateYaml] = useState(defaultPreviewTemplate);

  const { loading } = useAsync(
    () =>
      catalogApi
        .getEntities({
          filter: { kind: 'template' },
          fields: ['kind', 'metadata.namespace', 'metadata.name', 'metadata.title', 'spec.parameters', 'spec.steps', 'spec.output'],
        })
        .then(({ items }) =>
          setTemplateOptions(
            items.map(template => ({
              label: template.metadata.title ?? humanizeEntityRef(template, { defaultKind: 'template' }),
              value: template,
            })),
          ),
        )
        .catch(e =>
          alertApi.post({
            message: `Error loading exisiting templates: ${e.message}`,
            severity: 'error',
          }),
        ),
    [catalogApi],
  );

  const handleSelectChange = useCallback(
    selected => {
      setSelectedTemplate(selected);
      setTemplateYaml(yaml.stringify(selected.spec));
    },
    [setTemplateYaml],
  );

  return (
    <>
      {loading && <LinearProgress />}
      <main className={classes.root}>
        <div className={classes.controls}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel id="select-template-label">Load Existing Template</InputLabel>
            <Select value={selectedTemplate} label="Load Existing Template" labelId="select-template-label" onChange={e => handleSelectChange(e.target.value)}>
              {templateOptions.map((option, idx) => (
                <MenuItem key={idx} value={option.value as any}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <IconButton size="medium" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.textArea}>
          <TemplateEditorTextArea content={templateYaml} onUpdate={setTemplateYaml} errorText={errorText} />
        </div>
        <div className={classes.preview}>
          <TemplateEditorForm content={templateYaml} contentIsSpec fieldExtensions={customFieldExtensions} setErrorText={setErrorText} layouts={layouts} />
        </div>
      </main>
    </>
  );
};
