import {
  Entity,
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';
import { EmptyState } from '@backstage/core-components';
import {
  EntityApiDefinitionCard,
  EntityApiEditorCard,
  EntityConsumedApisCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidedApisCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
// import { EntityAzurePipelinesContent, EntityAzurePullRequestsContent, isAzureDevOpsAvailable, isAzurePipelinesAvailable } from '@backstage/plugin-azure-devops';
// import { EntityOctopusDeployContent, isOctopusDeployAvailable } from '@backstage/plugin-octopus-deploy';
import { EntityBadgesDialog } from '@backstage/plugin-badges';
import {
  EntityAboutCard,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasArtifactsCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityHasSubcomponentsCard,
  EntityHasSystemsCard,
  EntityLabelsCard,
  EntityLayout,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  EntityRelationWarning,
  EntitySwitch,
  hasCatalogProcessingErrors,
  hasLabels,
  hasRelationWarnings,
  isComponentType,
  isKind,
  isOrphan,
  isResourceType,
} from '@tmatic/plugin-catalog-customized';
import { Direction, EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
// import { EntityCircleCIContent, isCircleCIAvailable } from '@backstage/plugin-circleci';
// import { EntityCloudbuildContent, isCloudbuildAvailable } from '@backstage/plugin-cloudbuild';
import { EntityLikeDislikeRatingsCard } from '@backstage/plugin-entity-feedback';
import { EntityGithubActionsContent, EntityRecentGithubActionsRunsCard, isGithubActionsAvailable } from '@backstage/plugin-github-actions';
// import { EntityJenkinsContent, EntityLatestJenkinsRunCard, isJenkinsAvailable } from '@backstage/plugin-jenkins';
import { EntityGroupProfileCard, EntityMembersListCard, EntityOwnershipCard, EntityUserProfileCard } from '@backstage/plugin-org';
import { EntityPlaylistDialog } from '@backstage/plugin-playlist';
// import { EntityRollbarContent, isRollbarAvailable } from '@backstage/plugin-rollbar';
// import { isPuppetDbAvailable, PuppetDbPage } from '@backstage/plugin-puppetdb';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import { EntityTodoContent } from '@backstage/plugin-todo';
import Grid from '@mui/material/Grid';

import {
  EntityGithubInsightsLanguagesCard,
  EntityGithubInsightsReadmeCard,
  EntityGithubInsightsReleasesCard,
  isGithubInsightsAvailable,
} from '@roadiehq/backstage-plugin-github-insights';
import { EntityGithubPullRequestsContent, EntityGithubPullRequestsOverviewCard, isGithubPullRequestsAvailable } from '@roadiehq/backstage-plugin-github-pull-requests';
import { EntityTravisCIContent, EntityTravisCIOverviewCard, isTravisciAvailable } from '@roadiehq/backstage-plugin-travis-ci';
import { EntityBuildkiteContent, isBuildkiteAvailable } from '@roadiehq/backstage-plugin-buildkite';
// import { EntityGoCdContent, isGoCdAvailable } from '@backstage/plugin-gocd';
// import { EntityScoreCardContent } from '@oriflame/backstage-plugin-score-card';

import React, { ReactNode, useState } from 'react';

import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { LightBox, ReportIssue, TextSize } from '@backstage/plugin-techdocs-module-addons-contrib';
import { EntityLinguistCard, isLinguistAvailable } from '@backstage/plugin-linguist';
import {
  AboutCardTMatic,
  ClaimParametersCard,
  ResourceClusterStatusCard,
  ResourceClusterRelatedResourcesPage,
  DockerRepositoryInfoCard,
  isArtifactDockerImage,
  isArtifactNpmPackage,
  isResourceCrossplaneClaim,
  NpmPublicPackageInfoCard,
  ApplicationArtifactCard,
  ClaimDetailsCard,
  ApplicationParametersCard,
  ProvidedApisCard,
  ComponentArtifactCard,
  ComponentRepositoryCard,
  ParentComponentsCard,
  HasApplicationCard,
  DockerRepositoryInfoLatestCard,
  HasComponentCard,
  ArgoCDClustersCard,
  ArgoCDRepositoriesCard,
} from '@tmatic/entity-management-react';
import { ComponentMetricsCard } from '@tmatic/performance-management';
import { ArgoWorkflowPage, EntityArgoWorkflowsCard } from '@tmatic/plugin-argo-workflow';
import { EntityClusterEventsCard } from '@tmatic/entity-components-react';

const customEntityFilterKind = ['Component', 'API', 'System'];

const EntityLayoutWrapper = (props: { children?: ReactNode }) => {
  const [badgesDialogOpen, setBadgesDialogOpen] = useState(false);
  const [playlistDialogOpen, setPlaylistDialogOpen] = useState(false);

  return (
    <>
      <EntityLayout
        // @ts-ignore
        UNSTABLE_contextMenuOptions={{
          disableUnregister: 'visible',
        }}
      >
        {props.children}
      </EntityLayout>
      <EntityBadgesDialog open={badgesDialogOpen} onClose={() => setBadgesDialogOpen(false)} />
      <EntityPlaylistDialog open={playlistDialogOpen} onClose={() => setPlaylistDialogOpen(false)} />
    </>
  );
};

const techdocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <TextSize />
      <ReportIssue />
      <LightBox />
    </TechDocsAddons>
  </EntityTechdocsContent>
);

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

export const cicdContent = (
  <EntitySwitch>
    {/*<EntitySwitch.Case if={isJenkinsAvailable}>*/}
    {/*  <EntityJenkinsContent />*/}
    {/*</EntitySwitch.Case>*/}

    {/*<EntitySwitch.Case if={isBuildkiteAvailable}>*/}
    {/*  <EntityBuildkiteContent />*/}
    {/*</EntitySwitch.Case>*/}

    {/*<EntitySwitch.Case if={isCircleCIAvailable}>*/}
    {/*  <EntityCircleCIContent />*/}
    {/*</EntitySwitch.Case>*/}

    {/*<EntitySwitch.Case if={isCloudbuildAvailable}>*/}
    {/*  <EntityCloudbuildContent />*/}
    {/*</EntitySwitch.Case>*/}

    {/*<EntitySwitch.Case if={isTravisciAvailable}>*/}
    {/*  <EntityTravisCIContent />*/}
    {/*</EntitySwitch.Case>*/}

    {/*<EntitySwitch.Case if={isGoCdAvailable}>*/}
    {/*  <EntityGoCdContent />*/}
    {/*</EntitySwitch.Case>*/}

    <EntitySwitch.Case if={isGithubActionsAvailable}>
      <EntityGithubActionsContent />
    </EntitySwitch.Case>

    {/*<EntitySwitch.Case if={isAzurePipelinesAvailable}>*/}
    {/*  <EntityAzurePipelinesContent defaultLimit={25} />*/}
    {/*</EntitySwitch.Case>*/}

    {/*<EntitySwitch.Case if={isOctopusDeployAvailable}>*/}
    {/*  <EntityOctopusDeployContent defaultLimit={25} />*/}
    {/*</EntitySwitch.Case>*/}

    <EntitySwitch.Case>
      <EmptyState
        title="No CI/CD available for this entity"
        missing="info"
        description="You need to add appropriate annotations to your component if you want to enable CI/CD for it"
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);

const cicdCard = (
  <EntitySwitch>
    {/*<EntitySwitch.Case if={isJenkinsAvailable}>*/}
    {/*  <Grid item sm={6}>*/}
    {/*    <EntityLatestJenkinsRunCard branch="master" variant="gridItem" />*/}
    {/*  </Grid>*/}
    {/*</EntitySwitch.Case>*/}

    {/*<EntitySwitch.Case if={isTravisciAvailable as (e: Entity) => boolean}>*/}
    {/*  <Grid item sm={6}>*/}
    {/*    <EntityTravisCIOverviewCard />*/}
    {/*  </Grid>*/}
    {/*</EntitySwitch.Case>*/}

    <EntitySwitch.Case if={isGithubActionsAvailable}>
      <Grid item sm={6}>
        <EntityRecentGithubActionsRunsCard limit={4} variant="gridItem" />
      </Grid>
    </EntitySwitch.Case>
  </EntitySwitch>
);

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasRelationWarnings}>
        <Grid item xs={12}>
          <EntityRelationWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

// @ts-ignore
const errorsContent = (
  <EntitySwitch>
    {/*<EntitySwitch.Case if={isRollbarAvailable}>*/}
    {/*  <EntityRollbarContent />*/}
    {/*</EntitySwitch.Case>*/}

    {/* <EntitySwitch.Case>
      <EntitySentryContent />
    </EntitySwitch.Case> */}
  </EntitySwitch>
);

// @ts-ignore
const pullRequestsContent = (
  <EntitySwitch>
    {/*<EntitySwitch.Case if={isAzureDevOpsAvailable}>*/}
    {/*  <EntityAzurePullRequestsContent defaultLimit={25} />*/}
    {/*</EntitySwitch.Case>*/}

    <EntitySwitch.Case>
      <EntityGithubPullRequestsContent />
    </EntitySwitch.Case>
  </EntitySwitch>
);

const overviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={6} xs={12}>
      <EntityAboutCard variant="gridItem" />
    </Grid>

    <EntitySwitch>
      <EntitySwitch.Case if={isArtifactNpmPackage}>
        <Grid item md={6} xs={12}>
          <NpmPublicPackageInfoCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isArtifactDockerImage}>
        <Grid item md={6} xs={12}>
          <DockerRepositoryInfoCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    {/* <EntitySwitch>
      <EntitySwitch.Case if={isPagerDutyAvailable}>
        <Grid item md={6}>
          <EntityPagerDutyCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch> */}

    {/* <EntitySwitch>
      <EntitySwitch.Case if={isNewRelicDashboardAvailable}>
        <Grid item md={6} xs={12}>
          <EntityNewRelicDashboardCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch> */}

    {/* <EntitySwitch>
      <EntitySwitch.Case if={isDatadogGraphAvailable}>
        <Grid item>
          <EntityDatadogGraphCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch> */}

    <EntitySwitch>
      <EntitySwitch.Case if={hasLabels}>
        <Grid item md={4} xs={12}>
          <EntityLabelsCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    {cicdCard}

    <EntitySwitch>
      <EntitySwitch.Case if={isGithubInsightsAvailable}>
        <Grid item md={6}>
          <EntityGithubInsightsLanguagesCard />
          <EntityGithubInsightsReleasesCard />
        </Grid>
        <Grid item md={6}>
          <EntityGithubInsightsReadmeCard maxHeight={350} />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    {/* <EntitySwitch>
      <EntitySwitch.Case if={isLighthouseAvailable}>
        <Grid item sm={4}>
          <EntityLastLighthouseAuditCard variant="gridItem" />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch> */}

    <EntitySwitch>
      <EntitySwitch.Case if={isGithubPullRequestsAvailable}>
        <Grid item sm={4}>
          <EntityGithubPullRequestsOverviewCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isLinguistAvailable}>
        <Grid item md={6}>
          <EntityLinguistCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    {/* <EntitySwitch>
      <EntitySwitch.Case if={isAlertSelectorAvailable}>
        <Grid item md={6}>
          <EntityGrafanaAlertsCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch> */}

    {/* <EntitySwitch>
      <EntitySwitch.Case if={isDashboardSelectorAvailable}>
        <Grid item md={6}>
          <EntityGrafanaDashboardsCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch> */}

    {/* <Grid item md={4} xs={12}>*/}
    {/*  <EntityLinksCard />*/}
    {/* </Grid>*/}

    <Grid item md={6} xs={12}>
      <EntityArgoWorkflowsCard />
    </Grid>

    <EntitySwitch>
      <EntitySwitch.Case if={isComponentType('')}>
        <Grid item lg={6} xs={12}>
          <EntityHasSubcomponentsCard variant="gridItem" />
        </Grid>
        <Grid item lg={6} xs={12}>
          <EntityHasArtifactsCard variant="gridItem" />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    {/* <EntitySwitch>
      <EntitySwitch.Case if={isNomadJobIDAvailable}>
        <Grid item md={6} xs={12}>
          <EntityNomadJobVersionListCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch> */}
  </Grid>
);

const artifactOverviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={6} xs={12}>
      <AboutCardTMatic />
    </Grid>

    <EntitySwitch>
      <EntitySwitch.Case if={isArtifactNpmPackage}>
        <Grid item md={6} xs={12}>
          <NpmPublicPackageInfoCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isArtifactDockerImage}>
        <Grid item md={6} xs={12}>
          <DockerRepositoryInfoCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasLabels}>
        <Grid item md={4} xs={12}>
          <EntityLabelsCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    {cicdCard}

    <Grid item md={6} xs={12}>
      <EntityArgoWorkflowsCard title="Workflow Actions" />
    </Grid>

    {/* <EntitySwitch>*/}
    {/*  <EntitySwitch.Case if={isComponentType('')}>*/}
    {/*    <Grid item lg={6} xs={12}>*/}
    {/*      <EntityHasSubcomponentsCard variant="gridItem" />*/}
    {/*    </Grid>*/}
    {/*    <Grid item lg={6} xs={12}>*/}
    {/*      <EntityHasArtifactsCard variant="gridItem" />*/}
    {/*    </Grid>*/}
    {/*  </EntitySwitch.Case>*/}
    {/* </EntitySwitch>*/}
  </Grid>
);

const metricsContent = (
  <Grid container spacing={3} alignItems="stretch">
    <Grid item xs={12}>
      <ComponentMetricsCard />
    </Grid>
  </Grid>
);

const argoWorkflowContent = (
  <Grid container spacing={3} alignItems="stretch">
    <Grid item xs={12}>
      <ArgoWorkflowPage />
    </Grid>
  </Grid>
);

const serviceEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    {/* <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/errors" title="Errors">
      {errorsContent}
    </EntityLayout.Route> */}

    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    {/* <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRS">
      <EntityAdrContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route if={isNewRelicDashboardAvailable} path="/newrelic-dashboard" title="New Relic Dashboard">
      <EntityNewRelicDashboardContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route if={isKubernetesAvailable} path="/kubernetes" title="Kubernetes">
      <EntityKubernetesContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/argocd" title="ArgoCD">
      <EntitySwitch>
        <EntitySwitch.Case if={e => Boolean(isArgocdAvailable(e))}>
          <EntityArgoCDOverviewCard />
        </EntitySwitch.Case>
      </EntitySwitch>
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route if={isNomadAllocationsAvailable} path="/nomad" title="Nomad">
      <EntityNomadAllocationListTable />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/pull-requests" title="Pull Requests">
      {pullRequestsContent}
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/code-insights" title="Code Insights">
      <EntityGithubInsightsContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/tech-insights" title="Scorecards">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <EntityTechInsightsScorecardCard title="Scorecard 1" description="This is a sample scorecard no. 1" checksId={['titleCheck']} />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityTechInsightsScorecardCard title="Scorecard 2" checksId={['techDocsCheck']} />
        </Grid>
      </Grid>
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/code-coverage" title="Code Coverage">
      <EntityCodeCoverageContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/kafka" title="Kafka">
      <EntityKafkaContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/todos" title="TODOs">
      <EntityTodoContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/costs" title="Costs">
      <EntityCostInsightsContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/dynatrace" title="Dynatrace" if={isDynatraceAvailable}>
      <DynatraceTab />
    </EntityLayout.Route> */}
    {/*
    <EntityLayout.Route path="/sentry" title="Sentry">
      <EntitySentryContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/grafana" title="Grafana" if={isOverviewDashboardAvailable}>
      <EntityOverviewDashboardViewer />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/datadog" title="Datadog">
      <EntityDatadogContent />
    </EntityLayout.Route> */}

    <EntityLayout.Route path="/metrics" title="Metrics">
      {metricsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/actions" title="Actions">
      {argoWorkflowContent}
    </EntityLayout.Route>

    {/* <EntityLayout.Route path="/feedback" title="Feedback">*/}
    {/*  <EntityFeedbackResponseContent />*/}
    {/* </EntityLayout.Route>*/}
  </EntityLayoutWrapper>
);

const websiteEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    {/* <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route if={isLighthouseAvailable} path="/lighthouse" title="Lighthouse">
      <EntityLighthouseContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/errors" title="Errors">
      {errorsContent}
    </EntityLayout.Route> */}

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    {/* <EntityLayout.Route if={isNewRelicDashboardAvailable} path="/newrelic-dashboard" title="New Relic Dashboard">
      <EntityNewRelicDashboardContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route if={isKubernetesAvailable} path="/kubernetes" title="Kubernetes">
      <EntityKubernetesContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/dynatrace" title="Dynatrace" if={isDynatraceAvailable}>
      <DynatraceTab />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route if={isAzureDevOpsAvailable} path="/git-tags" title="Git Tags">
      <EntityAzureGitTagsContent />
    </EntityLayout.Route> */}

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    {/* <EntityLayout.Route path="/pull-requests" title="Pull Requests">
      {pullRequestsContent}
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/code-insights" title="Code Insights">
      <EntityGithubInsightsContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/code-coverage" title="Code Coverage">
      <EntityCodeCoverageContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/todos" title="TODOs">
      <EntityTodoContent />
    </EntityLayout.Route> */}

    <EntityLayout.Route path="/metrics" title="Metrics">
      {metricsContent}
    </EntityLayout.Route>

    {/* <EntityLayout.Route path="/feedback" title="Feedback">*/}
    {/*  <EntityFeedbackResponseContent />*/}
    {/* </EntityLayout.Route>*/}
  </EntityLayoutWrapper>
);

const libraryEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityConsumingComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityConsumedApisCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/metrics" title="Metrics">
      {metricsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/actions" title="Actions">
      {argoWorkflowContent}
    </EntityLayout.Route>
  </EntityLayout>
);

const defaultEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/todos" title="TODOs">
      <EntityTodoContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/metrics" title="Metrics">
      {metricsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/actions" title="Actions">
      {argoWorkflowContent}
    </EntityLayout.Route>

    {/* <EntityLayout.Route path="/feedback" title="Feedback">*/}
    {/*  <EntityFeedbackResponseContent />*/}
    {/* </EntityLayout.Route>*/}
  </EntityLayoutWrapper>
);

const artifactPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {artifactOverviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item md={6} xs={12}>
          <ParentComponentsCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <HasApplicationCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const applicationEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item md={6} xs={12}>
          <AboutCardTMatic />
        </Grid>
        <Grid item md={6} xs={12}>
          <ApplicationParametersCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <ProvidedApisCard />
        </Grid>
        <Grid item xs={12} md={6}>
          <ApplicationArtifactCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    {/* <EntityLayout.Route path="/docs" title="Docs">*/}
    {/*  {techdocsContent}*/}
    {/* </EntityLayout.Route>*/}

    {/* <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRS">
      <EntityAdrContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route if={isNewRelicDashboardAvailable} path="/newrelic-dashboard" title="New Relic Dashboard">
      <EntityNewRelicDashboardContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route if={isKubernetesAvailable} path="/kubernetes" title="Kubernetes">
      <EntityKubernetesContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/argocd" title="ArgoCD">
      <EntitySwitch>
        <EntitySwitch.Case if={e => Boolean(isArgocdAvailable(e))}>
          <EntityArgoCDOverviewCard />
        </EntitySwitch.Case>
      </EntitySwitch>
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route if={isNomadAllocationsAvailable} path="/nomad" title="Nomad">
      <EntityNomadAllocationListTable />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/pull-requests" title="Pull Requests">
      {pullRequestsContent}
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/code-insights" title="Code Insights">
      <EntityGithubInsightsContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/tech-insights" title="Scorecards">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <EntityTechInsightsScorecardCard title="Scorecard 1" description="This is a sample scorecard no. 1" checksId={['titleCheck']} />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityTechInsightsScorecardCard title="Scorecard 2" checksId={['techDocsCheck']} />
        </Grid>
      </Grid>
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/code-coverage" title="Code Coverage">
      <EntityCodeCoverageContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/kafka" title="Kafka">
      <EntityKafkaContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/todos" title="TODOs">
      <EntityTodoContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/costs" title="Costs">
      <EntityCostInsightsContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/dynatrace" title="Dynatrace" if={isDynatraceAvailable}>
      <DynatraceTab />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/sentry" title="Sentry">
      <EntitySentryContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/grafana" title="Grafana" if={isOverviewDashboardAvailable}>
      <EntityOverviewDashboardViewer />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/datadog" title="Datadog">
      <EntityDatadogContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/metrics" title="Metrics">*/}
    {/*  {metricsContent}*/}
    {/* </EntityLayout.Route>*/}
  </EntityLayoutWrapper>
);

// const componentPage = (
//   <EntitySwitch>
//     <EntitySwitch.Case if={isComponentType('service')}>{serviceEntityPage}</EntitySwitch.Case>
//
//     <EntitySwitch.Case if={isComponentType('website')}>{websiteEntityPage}</EntitySwitch.Case>
//
//     <EntitySwitch.Case if={isComponentType('library')}>{libraryEntityPage}</EntitySwitch.Case>
//
//     <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
//   </EntitySwitch>
// );

const componentPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item md={6} xs={12}>
          <AboutCardTMatic />
        </Grid>
        <Grid item md={6} xs={12}>
          <ComponentRepositoryCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityArgoWorkflowsCard title="Workflow Actions" />
        </Grid>
        <Grid item md={6} xs={12}>
          <ComponentArtifactCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      <EntityApiEditorCard />
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <ComponentArtifactCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <ParentComponentsCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const applicationPage = (
  <EntitySwitch>
    {/* <EntitySwitch.Case if={isComponentType('infrastructure')}>{applicationEntityPage}</EntitySwitch.Case>
    <EntitySwitch.Case if={isComponentType('service')}>{applicationEntityPage}</EntitySwitch.Case> */}

    <EntitySwitch.Case>{applicationEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);

const apiPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item md={6} xs={12}>
          <AboutCardTMatic />
        </Grid>
        <Grid item md={6} xs={12}>
          <HasComponentCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/editor" title="Editor">
      <EntityApiEditorCard />
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <EntityProvidingComponentsCard />
          </Grid>
          <Grid item xs={12} md={6}>
            <EntityConsumingComponentsCard />
          </Grid>
          <Grid item md={6} xs={12}>
            <EntityCatalogGraphCard variant="gridItem" height={400} />
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const userPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" entityFilterKind={customEntityFilterKind} />
        </Grid>
        <Grid item xs={12}>
          <EntityLikeDislikeRatingsCard />
        </Grid>
        {/* <Grid item xs={12}>*/}
        {/*  <EntityLikeDislikeRatingsCard />*/}
        {/* </Grid>*/}
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/metrics" title="Metrics">
      {metricsContent}
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const groupPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityGroupProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" entityFilterKind={customEntityFilterKind} />
        </Grid>
        <Grid item xs={12}>
          <EntityMembersListCard />
        </Grid>
        {/* <Grid item xs={12}>*/}
        {/*  <EntityLikeDislikeRatingsCard />*/}
        {/* </Grid>*/}
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/metrics" title="Metrics">
      {metricsContent}
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const systemPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
        {/* <Grid item md={2}>*/}
        {/*  <InfoCard title="Rate this entity">*/}
        {/*    <LikeDislikeButtons />*/}
        {/*  </InfoCard>*/}
        {/* </Grid>*/}
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/score" title="Score">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12}>
          {/*<EntityScoreCardContent />*/}
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.TOP_BOTTOM}
        title="System Diagram"
        height={700}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        unidirectional={false}
      />
    </EntityLayout.Route>

    <EntityLayout.Route path="/metrics" title="Metrics">
      {metricsContent}
    </EntityLayout.Route>
    {/* <EntityLayout.Route path="/feedback" title="Feedback">*/}
    {/*  <EntityFeedbackResponseContent />*/}
    {/* </EntityLayout.Route>*/}
  </EntityLayoutWrapper>
);

const domainPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
        {/* <Grid item md={2}>*/}
        {/*  <InfoCard title="Rate this entity">*/}
        {/*    <LikeDislikeButtons />*/}
        {/*  </InfoCard>*/}
        {/* </Grid>*/}
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/metrics" title="Metrics">
      {metricsContent}
    </EntityLayout.Route>
    {/* <EntityLayout.Route path="/feedback" title="Feedback">*/}
    {/*  <EntityFeedbackResponseContent />*/}
    {/* </EntityLayout.Route>*/}
  </EntityLayoutWrapper>
);

const resourceDefaultPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <AboutCardTMatic grid={{ md: 6 }} />
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    {/*<EntityLayout.Route path="/puppetdb" title="Puppet" if={isPuppetDbAvailable}>*/}
    {/*  <PuppetDbPage />*/}
    {/*</EntityLayout.Route>*/}
  </EntityLayoutWrapper>
);

const resourceOverview = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <AboutCardTMatic grid={{ md: 6 }} />
    <Grid item md={6}>
      <ResourceClusterStatusCard />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>
    <Grid item md={6}>
      <EntityClusterEventsCard />
    </Grid>
  </Grid>
);

const resourceClusterPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {resourceOverview}
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const resourceControlPlanePage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {resourceOverview}
    </EntityLayout.Route>
    <EntityLayout.Route path="/resources" title="Resources">
      <ResourceClusterRelatedResourcesPage title="XR" />
    </EntityLayout.Route>
    <EntityLayout.Route path="/clusters" title="Clusters">
      <ArgoCDClustersCard />
    </EntityLayout.Route>
    <EntityLayout.Route path="/repositories" title="Repositories">
      <ArgoCDRepositoriesCard />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const resourceClaimPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6} xs={12}>
          <AboutCardTMatic />
        </Grid>
        <Grid item md={6} xs={12}>
          <ClaimParametersCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6} xs={12}>
          <ClaimDetailsCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const resourcePage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isResourceType('k8s-cluster')}>{resourceClusterPage}</EntitySwitch.Case>
    <EntitySwitch.Case if={isResourceType('control-plane')}>{resourceControlPlanePage}</EntitySwitch.Case>
    <EntitySwitch.Case if={isResourceCrossplaneClaim}>{resourceClaimPage}</EntitySwitch.Case>
    <EntitySwitch.Case>{resourceDefaultPage}</EntitySwitch.Case>
  </EntitySwitch>
);

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('application')} children={applicationPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('artifact')} children={artifactPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />
    <EntitySwitch.Case if={isKind('resource')} children={resourcePage} />

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
