import React, { useMemo } from 'react';
import _ from 'lodash';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { Select } from '@backstage/core-components';
import { RepoUrlPickerState } from './types';
import { useCommonStyles } from './styles';
import { CustomFormLabel } from './CustomFormLabel';

export interface GithubRepoPickerProps {
  allowedOwners?: string[];
  rawErrors: string[];
  state: RepoUrlPickerState;
  onChange: (state: RepoUrlPickerState) => void;
  disabled?: boolean;
}

export const GithubRepoPicker = (props: GithubRepoPickerProps) => {
  const classes = useCommonStyles();

  const { rawErrors, state, onChange, disabled } = props;
  const allowedOwners = useMemo(() => props.allowedOwners, [props.allowedOwners]);
  const { owner } = state;
  const label = (<CustomFormLabel text="Organization *" pull="right" />) as unknown as string;
  return (
    <FormControl className={classes.fullWidthInput} margin="normal" required error={rawErrors?.length > 0 && !owner}>
      {allowedOwners?.length ? (
        <Select
          className={classes.fullWidthInput}
          label={label}
          onChange={s => onChange({ owner: String(Array.isArray(s) ? s[0] : s) })}
          disabled={disabled}
          selected={owner!}
          items={_.map(allowedOwners, value => ({ label: value, value }))}
        />
      ) : (
        <TextField className={classes.fullWidthInput} id="ownerInput" label={label} onChange={e => onChange({ owner: e.target.value })} value={owner} disabled={disabled} />
      )}
      <FormHelperText>The organization, user or project that this repo will belong to</FormHelperText>
    </FormControl>
  );
};
