import React from 'react';
import { CodeSnippet } from '@backstage/core-components';
import { useTheme } from '@material-ui/core/styles';
import { BackstageTheme } from '@backstage/theme';

/** @public */
export type GrpcApiDefinitionWidgetProps = {
  definition: string;
};

/** @public */
export const GrpcApiDefinitionWidget = (props: GrpcApiDefinitionWidgetProps) => {
  const theme = useTheme<BackstageTheme>();
  return <CodeSnippet customStyle={{ backgroundColor: theme.palette.background.default }} text={props.definition} language="protobuf" showCopyCodeButton />;
};
