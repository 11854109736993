import React, { ReactNode } from 'react';
import './MuiClassNameSetup';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider, StylesProvider, createGenerateClassName, Theme as Mui4Theme } from '@material-ui/core/styles';
import { StyledEngineProvider, ThemeProvider as Mui5Provider, Theme as Mui5Theme } from '@mui/material/styles';
import { UnifiedTheme } from './types';

/**
 * Props for {@link UnifiedThemeProvider}.
 *
 * @public
 */
export interface UnifiedThemeProviderProps {
  children: ReactNode;
  theme: UnifiedTheme;
  noCssBaseline?: boolean;
}

// Background at https://mui.com/x/migration/migration-data-grid-v4/#using-mui-core-v4-with-v5
// Rather than disabling globals and custom seed, we instead only set a production prefix that
// won't collide with Material UI 5 styles. We've already got a separate class name generator for v5 set
// up in MuiClassNameSetup.ts, so only the production JSS needs deduplication.
const generateV4ClassName = createGenerateClassName({
  productionPrefix: 'jss4-',
});

/**
 * Provides themes for all Material UI versions supported by the provided unified theme.
 *
 * @public
 */
export function UnifiedThemeProvider(props: UnifiedThemeProviderProps): JSX.Element {
  const { children, theme, noCssBaseline = false } = props;

  const v4Theme = theme.getTheme('v4') as Mui4Theme;
  const v5Theme = theme.getTheme('v5') as Mui5Theme;

  let cssBaseline: JSX.Element | undefined = undefined;
  if (!noCssBaseline) {
    cssBaseline = <CssBaseline />;
  }

  let result = (
    <>
      {cssBaseline}
      {children}
    </>
  );

  if (v4Theme) {
    result = (
      <StylesProvider generateClassName={generateV4ClassName}>
        <ThemeProvider theme={v4Theme}>{result}</ThemeProvider>
      </StylesProvider>
    );
  }

  if (v5Theme) {
    result = (
      <StyledEngineProvider injectFirst>
        <Mui5Provider theme={v5Theme}>{result}</Mui5Provider>
      </StyledEngineProvider>
    );
  }

  return result;
}
