import { RepoUrlPickerState } from './types';

export function serializeRepoPickerUrl(data: RepoUrlPickerState) {
  if (!data.host) {
    return undefined;
  }

  const params = new URLSearchParams();
  if (data.owner) {
    params.set('owner', data.owner);
  }
  if (data.repoName) {
    params.set('repo', data.repoName);
  }
  if (data.organization) {
    params.set('organization', data.organization);
  }
  if (data.workspace) {
    params.set('workspace', data.workspace);
  }
  if (data.project) {
    params.set('project', data.project);
  }

  return `${data.host}?${params.toString()}`;
}

export function parseRepoPickerUrl(url: string | undefined): RepoUrlPickerState {
  let host = '';
  let owner = '';
  let repoName = '';
  let organization = '';
  let workspace = '';
  let project = '';

  try {
    if (url) {
      const parsed = new URL(`https://${url}`);
      host = parsed.host;
      owner = parsed.searchParams.get('owner') || '';
      repoName = parsed.searchParams.get('repo') || '';
      organization = parsed.searchParams.get('organization') || '';
      workspace = parsed.searchParams.get('workspace') || '';
      project = parsed.searchParams.get('project') || '';
    }
  } catch {
    /* ok */
  }

  return { host, owner, repoName, organization, workspace, project };
}
