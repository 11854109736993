import { JSONSchema7 } from 'json-schema';

export const RunApplicationPayload: JSONSchema7 = {
  type: 'object',
  properties: {
    templateRef: { type: 'string', minLength: 1 },
    values: {
      type: 'object',
      properties: {
        deploymentName: { type: 'string', format: 'k8s-name', minLength: 1 },
        description: { type: 'string' },
        owner: { type: 'string', minLength: 1 },
        system: { type: 'string', minLength: 1 },
        subdomain: {
          type: 'string',
          minLength: 2,
          maxLength: 24,
          pattern: '^[a-zA-Z][a-zA-Z0-9-]*$',
        },
        artifactName: { type: 'string', minLength: 1 },
        argoCDInstance: { type: 'string', minLength: 1 },
        argoCDProject: { type: 'string', minLength: 1 },
        clusterName: { type: 'string', minLength: 1 },
        namespace: { type: 'string', minLength: 1, format: 'k8s-name' },
        repoUrl: { type: 'string', minLength: 1 },
      },
      required: ['subdomain', 'artifactName', 'argoCDInstance', 'argoCDProject', 'clusterName', 'namespace', 'repoUrl', 'deploymentName', 'owner', 'system'],
    },
    secrets: {
      type: 'object',
      properties: {
        USER_OAUTH_TOKEN: { type: 'string', minLength: 1 },
      },
    },
  },
  required: ['templateRef', 'values'],
};
