import { TenantSettings } from '@tmatic/tenant-settings-common';
import { createContext, useContext } from 'react';

export const TenantSettingsContext = createContext<TenantSettings>({});

export const useTenantSettings = () => {
  const context = useContext(TenantSettingsContext);
  if (!context) {
    throw new Error('useTenantSettings must be used within a TenantSettingsProvider');
  }
  return context;
};
