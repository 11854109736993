import { explorePlugin } from './plugin';
import { exploreRouteRef } from './routes';
import { createComponentExtension, createRoutableExtension } from '@backstage/core-plugin-api';

/** @public */
export const ExplorePage = explorePlugin.provide(
  createRoutableExtension({
    name: 'ExplorePage',
    component: () => import('./components/ExplorePage').then(m => m.ExplorePage),
    mountPoint: exploreRouteRef,
  }),
);

/** @public */
export const DomainExplorerContent = explorePlugin.provide(
  createComponentExtension({
    name: 'DomainExplorerContent',
    component: {
      lazy: () => import('./components/DomainExplorerContent').then(m => m.DomainExplorerContent),
    },
  }),
);

/** @public */
export const GroupsExplorerContent = explorePlugin.provide(
  createComponentExtension({
    name: 'GroupsExplorerContent',
    component: {
      lazy: () => import('./components/GroupsExplorerContent').then(m => m.GroupsExplorerContent),
    },
  }),
);

/** @public */
export const ToolExplorerContent = explorePlugin.provide(
  createComponentExtension({
    name: 'ToolExplorerContent',
    component: {
      lazy: () => import('./components/ToolExplorerContent').then(m => m.ToolExplorerContent),
    },
  }),
);
