import { JsonValue } from '@backstage/types';
import qs from 'qs';
import { useState } from 'react';

/**
 * This hook is used to get the formData from the query string.
 * @alpha
 */
export const useFormDataFromQuery = (initialState?: Record<string, JsonValue>) => {
  return useState<Record<string, any>>(() => {
    if (initialState) {
      return initialState;
    }

    const query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    try {
      return JSON.parse(query.formData as string);
    } catch (e) {
      return {};
    }
  });
};
