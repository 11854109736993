import { BackstageTheme } from '@backstage/theme';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { useDryRun } from '../DryRunContext';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles(() => {
  const theme = useTheme<BackstageTheme>();
  return {
    root: {
      overflowY: 'auto',
      background: theme.palette.background.default,
    },
    iconSuccess: {
      minWidth: 0,
      marginRight: theme.spacing(1),
      color: theme.palette.status.ok,
    },
    iconFailure: {
      minWidth: 0,
      marginRight: theme.spacing(1),
      color: theme.palette.status.error,
    },
  };
});

export function DryRunResultsList() {
  const classes = useStyles();
  const dryRun = useDryRun();

  return (
    <List className={classes.root} dense>
      {dryRun.results.map(result => {
        const failed = result.log.some(l => l.body.status === 'failed');
        return (
          <ListItem button key={result.id} selected={dryRun.selectedResult?.id === result.id} onClick={() => dryRun.selectResult(result.id)}>
            <ListItemIcon className={failed ? classes.iconFailure : classes.iconSuccess}>{failed ? <CancelIcon /> : <CheckIcon />}</ListItemIcon>
            <ListItemText primary={`Result ${result.id}`} />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete" onClick={() => dryRun.deleteResult(result.id)} size="large">
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
}
