import { Components } from '@mui/material/styles';
import colors from '../colors';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    straight: true;
  }
}

export const Button: Pick<Components, 'MuiButton' | 'MuiButtonBase' | 'MuiAutocomplete'> = {
  MuiButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        borderRadius: '8px',
        padding: '8px 25px',
        textTransform: 'none',
        '&.BackstageSidebarItem-buttonItem': {
          borderRadius: '0',
        },
      },
      sizeSmall: {
        padding: '4px 25px',
        fontSize: '10px',
        lineHeight: '14px',
      },
      sizeMedium: {
        fontSize: '12px',
        lineHeight: '16px',
      },
      sizeLarge: {
        fontSize: '15px',
        lineHeight: '24px',
      },
      text: ({ theme }) => ({
        ...(theme.palette.mode === 'dark'
          ? {
              backgroundColor: colors.backgrounds.dark.light,
              boxShadow: `0 2px 4px ${colors.atomicColors.black}`,
              color: colors.backgrounds.light.main,
              '&:active': {
                backgroundColor: colors.backgrounds.dark.light,
              },
            }
          : {
              backgroundColor: colors.backgrounds.light.main,
              boxShadow: `0 2px 4px ${colors.backgrounds.dark.contrastText}`,
              '&:active': {
                backgroundColor: colors.backgrounds.light.dark,
              },
            }),
        borderRadius: '40px',
        '&:hover': {
          backgroundColor: theme.palette.mode === 'dark' ? colors.backgrounds.dark.contrastText : colors.backgrounds.light.light,
        },
      }),
      textPrimary: ({ theme }) => ({
        ...(theme.palette.mode === 'dark'
          ? {
              backgroundColor: colors.backgrounds.dark.light,
              boxShadow: `0 2px 4px ${colors.atomicColors.black}`,
              color: colors.backgrounds.light.main,
              '&:active': {
                backgroundColor: colors.backgrounds.dark.light,
              },
            }
          : {
              backgroundColor: colors.backgrounds.light.main,
              boxShadow: `0 2px 4px ${colors.backgrounds.dark.contrastText}`,
              '&:active': {
                backgroundColor: colors.backgrounds.light.dark,
              },
            }),
        borderRadius: '40px',
        '&:hover': {
          backgroundColor: theme.palette.mode === 'dark' ? colors.backgrounds.dark.contrastText : colors.backgrounds.light.light,
        },
      }),
      outlined: ({ theme }) => ({
        borderWidth: '2px',
        // padding: '6px 23px',
        ...(theme.palette.mode === 'dark'
          ? {
              color: colors.backgrounds.light.main,
              '&:hover': {
                border: `2px solid ${colors.primary.light}`,
                backgroundColor: colors.primary.light,
              },
              '&:active': {
                backgroundColor: colors.primary.main,
              },
              '&.v5-MuiButton-outlinedError': {
                '&:hover': {
                  border: `2px solid ${colors.system.red.dark}`,
                  backgroundColor: colors.system.red.main,
                },
                '&:active': {
                  backgroundColor: colors.primary.main,
                },
              },
            }
          : {
              color: colors.backgrounds.dark.main,
              '&:hover': {
                border: `2px solid ${colors.primary.contrastText}`,
                backgroundColor: colors.primary.contrastText,
              },
              '&:active': {
                backgroundColor: colors.primary.light,
              },
            }),
      }),
      containedPrimary: {
        '&:hover': {
          backgroundColor: colors.primary.light,
        },
        '&:active': {
          backgroundColor: colors.primary.dark,
        },
      },
      containedSuccess: {
        backgroundColor: colors.system.green.dark,
        '&:hover': {
          backgroundColor: colors.system.green.main,
        },
        '&:active': {
          backgroundColor: colors.atomicColors.darkGreen,
        },
      },
      containedError: {
        backgroundColor: colors.system.red.dark,
        '&:hover': {
          backgroundColor: colors.system.red.main,
        },
        '&:active': {
          backgroundColor: colors.atomicColors.darkRed,
        },
      },
    },
    variants: [
      {
        props: { variant: 'straight' },
        style: {
          borderRadius: 0,
        },
      },
    ],
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        borderRadius: '8px',
        padding: '8px 25px',
        '&.BackstageSidebarItem-buttonItem': {
          borderRadius: '0',
        },
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      endAdornment: {
        '& .MuiButtonBase-root': {
          padding: 0,
        },
      },
    },
  },
};
