import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_OWNED_BY,
  RELATION_OWNER_OF,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FeatureFlagged, FlatRoutes } from '@backstage/core-app-api';
import { AlertDisplay, OAuthRequestDialog, SignInPage } from '@backstage/core-components';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
// import { AzurePullRequestsPage } from '@backstage/plugin-azure-devops';
// import { ConfiguratorPage } from '@tmatic/plugin-configurator';
import { CatalogEntityPage, CatalogIndexPage, catalogPlugin } from '@tmatic/plugin-catalog-customized';

import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { CatalogImportPage, catalogImportPlugin } from '@backstage/plugin-catalog-import';
import { CostInsightsLabelDataflowInstructionsPage, CostInsightsPage, CostInsightsProjectGrowthInstructionsPage } from '@backstage/plugin-cost-insights';
import { orgPlugin } from '@backstage/plugin-org';
import { ExplorePage } from '@backstage/plugin-explore';
// import { GcpProjectsPage } from '@backstage/plugin-gcp-projects';
import { GraphiQLPage } from '@backstage/plugin-graphiql';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
// import { LighthousePage } from '@backstage/plugin-lighthouse';
// import { NewRelicPage } from '@backstage/plugin-newrelic';
import { NextScaffolderPage } from '@backstage/plugin-scaffolder/alpha';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { ScaffolderFieldExtensions, ScaffolderLayouts } from '@backstage/plugin-scaffolder-react';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import { TechDocsIndexPage, techdocsPlugin, TechDocsReaderPage } from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ExpandableNavigation, LightBox, ReportIssue, TextSize } from '@backstage/plugin-techdocs-module-addons-contrib';
import { SettingsLayout, UserSettingsPage } from '@backstage/plugin-user-settings';
import { AdvancedSettings } from './components/advancedSettings';
import AlarmIcon from '@mui/icons-material/Alarm';
import React from 'react';
import { Route } from 'react-router-dom';
import { apis, tmaticOIDCAuthApiRef } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { homePage } from './components/home/HomePage';
import { Root } from './components/Root';
import {
  DelayingComponentFieldExtension,
  LowerCaseValuePickerFieldExtension,
  ArgocdAppNamePickerExtension,
  NamespacePickerExtension,
} from './components/scaffolder/customScaffolderExtensions';
import { defaultPreviewTemplate } from './components/scaffolder/defaultPreviewTemplate';
import { searchPage } from './components/search/SearchPage';
import * as plugins from './plugins';

import { techDocsPage } from './components/techdocs/TechDocsPage';
// import { ApacheAirflowPage } from '@backstage/plugin-apache-airflow';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { PlaylistIndexPage } from '@backstage/plugin-playlist';
import { TwoColumnLayout } from './components/scaffolder/customScaffolderLayouts';
// import { StackstormPage } from '@backstage/plugin-stackstorm';
// import { PuppetDbPage } from '@backstage/plugin-puppetdb';
import { DevToolsPage } from '@backstage/plugin-devtools';
import { customDevToolsPage } from './components/devtools/CustomDevToolsPage';
import { CatalogUnprocessedEntitiesPage } from '@backstage/plugin-catalog-unprocessed-entities';
import LightIcon from '@mui/icons-material/WbSunny';
import ModeNightIcon from '@mui/icons-material/NightsStaySharp';
import tmaticLightTheme from './datasets/themes/lightTheme';
import tmaticDarkTheme from './datasets/themes/darkTheme';
import { UnifiedThemeProvider } from '@backstage/theme';
import { ConfiguratorPage } from '@tmatic/plugin-configurator';
import {
  DynamicContentPickerFieldExtension,
  ResourcesListPage,
  APIListPage,
  ApplicationsListPage,
  ArgoCDResourcesReaderFieldExtension,
  ArtifactsListPage,
  ComponentsListPage,
} from '@tmatic/entity-management-react';
import { CustomFileInputExtension } from '@tmatic/scaffolder-custom-actions-react';
import { DevopsPage } from '@tmatic/plugin-devops';
import { MetricsListPageComponent, MetricCrudPageComponent } from '@tmatic/performance-management';
import { SelectFieldFromApiExtension } from '@roadiehq/plugin-scaffolder-frontend-module-http-request-field';
import { PlatformToolsPage } from '@tmatic/platform-tools';
import { TenantSettingsProvider } from '@tmatic/tenant-settings';

const app = createApp({
  apis,
  plugins: Object.values(plugins),
  icons: {
    // @ts-ignore Custom icon example
    alert: AlarmIcon,
  },
  featureFlags: [
    {
      name: 'scaffolder-next-preview',
      description: 'Preview the new Scaffolder Next',
      pluginId: '',
    },
  ],
  themes: [
    {
      id: 'custom-light-theme',
      title: 'Light',
      variant: 'light',
      icon: <LightIcon />,
      Provider: ({ children }) => <UnifiedThemeProvider theme={tmaticLightTheme} children={children} />,
    },
    {
      id: 'custom-dark-theme',
      title: 'Dark',
      variant: 'dark',
      // @TODO Search for appropriate Backstage icon for dark mode
      icon: <ModeNightIcon />,
      Provider: ({ children }) => <UnifiedThemeProvider theme={tmaticDarkTheme} children={children} />,
    },
  ],
  components: {
    SignInPage: props => (
      <SignInPage
        {...props}
        auto
        provider={{
          id: 'tmatic',
          title: 'T-Matic',
          message: 'Welcome on T-Matic Platform',
          apiRef: tmaticOIDCAuthApiRef,
        }}
      />
    ),
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot as any,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate as any,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      {' '}
      {homePage}{' '}
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route path="/catalog/:namespace/:kind/:name" element={<CatalogEntityPage />}>
      {' '}
      {entityPage}{' '}
    </Route>
    <Route path="/catalog-unprocessed-entities" element={<CatalogUnprocessedEntitiesPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route
      path="/catalog-graph"
      element={
        <CatalogGraphPage
          initialState={{
            selectedKinds: ['component', 'domain', 'system', 'api', 'group'],
            selectedRelations: [
              RELATION_OWNER_OF,
              RELATION_OWNED_BY,
              RELATION_CONSUMES_API,
              RELATION_API_CONSUMED_BY,
              RELATION_PROVIDES_API,
              RELATION_API_PROVIDED_BY,
              RELATION_HAS_PART,
              RELATION_PART_OF,
              RELATION_DEPENDS_ON,
              RELATION_DEPENDENCY_OF,
            ],
          }}
        />
      }
    />
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route path="/docs/:namespace/:kind/:name/*" element={<TechDocsReaderPage />}>
      {techDocsPage}
      <TechDocsAddons>
        <ExpandableNavigation />
        <ReportIssue />
        <TextSize />
        <LightBox />
      </TechDocsAddons>
    </Route>
    <FeatureFlagged with="scaffolder-next-preview">
      <Route
        path="/create"
        element={
          <NextScaffolderPage
            groups={[
              {
                title: 'Recommended',
                filter: entity => entity?.metadata?.tags?.includes('recommended') ?? false,
              },
            ]}
          />
        }
      >
        <ScaffolderFieldExtensions>
          <DelayingComponentFieldExtension />
          <DynamicContentPickerFieldExtension />
          <ArgoCDResourcesReaderFieldExtension />
          <CustomFileInputExtension />
          <SelectFieldFromApiExtension />
          <ArgocdAppNamePickerExtension />
          <NamespacePickerExtension />
        </ScaffolderFieldExtensions>
        <ScaffolderLayouts>
          <TwoColumnLayout />
        </ScaffolderLayouts>
      </Route>
    </FeatureFlagged>
    <FeatureFlagged without="scaffolder-next-preview">
      <Route
        path="/create"
        element={
          <ScaffolderPage
            defaultPreviewTemplate={defaultPreviewTemplate}
            groups={[
              {
                title: 'Recommended',
                filter: entity => entity?.metadata?.tags?.includes('recommended') ?? false,
              },
            ]}
          />
        }
      >
        <ScaffolderFieldExtensions>
          <LowerCaseValuePickerFieldExtension />
          <DynamicContentPickerFieldExtension />
          <ArgoCDResourcesReaderFieldExtension />
          <CustomFileInputExtension />
          <SelectFieldFromApiExtension />
          <ArgocdAppNamePickerExtension />
          <NamespacePickerExtension />
        </ScaffolderFieldExtensions>
        <ScaffolderLayouts>
          <TwoColumnLayout />
        </ScaffolderLayouts>
      </Route>
    </FeatureFlagged>
    <Route path="/explore" element={<ExplorePage />} />
    <Route path="/tech-radar" element={<TechRadarPage width={1500} height={800} />} />
    <Route path="/graphiql" element={<GraphiQLPage />} />
    {/*<Route path="/lighthouse" element={<LighthousePage />} />*/}
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    {/*<Route path="/gcp-projects" element={<GcpProjectsPage />} />*/}
    {/*<Route path="/newrelic" element={<NewRelicPage />} />*/}
    <Route path="/search" element={<SearchPage />}>
      {' '}
      {searchPage}{' '}
    </Route>
    <Route path="/cost-insights" element={<CostInsightsPage />} />
    <Route path="/cost-insights/investigating-growth" element={<CostInsightsProjectGrowthInstructionsPage />} />
    <Route path="/cost-insights/labeling-jobs" element={<CostInsightsLabelDataflowInstructionsPage />} />
    <Route path="/settings" element={<UserSettingsPage />}>
      <SettingsLayout.Route path="/advanced" title="Advanced">
        <AdvancedSettings />
      </SettingsLayout.Route>
      <SettingsLayout.Route path="/tools" title="Tools">
        <PlatformToolsPage />
      </SettingsLayout.Route>
    </Route>
    <Route path="/configurator" element={<ConfiguratorPage />} />
    {/* <Route path="/azure-pull-requests" element={<AzurePullRequestsPage />} /> */}
    {/* <Route path="/apache-airflow" element={<ApacheAirflowPage />} /> */}
    <Route path="/playlist" element={<PlaylistIndexPage />} />
    {/* <Route path="/stackstorm" element={<StackstormPage />} /> */}
    {/* <Route path="/puppetdb" element={<PuppetDbPage />} /> */}
    <Route path="/devtools" element={<DevToolsPage />}>
      {' '}
      {customDevToolsPage}{' '}
    </Route>
    <Route path="/configurator" element={<ConfiguratorPage />} />
    <Route path="/devops" element={<DevopsPage />} />
    <Route path="/metrics" element={<MetricsListPageComponent />} />
    <Route path="/metrics/:id" element={<MetricCrudPageComponent />} />
    <Route path="/k8s-clusters" element={<ResourcesListPage type="k8s-cluster" />} />
    <Route path="/control-planes" element={<ResourcesListPage type="control-plane" />} />
    <Route path="/apis" element={<APIListPage type="openapi" />} />
    <Route path="/artifacts" element={<ArtifactsListPage />} />
    <Route path="/components" element={<ComponentsListPage />} />
    <Route path="/applications" element={<ApplicationsListPage type="service" />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay transientTimeoutMs={2500} />
    <OAuthRequestDialog />
    <AppRouter>
      <TenantSettingsProvider>
        <Root>{routes}</Root>
      </TenantSettingsProvider>
    </AppRouter>
  </>,
);
