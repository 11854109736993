import { Components } from '@mui/material/styles';

export const Typography: Pick<Components, 'MuiTypography'> = {
  MuiTypography: {
    styleOverrides: {
      root: {
        fontFamily: 'Inter',
      },
      h1: {
        fontSize: '40px',
        fontWeight: 700,
        lineHeight: '54px',
      },
      h2: {
        fontSize: '32px',
        fontWeight: 700,
        lineHeight: '44px',
      },
      h3: {
        fontSize: '28px',
        fontWeight: 700,
        lineHeight: '38px',
      },
      h4: {
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '32px',
      },
      h5: {
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '28px',
      },
      h6: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '22px',
      },
      body1: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
      },
      body2: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
      },
      overline: {
        fontSize: '14px',
        letterSpacing: 2,
        fontWeight: 400,
      },
      caption: {
        fontSize: '12px',
        fontWeight: 400,
      },
    },
  },
};
