import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import React, { memo } from 'react';

interface Props {
  pickerProps: any;
  selected: boolean;
  value: string;
  availableOptions?: Record<string, number>;
  showCounts: boolean;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function OptionCheckbox({ selected }: { selected: boolean }) {
  return <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />;
}

export const EntityAutocompletePickerOption = memo((props: Props) => {
  const { selected, value, availableOptions, showCounts, pickerProps } = props;
  const label = showCounts ? `${value} (${availableOptions?.[value]})` : value;

  return <FormControlLabel {...pickerProps} control={<OptionCheckbox selected={selected} />} label={label} />;
});
