import React from 'react';
import { EntityPicker } from '../EntityPicker/EntityPicker';
import { OwnerPickerProps } from './schema';

export { OwnerPickerSchema } from './schema';

/**
 * The underlying component that is rendered in the form for the `OwnerPicker`
 * field extension.
 *
 * @public
 */
export const OwnerPicker = (props: OwnerPickerProps) => {
  const {
    schema: { title = 'Owner', description = 'The owner of the component' },
    uiSchema,
    ...restProps
  } = props;

  const defaultNamespace = uiSchema['ui:options']?.defaultNamespace;
  const allowedKinds = uiSchema['ui:options']?.allowedKinds;

  const catalogFilter = uiSchema['ui:options']?.catalogFilter || {
    kind: allowedKinds || ['Group', 'User'],
  };

  const ownerUiSchema = {
    ...uiSchema,
    'ui:options': {
      catalogFilter,
      defaultKind: 'Group',
      allowArbitraryValues: uiSchema['ui:options']?.allowArbitraryValues ?? true,
      ...(defaultNamespace !== undefined ? { defaultNamespace } : {}),
    },
  };

  return <EntityPicker {...restProps} schema={{ title, description }} uiSchema={ownerUiSchema} />;
};
