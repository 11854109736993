import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { EntityTagFilter } from '../../filters';
import { EntityAutocompletePicker } from '../EntityAutocompletePicker';

/** @public */
export type CatalogReactEntityTagPickerClassKey = 'input';

/** @public */
export type EntityTagPickerProps = {
  showCounts?: boolean;
};

const useStyles = makeStyles({ input: {} }, { name: 'CatalogReactEntityTagPicker' });

/** @public */
export const EntityTagPicker = (props: EntityTagPickerProps) => {
  const classes = useStyles();

  return (
    <EntityAutocompletePicker label="Tags" name="tags" path="metadata.tags" Filter={EntityTagFilter} showCounts={props.showCounts} InputProps={{ className: classes.input }} />
  );
};
