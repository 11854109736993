import { Entity } from '@backstage/catalog-model';
import { EntityRefLink } from '../EntityRefLink';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import React, { useCallback, useState } from 'react';
import { useUnregisterEntityDialogState } from './useUnregisterEntityDialogState';

import { alertApiRef, configApiRef, useApi } from '@backstage/core-plugin-api';
import { Progress, ResponseErrorPanel } from '@backstage/core-components';
import { assertError } from '@backstage/errors';

const useStyles = makeStyles({
  advancedButton: {
    fontSize: '0.7em',
  },
  dialogActions: {
    display: 'inline-block',
  },
});

const Contents = ({ entity, onConfirm, onClose }: { entity: Entity; onConfirm: () => any; onClose: () => any }) => {
  const alertApi = useApi(alertApiRef);
  const configApi = useApi(configApiRef);
  const classes = useStyles();
  const state = useUnregisterEntityDialogState(entity);
  const [showDelete, setShowDelete] = useState(false);
  const [busy, setBusy] = useState(false);
  const appTitle = configApi.getOptionalString('app.title') ?? 'Backstage';

  const onUnregister = useCallback(
    async function onUnregisterFn() {
      if ('unregisterLocation' in state) {
        setBusy(true);
        try {
          await state.unregisterLocation();
          onConfirm();
        } catch (err) {
          assertError(err);
          alertApi.post({ message: err.message });
        } finally {
          setBusy(false);
        }
      }
    },
    [alertApi, onConfirm, state],
  );

  const onDelete = useCallback(
    async function onDeleteFn() {
      if ('deleteEntity' in state) {
        setBusy(true);
        try {
          await state.deleteEntity();
          const entityName = entity.metadata.title ?? entity.metadata.name;
          onConfirm();
          alertApi.post({
            message: `Removed entity ${entityName}`,
            severity: 'success',
            display: 'transient',
          });
        } catch (err) {
          assertError(err);
          alertApi.post({ message: err.message });
        } finally {
          setBusy(false);
        }
      }
    },
    [alertApi, onConfirm, state, entity],
  );

  const DialogActionsPanel = () => (
    <DialogActions className={classes.dialogActions}>
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
    </DialogActions>
  );

  if (state.type === 'loading') {
    return <Progress />;
  }

  if (state.type === 'error') {
    return <ResponseErrorPanel error={state.error} />;
  }

  if (state.type === 'bootstrap') {
    return (
      <>
        <Alert severity="info">
          You cannot unregister this entity, since it originates from a protected Backstage configuration (location "{state.location}"). If you believe this is in error, please
          contact the {appTitle} integrator.
        </Alert>

        <Box marginTop={2}>
          {!showDelete && (
            <>
              <Button variant="text" size="small" color="primary" className={classes.advancedButton} onClick={() => setShowDelete(true)}>
                Advanced Options
              </Button>
              <DialogActionsPanel />
            </>
          )}

          {showDelete && (
            <>
              <DialogContentText>
                You have the option to delete the entity itself from the catalog. Note that this should only be done if you know that the catalog file has been deleted at, or moved
                from, its origin location. If that is not the case, the entity will reappear shortly as the next refresh round is performed by the catalog.
              </DialogContentText>
              <Button variant="contained" color="secondary" disabled={busy} onClick={onDelete}>
                Delete Entity
              </Button>
              <DialogActionsPanel />
            </>
          )}
        </Box>
      </>
    );
  }

  if (state.type === 'only-delete') {
    return (
      <>
        <DialogContentText>
          This entity does not seem to originate from a registered location. You therefore only have the option to delete it outright from the catalog.
        </DialogContentText>
        <Button variant="contained" color="secondary" disabled={busy} onClick={onDelete}>
          Delete Entity
        </Button>
        <DialogActionsPanel />
      </>
    );
  }

  if (state.type === 'unregister') {
    return (
      <>
        <DialogContentText>This action will unregister the following entities:</DialogContentText>
        <DialogContentText component="ul">
          {state.colocatedEntities.map(e => (
            <li key={`${e.kind}:${e.namespace}/${e.name}`}>
              <EntityRefLink entityRef={e} />
            </li>
          ))}
        </DialogContentText>
        <DialogContentText>Located at the following location:</DialogContentText>
        <DialogContentText component="ul">
          <li>{state.location}</li>
        </DialogContentText>
        <DialogContentText>To undo, just re-register the entity in {appTitle}.</DialogContentText>
        <Box marginTop={2}>
          <Button variant="contained" color="secondary" disabled={busy} onClick={onUnregister}>
            Unregister Location
          </Button>
          {!showDelete && (
            <Box component="span" marginLeft={2}>
              <Button variant="text" size="small" color="primary" className={classes.advancedButton} onClick={() => setShowDelete(true)}>
                Advanced Options
              </Button>
            </Box>
          )}
        </Box>

        {showDelete && (
          <>
            <Box paddingTop={4} paddingBottom={4}>
              <Divider />
            </Box>
            <DialogContentText>
              You also have the option to delete the entity itself from the catalog. Note that this should only be done if you know that the catalog file has been deleted at, or
              moved from, its origin location. If that is not the case, the entity will reappear shortly as the next refresh round is performed by the catalog.
            </DialogContentText>
            <Button variant="contained" color="secondary" disabled={busy} onClick={onDelete}>
              Delete Entity
            </Button>
          </>
        )}
      </>
    );
  }

  return <Alert severity="error">Internal error: Unknown state</Alert>;
};

/** @public */
export type UnregisterEntityDialogProps = {
  open: boolean;
  onConfirm: () => any;
  onClose: () => any;
  entity: Entity;
};

/** @public */
export const UnregisterEntityDialog = (props: UnregisterEntityDialogProps) => {
  const { open, onConfirm, onClose, entity } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="responsive-dialog-title">Are you sure you want to unregister this entity?</DialogTitle>
      <DialogContent>
        <Contents entity={entity} onConfirm={onConfirm} onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};
