import Box from '@mui/material/Box';
import React from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useAsync } from 'react-use';

export const Disclaimer = () => {
  const catalogClient = useApi(catalogApiRef);
  const result = useAsync(async () => {
    return catalogClient.performCustomRequest<undefined, { disclaimer: string }>({
      method: 'GET',
      url: { suffix: 'tools/disclaimer' },
    });
  });
  return !result.value?.disclaimer ? null : (
    <Box style={{ background: 'yellow', color: 'black', width: '100%' }}>
      <div style={{ WebkitOverflowScrolling: 'touch' }}>
        <div style={{ padding: '0 5px', display: 'inline-block', textAlign: 'center' }}>{result.value.disclaimer}</div>
      </div>
    </Box>
  );
};
