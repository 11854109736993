import { palettes } from '../base';
import { createUnifiedTheme } from './UnifiedTheme';

/**
 * Built-in Backstage Material UI themes.
 *
 * @public
 */
export const themes = {
  light: createUnifiedTheme({ palette: palettes.light }),
  dark: createUnifiedTheme({ palette: palettes.dark }),
};
