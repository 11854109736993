import { Entity, CompoundEntityRef, stringifyEntityRef } from '@backstage/catalog-model';
import React from 'react';
import { EntityRefLink } from './EntityRefLink';
import { LinkProps } from '@backstage/core-components';

/**
 * Props for {@link EntityRefLink}.
 *
 * @public
 */
export type EntityRefLinksProps<TRef extends string | CompoundEntityRef | Entity> = {
  noIcon?: boolean;
  defaultKind?: string;
  entityRefs: TRef[];
  /** @deprecated This option is no longer used; presentation is handled by entityPresentationApiRef instead */
  fetchEntities?: boolean;
  /** @deprecated This option is no longer used; presentation is handled by entityPresentationApiRef instead */
  getTitle?(entity: TRef): string | undefined;
} & Omit<LinkProps, 'to'>;

/**
 * Shows a list of clickable links to entities.
 *
 * @public
 */
export function EntityRefLinks<TRef extends string | CompoundEntityRef | Entity>(props: EntityRefLinksProps<TRef>) {
  const { entityRefs, noIcon, ...linkProps } = props;

  return (
    <>
      {entityRefs.map((r: TRef, i: number) => {
        const entityRefString = typeof r === 'string' ? r : stringifyEntityRef(r);
        return (
          <React.Fragment key={`${i}.${entityRefString}`}>
            {i > 0 && ', '}
            <EntityRefLink {...linkProps} entityRef={r} noIcon={noIcon} />
          </React.Fragment>
        );
      })}
    </>
  );
}
