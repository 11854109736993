import { Progress } from '@backstage/core-components';
import { BackstageTheme } from '@backstage/theme';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import _ from 'lodash';
import { CustomFormLabel } from '../../CustomFormLabel';
import { AddRepositoriesToControlPlaneModalProps } from './types';

const useStyles = makeStyles(() => {
  const theme = useTheme<BackstageTheme>();
  return {
    modal: {
      justifyContent: 'center',
      [theme.breakpoints.up('xs')]: {
        minWidth: '480px',
      },
      [theme.breakpoints.up('md')]: {
        minWidth: '640px',
      },
      [theme.breakpoints.up('lg')]: {
        minWidth: '800px',
      },
    },
  };
});

export const AddRepositoryToControlPlaneModal = (props: AddRepositoriesToControlPlaneModalProps) => {
  const classes = useStyles();
  const { open, title = 'Add new', toggleModal, onSubmit } = props;
  const [form, setForm] = useState<{ repositoryUrl: string }>({
    repositoryUrl: '',
  });

  const [loading, setLoading] = useState(false);

  return (
    <Dialog style={{ padding: '2rem' }} onClose={toggleModal} aria-labelledby="search-modal-title" maxWidth="md" open={!!open}>
      {loading && <Progress />}
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.modal}>
        <Grid style={{ backgroundImage: 'none', boxShadow: 'none' }} container component={Paper} paddingX={2}>
          <Grid item xs={12} marginTop={2}>
            <TextField
              label={<CustomFormLabel text="Repository URL" />}
              placeholder="Repository URL"
              margin="normal"
              variant="outlined"
              fullWidth
              value={form.repositoryUrl}
              onChange={e => setForm({ repositoryUrl: e.target.value })}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: '0 2rem 2rem 2rem' }}>
        <Button variant="contained" onClick={toggleModal} color="secondary" disabled={loading}>
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setLoading(true);
            setForm({ repositoryUrl: '' });
            onSubmit(form).then(() => {
              setLoading(false);
              toggleModal();
            });
          }}
          disabled={_.isEmpty(form.repositoryUrl) || loading}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
