import makeStyles from '@mui/styles/makeStyles';
import { BackstageTheme } from '@backstage/theme';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import React, { Children, ReactNode } from 'react';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '280px auto 3fr',
    gridTemplateRows: '1fr',
  },
  child: {
    overflowY: 'auto',
    height: '100%',
    minHeight: 0,
  },
  firstChild: {
    background: useTheme<BackstageTheme>().palette.background.paper,
  },
}));

export function DryRunResultsSplitView(props: { children: ReactNode }) {
  const classes = useStyles();
  const childArray = Children.toArray(props.children);

  if (childArray.length !== 2) {
    throw new Error('must have exactly 2 children');
  }

  return (
    <div className={classes.root}>
      <div className={classNames(classes.child, classes.firstChild)}>{childArray[0]}</div>
      <Divider orientation="horizontal" />
      <div className={classes.child}>{childArray[1]}</div>
    </div>
  );
}
