import React from 'react';
import { StructuredMetadataTable } from '@backstage/core-components';
import { JsonObject } from '@backstage/types';
import { parseEntityRef } from '@backstage/catalog-model';
import { Draft07 as JSONSchema } from 'json-schema-library';
import { ParsedTemplateSchema } from '../../hooks';
import _ from 'lodash';

/**
 * The props for the {@link ReviewState} component.
 * @alpha
 */
export type ReviewStateProps = {
  schemas: ParsedTemplateSchema[];
  formState: JsonObject;
};

const RE_ABBR = /(api|sdk|npm|js)/i;
const RE_ENTITY_REF_PATERN = /^(api|artifact|component|domain|group|location|resource|system|template|user):[a-z\d-_.]{1,63}\/[a-z\d-_.]{1,63}/i;
/**
 * @description preview step customization
 * */
const customizeKeyValue = (pair: [string, any]): [string, any] => {
  let [key, value] = pair;
  if (_.isString(value) && RE_ENTITY_REF_PATERN.test(value)) {
    value = parseEntityRef(value).name;
  }
  key = key
    .split(/(?=[A-Z])/)
    .map(chunk => (RE_ABBR.test(chunk.toLocaleLowerCase('en-us')) ? chunk.toLocaleUpperCase('en-US') : chunk))
    .join(' ');

  return [key, value];
};

/**
 * The component used by the {@link Stepper} to render the review step.
 * @alpha
 */
export const ReviewState = (props: ReviewStateProps) => {
  const reviewData = Object.fromEntries(
    Object.entries(props.formState).map(([key, value]) => {
      for (const step of props.schemas) {
        const parsedSchema = new JSONSchema(step.mergedSchema);
        const definitionInSchema = parsedSchema.getSchema(`#/${key}`, props.formState);
        if (definitionInSchema) {
          const backstageReviewOptions = definitionInSchema['ui:backstage']?.review;

          if (backstageReviewOptions) {
            if (backstageReviewOptions.mask) {
              return [key, backstageReviewOptions.mask];
            }
            if (backstageReviewOptions.show === false) {
              return [];
            }
          }

          if (definitionInSchema['ui:widget'] === 'password') {
            return [key, '******'];
          }
        }
      }
      return customizeKeyValue([key as string, value]);
    }),
  );
  return <StructuredMetadataTable metadata={reviewData} />;
};
