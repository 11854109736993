import React from 'react';
import { AsyncApiDefinitionWidget } from '../AsyncApiDefinitionWidget';
import { GraphQlDefinitionWidget } from '../GraphQlDefinitionWidget';
import { OpenApiDefinitionWidget } from '../OpenApiDefinitionWidget';
import { GrpcApiDefinitionWidget } from '../GrpcApiDefinitionWidget';
import { TrpcApiDefinitionWidget } from '../TrpcDefinitionWidget';

/** @public */
export type ApiDefinitionWidget = {
  type: string;
  title: string;
  component: (definition: string) => React.ReactElement;
  rawLanguage?: string;
};

/** @public */
export function defaultDefinitionWidgets(): ApiDefinitionWidget[] {
  return [
    {
      type: 'openapi',
      title: 'OpenAPI',
      rawLanguage: 'yaml',
      component: definition => <OpenApiDefinitionWidget definition={definition} />,
    },
    {
      type: 'asyncapi',
      title: 'AsyncAPI',
      rawLanguage: 'yaml',
      component: definition => <AsyncApiDefinitionWidget definition={definition} />,
    },
    {
      type: 'graphql',
      title: 'GraphQL',
      rawLanguage: 'graphql',
      component: definition => <GraphQlDefinitionWidget definition={definition} />,
    },
    {
      type: 'grpc',
      title: 'gRPC',
      component: definition => <GrpcApiDefinitionWidget definition={definition} />,
    },
    {
      type: 'trpc',
      title: 'tRPC',
      component: definition => <TrpcApiDefinitionWidget definition={definition} />,
    },
  ];
}
