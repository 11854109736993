import { FieldValidation } from '@rjsf/core';
import { ApiHolder } from '@backstage/core-plugin-api';
import { scmIntegrationsApiRef } from '@backstage/integration-react';

/**
 * The validation function for the `repoUrl` that is returned from the
 * field extension. Ensures that you have all the required fields filled for
 * the different providers that exist.
 *
 * @public
 */
export const repoPickerValidation = (value: string, validation: FieldValidation, context: { apiHolder: ApiHolder }) => {
  try {
    const { host, searchParams } = new URL(`https://${value}`);

    const integrationApi = context.apiHolder.get(scmIntegrationsApiRef);

    const repoNameRegexp = new RegExp('^([a-zA-Z][a-zA-Z0-9]*)(-[a-zA-Z0-9]+)*(_[a-zA-Z0-9]+)*$');

    if (!host) {
      validation.addError('Incomplete repository location provided, host not provided');
    } else {
      if (integrationApi?.byHost(host)?.type === 'bitbucket') {
        // workspace is only applicable for bitbucket cloud
        if (host === 'bitbucket.org' && !searchParams.get('workspace')) {
          validation.addError('Incomplete repository location provided, workspace not provided');
        }

        if (!searchParams.get('project')) {
          validation.addError('Incomplete repository location provided, project not provided');
        }
      }
      // For anything other than bitbucket and gerrit
      else if (integrationApi?.byHost(host)?.type !== 'gerrit') {
        if (!searchParams.get('owner')) {
          validation.addError('Incomplete repository location provided, owner not provided');
        }
      }

      // Do this for all hosts
      if (!searchParams.get('repo')) {
        validation.addError('Incomplete repository location provided, repo not provided');
      }

      if (searchParams.get('repo')) {
        const repoName = searchParams.get('repo') ?? '';

        if (!repoNameRegexp.test(repoName)) {
          validation.addError('Invalid repository name provided');
        }
      }
    }
  } catch {
    validation.addError('Unable to parse the Repository URL');
  }
};
