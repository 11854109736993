import Box from '@mui/material/Box';
import React, { useEffect, useRef } from 'react';
import { DismissableBanner } from '@backstage/core-components';

type TaskErrorsProps = {
  error?: Error;
};

export const TaskErrors = ({ error }: TaskErrorsProps) => {
  const id = useRef('');

  useEffect(() => {
    id.current = String(Math.random());
  }, [error]);
  return error ? (
    <Box>
      <DismissableBanner id={id.current} variant="warning" message={error.message} />
    </Box>
  ) : null;
};
