import React from 'react';
import { InfoCard } from '@backstage/core-components';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Switch from '@mui/material/Switch';
import useLocalStorage from 'react-use/lib/useLocalStorage';

export function AdvancedSettings() {
  const [value, setValue] = useLocalStorage<'on' | 'off'>('advanced-option', 'off');

  const toggleValue = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setValue(ev.currentTarget.checked ? 'on' : 'off');
  };

  return (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={12} md={6}>
        <InfoCard title="Advanced settings" variant="gridItem">
          <List>
            <ListItem>
              <ListItemText primary="Advanced user option" secondary="An extra settings tab to further customize the experience" />
              <ListItemSecondaryAction>
                <Switch color="primary" value={value} onChange={toggleValue} name="advanced" />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </InfoCard>
      </Grid>
    </Grid>
  );
}
