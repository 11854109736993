import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import { GetExploreToolsRequest, GetExploreToolsResponse } from '@backstage/plugin-explore-common';
import { ExploreToolsConfig } from '@backstage/plugin-explore-react';
import { ExploreApi } from './ExploreApi';

/**
 * Default implementation of the {@link ExploreApi}.
 *
 * @public
 */
export class ExploreClient implements ExploreApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;
  // NOTE: This will be removed in the future as it is replaced by the ExploreApi.getTools method
  private readonly exploreToolsConfig: ExploreToolsConfig | undefined;

  /**
   * @remarks
   *
   * The `exploreToolsConfig` is for backwards compatibility with the `exploreToolsConfigRef`•
   * and will be removed in the future.
   */
  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi; exploreToolsConfig?: ExploreToolsConfig }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
    this.exploreToolsConfig = options.exploreToolsConfig;
  }

  async getTools(request: GetExploreToolsRequest = {}): Promise<GetExploreToolsResponse> {
    // NOTE: This will be removed in the future as it is replaced by the ExploreApi.getTools method
    if (this.exploreToolsConfig) {
      const tools = await this.exploreToolsConfig.getTools();
      if (tools) {
        return { tools };
      }
    }

    const { fetch } = this.fetchApi;

    const filter = request.filter ?? {};
    const baseUrl = await this.discoveryApi.getBaseUrl('explore');

    const tags = filter?.tags?.map(t => `tag=${encodeURIComponent(t)}`) ?? [];
    const lifecycles = filter?.lifecycle?.map(l => `lifecycle=${encodeURIComponent(l)}`) ?? [];
    const query = [...tags, ...lifecycles].join('&');

    const response = await fetch(`${baseUrl}/tools${query ? `?${query}` : ''}`);

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json() as Promise<GetExploreToolsResponse>;
  }
}
