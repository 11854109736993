import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import type { LayoutOptions } from '@backstage/plugin-scaffolder-react';
import { NextFieldExtensionOptions } from '@backstage/plugin-scaffolder-react/alpha';
import { TemplateDirectoryAccess } from '../../lib/filesystem';
import { DirectoryEditorProvider } from '../../components/TemplateEditorPage/DirectoryEditorContext';
import { DryRunProvider } from '../../components/TemplateEditorPage/DryRunContext';
import { DryRunResults } from '../../components/TemplateEditorPage/DryRunResults';
import { TemplateEditorBrowser } from '../../components/TemplateEditorPage/TemplateEditorBrowser';
import { TemplateEditorForm } from './TemplateEditorForm';
import { TemplateEditorTextArea } from '../../components/TemplateEditorPage/TemplateEditorTextArea';

const useStyles = makeStyles({
  // Reset and fix sizing to make sure scrolling behaves correctly
  root: {
    gridArea: 'pageContent',

    display: 'grid',
    gridTemplateAreas: `
      "browser editor preview"
      "results results results"
    `,
    gridTemplateColumns: '1fr 3fr 2fr',
    gridTemplateRows: '1fr auto',
  },
  browser: {
    gridArea: 'browser',
    overflow: 'auto',
  },
  editor: {
    gridArea: 'editor',
    overflow: 'auto',
  },
  preview: {
    gridArea: 'preview',
    overflow: 'auto',
  },
  results: {
    gridArea: 'results',
  },
});

export const TemplateEditor = (props: {
  directory: TemplateDirectoryAccess;
  fieldExtensions?: NextFieldExtensionOptions<any, any>[];
  layouts?: LayoutOptions[];
  onClose?: () => void;
}) => {
  const classes = useStyles();

  const [errorText, setErrorText] = useState<string>();

  return (
    <DirectoryEditorProvider directory={props.directory}>
      <DryRunProvider>
        <main className={classes.root}>
          <section className={classes.browser}>
            <TemplateEditorBrowser onClose={props.onClose} />
          </section>
          <section className={classes.editor}>
            <TemplateEditorTextArea.DirectoryEditor errorText={errorText} />
          </section>
          <section className={classes.preview}>
            <TemplateEditorForm.DirectoryEditorDryRun setErrorText={setErrorText} fieldExtensions={props.fieldExtensions} layouts={props.layouts} />
          </section>
          <section className={classes.results}>
            <DryRunResults />
          </section>
        </main>
      </DryRunProvider>
    </DirectoryEditorProvider>
  );
};
