import { createComponentExtension, createApiFactory, configApiRef, discoveryApiRef, createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';
import { ArgoWorkflowApiClient, argoWorkflowsApiRef } from './api';
import { rootRouteRef } from './routes';

export const argoWorkflowPlugin = createPlugin({
  id: 'argo-workflow',
  apis: [
    createApiFactory({
      api: argoWorkflowsApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        configApi: configApiRef,
      },
      factory: ({ discoveryApi, configApi }) => new ArgoWorkflowApiClient({ discoveryApi, configApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const ArgoWorkflowPage = argoWorkflowPlugin.provide(
  createRoutableExtension({
    name: 'ArgoWorkflowPage',
    component: () => import('./components/WorkflowsPageComponent').then(m => m.WorkflowsPageComponent),
    mountPoint: rootRouteRef,
  }),
);

export const EntityArgoWorkflowsCard = argoWorkflowPlugin.provide(
  createComponentExtension({
    name: 'EntityArgoWorkflowsCard',
    component: {
      lazy: () => import('./components/WorkflowsCard').then(m => m.WorkflowsCard),
    },
  }),
);
