import React, { useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
  () => ({
    highlight: {},
  }),
  { name: 'BackstageHighlightedSearchResultText' },
);

/**
 * Props for {@link HighlightedSearchResultText}.
 *
 * @public
 */
export type HighlightedSearchResultTextProps = {
  text: string;
  preTag: string;
  postTag: string;
};

/**
 * @public
 */
export const HighlightedSearchResultText = (props: HighlightedSearchResultTextProps) => {
  const { text, preTag, postTag } = props;

  const classes = useStyles();
  const terms = useMemo(() => text.split(new RegExp(`(${preTag}.+?${postTag})`)), [postTag, preTag, text]);

  return (
    <>
      {terms.map((t, idx) =>
        t.includes(preTag) ? (
          <mark className={classes.highlight} key={idx}>
            {t.replace(new RegExp(`${preTag}|${postTag}`, 'g'), '')}
          </mark>
        ) : (
          t
        ),
      )}
    </>
  );
};
