import { CompoundEntityRef, Entity } from '@backstage/catalog-model';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import { BackstageTheme } from '@backstage/theme';
import { useTheme } from '@mui/material/styles';

import React from 'react';
import { useEntityPresentation } from '../../apis';

/**
 * The available style class keys for {@link EntityDisplayName}, under the name
 * "CatalogReactEntityDisplayName".
 *
 * @public
 */
export type CatalogReactEntityDisplayNameClassKey = 'root' | 'icon';

const useStyles = makeStyles(
  () => {
    const theme = useTheme<BackstageTheme>();
    return {
      root: {
        display: 'inline-flex',
        alignItems: 'center',
      },
      icon: {
        marginLeft: theme.spacing(0.5),
        color: theme.palette.text.secondary,
        lineHeight: 0,
      },
    };
  },
  { name: 'CatalogReactEntityDisplayName' },
);

/**
 * Props for {@link EntityDisplayName}.
 *
 * @public
 */
export type EntityDisplayNameProps = {
  entityRef: Entity | CompoundEntityRef | string;
  noIcon?: boolean;
  noTooltip?: boolean;
  defaultKind?: string;
  defaultNamespace?: string;
};

/**
 * Shows a nice representation of a reference to an entity.
 *
 * @public
 */
export const EntityDisplayName = (props: EntityDisplayNameProps): JSX.Element => {
  const { entityRef, noIcon = true, noTooltip, defaultKind, defaultNamespace } = props;

  const classes = useStyles();
  const { primaryTitle, secondaryTitle, Icon } = useEntityPresentation(entityRef, { defaultKind, defaultNamespace });

  // The innermost "body" content
  let content = <>{primaryTitle}</>;

  // Optionally an icon, and wrapper around them both
  content = (
    <Box component="span" className={classes.root}>
      {content}
      {Icon && !noIcon ? (
        <Box component="span" className={classes.icon}>
          <Icon fontSize="default" />
        </Box>
      ) : null}
    </Box>
  );

  // Optionally, a tooltip as the outermost layer
  if (secondaryTitle && !noTooltip) {
    content = (
      <Tooltip enterDelay={1500} title={secondaryTitle}>
        {content}
      </Tooltip>
    );
  }

  return content;
};
