import { Progress } from '@backstage/core-components';
import React, { Suspense } from 'react';

// The swagger-ui component and related CSS has a significant size, only load it
// if the element is actually used.
const LazyOpenApiDefinition = React.lazy(() =>
  import('./OpenApiDefinition').then(m => ({
    default: m.OpenApiDefinition,
  })),
);

/** @public */
export type OpenApiDefinitionWidgetProps = {
  definition: string;
  requestInterceptor?: (req: any) => any | Promise<any>;
};

/** @public */
export const OpenApiDefinitionWidget = (props: OpenApiDefinitionWidgetProps) => {
  return (
    <Suspense fallback={<Progress />}>
      <LazyOpenApiDefinition {...props} />
    </Suspense>
  );
};
