import { Entity } from '@backstage/catalog-model';

/** @public */
export interface EntityPredicates {
  kind?: string | string[];
  type?: string | string[];
}

function strCmp(a: unknown, b: string | undefined): boolean {
  return Boolean(a && typeof a === 'string' && a?.toLocaleLowerCase('en-US') === b?.toLocaleLowerCase('en-US'));
}

function strCmpAll(value: unknown, cmpValues: string | string[]) {
  return typeof cmpValues === 'string' ? strCmp(value, cmpValues) : cmpValues.length === 0 || cmpValues.some(cmpVal => strCmp(value, cmpVal));
}

/**
 * For use in EntitySwitch.Case. Matches if the entity is of a given kind.
 * @public
 */
export function isKind(kinds: string | string[]) {
  return isEntityWith({ kind: kinds });
}

/**
 * For use in EntitySwitch.Case. Matches if the entity is a Component of a given spec.type.
 * @public
 */
export function isComponentType(types: string | string[]) {
  return isEntityWith({ kind: 'component', type: types });
}

/**
 * For use in EntitySwitch.Case. Matches if the entity is a Resource of a given spec.type.
 * @public
 */
export function isResourceType(types: string | string[]) {
  return isEntityWith({ kind: 'resource', type: types });
}

/**
 * For use in EntitySwitch.Case. Matches if the entity is the specified kind and type (if present).
 * @public
 */
export function isEntityWith(predicate: EntityPredicates) {
  return (entity: Entity) => {
    if (predicate.kind && !strCmpAll(entity.kind, predicate.kind)) {
      return false;
    }

    if (predicate.type && !strCmpAll(entity.spec?.type, predicate.type)) {
      return false;
    }

    // there's no type check, return true
    return true;
  };
}

/**
 * For use in EntitySwitch.Case. Matches if the entity is in a given namespace.
 * @public
 */
export function isNamespace(namespaces: string | string[]) {
  return (entity: Entity) => strCmpAll(entity.metadata?.namespace, namespaces);
}
