import { BackstageTheme } from '@backstage/theme';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import { useSearch } from '../../context';

const useStyles = makeStyles(() => {
  const theme = useTheme<BackstageTheme>();
  return {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: theme.spacing(2),
      margin: theme.spacing(2, 0),
    },
  };
});

/**
 * @public
 */
export const SearchResultPager = () => {
  const { fetchNextPage, fetchPreviousPage } = useSearch();
  const classes = useStyles();

  if (!fetchNextPage && !fetchPreviousPage) {
    return <></>;
  }

  return (
    <nav aria-label="pagination navigation" className={classes.root}>
      <Button aria-label="previous page" variant="outlined" color="primary" disabled={!fetchPreviousPage} onClick={fetchPreviousPage} startIcon={<ArrowBackIosIcon />}>
        Previous
      </Button>

      <Button aria-label="next page" variant="contained" color="primary" disabled={!fetchNextPage} onClick={fetchNextPage} endIcon={<ArrowForwardIosIcon />}>
        Next
      </Button>
    </nav>
  );
};
