import { z } from 'zod';
import { makeFieldSchemaFromZod } from '../utils';

/**
 * @public
 */
export const entityQueryFilterExpressionSchema = z.record(
  z
    .string()
    .or(z.object({ exists: z.boolean().optional() }))
    .or(z.array(z.string())),
);

/**
 * @public
 */
export const EntityPickerFieldSchema = makeFieldSchemaFromZod(
  z.string(),
  z.object({
    /**
     * @deprecated Use `catalogFilter` instead.
     */
    allowedKinds: z.array(z.string()).optional().describe('DEPRECATED: Use `catalogFilter` instead. List of kinds of entities to derive options from'),
    defaultKind: z.string().optional().describe('The default entity kind. Options of this kind will not be prefixed.'),
    allowArbitraryValues: z.boolean().optional().describe('Whether to allow arbitrary user input. Defaults to true'),
    defaultNamespace: z
      .union([z.string(), z.literal(false)])
      .optional()
      .describe('The default namespace. Options with this namespace will not be prefixed.'),
    catalogFilter: z.array(entityQueryFilterExpressionSchema).or(entityQueryFilterExpressionSchema).optional().describe('List of key-value filter expression for entities'),
  }),
);

/**
 * The input props that can be specified under `ui:options` for the
 * `EntityPicker` field extension.
 *
 * @public
 */
export type EntityPickerUiOptions = typeof EntityPickerFieldSchema.uiOptionsType;

export type EntityPickerProps = typeof EntityPickerFieldSchema.type;

export const EntityPickerSchema = EntityPickerFieldSchema.schema;

export type EntityPickerFilterQuery = z.TypeOf<typeof entityQueryFilterExpressionSchema>;

export type EntityPickerFilterQueryValue = EntityPickerFilterQuery[keyof EntityPickerFilterQuery];
