import React from 'react';

import { MarkdownContent } from '@backstage/core-components';
import { makeStyles, Typography } from '@material-ui/core';
import { DescriptionFieldProps, FormContextType, RJSFSchema, StrictRJSFSchema } from '@rjsf/utils';

const useStyles = makeStyles(theme => ({
  markdownDescription: {
    fontSize: theme.typography.caption.fontSize,
    margin: 0,
    color: theme.palette.text.secondary,
    '& :first-child': {
      margin: 0,
      marginTop: '3px', // to keep the standard browser padding
    },
  },
}));

/** The `DescriptionField` is the template to use to render the description of a field
 * @alpha
 * @param props - The `DescriptionFieldProps` for this component
 */
export const DescriptionFieldTemplate = <T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>(props: DescriptionFieldProps<T, S, F>) => {
  const { id, description } = props;
  const classes = useStyles();

  if (description) {
    if (typeof description === 'string') {
      return <MarkdownContent content={description} className={classes.markdownDescription} />;
    }

    return (
      <Typography id={id} variant="subtitle2" style={{ marginTop: '5px' }}>
        {description}
      </Typography>
    );
  }

  return null;
};
