import { Progress, Select } from '@backstage/core-components';
import { BackstageTheme } from '@backstage/theme';
import { AddClusterPayload } from '@tmatic/configurator-common';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import _ from 'lodash';
import { CreateClusterData, CreateClusterModalProps } from './types';
import { validateRequestPayload } from './utils';
import { useSpecRequestedSelection } from '../../../hooks/useSpecRequestedSelection';
import { CustomFormLabel } from '../../CustomFormLabel';

const useStyles = makeStyles(() => {
  const theme = useTheme<BackstageTheme>();
  return {
    modal: {
      justifyContent: 'center',
      [theme.breakpoints.up('xs')]: {
        minWidth: '480px',
      },
      [theme.breakpoints.up('md')]: {
        minWidth: '640px',
      },
      [theme.breakpoints.up('lg')]: {
        minWidth: '800px',
      },
    },
  };
});

export const CreateClusterModal = (props: CreateClusterModalProps) => {
  const classes = useStyles();
  const { open, hidden, title = 'Add a new Cluster', toggleModal, onSubmit } = props;
  const [form, setForm] = useState<AddClusterPayload<CreateClusterData>>({
    description: '',
    owner: '',
    system: '',
    clusterData: {
      name: '',
    },
  });
  const { groups, systems } = useSpecRequestedSelection();
  const [loading, setLoading] = useState(false);

  return (
    <Dialog style={{ padding: '2rem' }} onClose={toggleModal} aria-labelledby="search-modal-title" maxWidth="md" open={!!open} hidden={hidden}>
      {loading && <Progress />}
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.modal}>
        <Grid style={{ backgroundImage: 'none', boxShadow: 'none' }} container component={Paper} paddingX={2}>
          <Grid item xs={12} marginTop={2}>
            <TextField
              label={<CustomFormLabel text="Cluster Name *" />}
              placeholder="Cluster Name"
              margin="normal"
              variant="outlined"
              fullWidth
              value={form.clusterData?.name}
              onChange={e => setForm({ ...form, clusterData: { name: e.target.value } })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={<CustomFormLabel text="Description" />}
              placeholder="Description"
              margin="normal"
              variant="outlined"
              fullWidth
              value={form.description}
              onChange={e => setForm({ ...form, description: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label={(<CustomFormLabel text="Owner *" pull="right" />) as unknown as string}
              items={groups.value || []}
              onChange={value => setForm({ ...form, owner: _.toString(value) })}
            />
          </Grid>
          <Grid item xs={12} marginBottom={2}>
            <Select
              label={(<CustomFormLabel text="System *" pull="right" />) as unknown as string}
              items={systems.value || []}
              onChange={value => setForm({ ...form, system: _.toString(value) })}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: '0 2rem 2rem 2rem' }}>
        <Button variant="contained" onClick={toggleModal} color="secondary" disabled={loading}>
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setLoading(true);
            onSubmit(form).then(() => setLoading(false));
          }}
          disabled={!!_.size(validateRequestPayload(form)) || loading}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
