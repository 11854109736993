import React from 'react';
import { createScaffolderLayout, LayoutTemplate, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import Grid from '@mui/material/Grid';

const TwoColumn: LayoutTemplate = ({ properties, description, title }) => {
  const mid = Math.ceil(properties.length / 2);
  const left = properties.slice(0, mid);
  const right = properties.slice(mid);
  return (
    <>
      <h1>{title}</h1>
      <Grid container justifyContent="flex-end">
        {left.map(prop => (
          <Grid item xs={6} key={prop.content.key}>
            {prop.content}
          </Grid>
        ))}
        {right.map(prop => (
          <Grid item xs={6} key={prop.content.key}>
            {prop.content}
          </Grid>
        ))}
      </Grid>
      {description}
    </>
  );
};

export const TwoColumnLayout = scaffolderPlugin.provide(
  createScaffolderLayout({
    name: 'TwoColumn',
    component: TwoColumn,
  }),
);
