import { Entity } from '@backstage/catalog-model';
import { CatalogApi, catalogApiRef, useEntity } from '@backstage/plugin-catalog-react';
import { Alert } from '@material-ui/lab';
import React from 'react';
import useAsync from 'react-use/lib/useAsync';
import { Box } from '@material-ui/core';
import { ResponseErrorPanel } from '@backstage/core-components';
import { useApi, ApiHolder } from '@backstage/core-plugin-api';

async function getRelationWarnings(entity: Entity, catalogApi: CatalogApi) {
  const entityRefRelations = entity.relations?.map(relation => relation.targetRef);
  if (!entityRefRelations || entityRefRelations?.length < 1 || entityRefRelations.length > 1000) {
    return [];
  }

  const relatedEntities = await catalogApi.getEntitiesByRefs({
    entityRefs: entityRefRelations,
    fields: ['kind', 'metadata.name', 'metadata.namespace'],
  });

  return entityRefRelations.filter((_, index) => relatedEntities.items[index] === undefined);
}

/**
 * Returns true if the given entity has relations to other entities, which
 * don't exist in the catalog
 *
 * @public
 */
export async function hasRelationWarnings(entity: Entity, context: { apis: ApiHolder }) {
  const catalogApi = context.apis.get(catalogApiRef);
  if (!catalogApi) {
    throw new Error(`No implementation available for ${catalogApiRef}`);
  }

  const relatedEntitiesMissing = await getRelationWarnings(entity, catalogApi);
  return relatedEntitiesMissing.length > 0;
}

/**
 * Displays a warning alert if the entity has relations to other entities, which
 * don't exist in the catalog
 *
 * @public
 */
export function EntityRelationWarning() {
  const { entity } = useEntity();
  const catalogApi = useApi(catalogApiRef);
  const { loading, error, value } = useAsync(async () => {
    return getRelationWarnings(entity, catalogApi);
  }, [entity, catalogApi]);

  if (error) {
    return (
      <Box mb={1}>
        <ResponseErrorPanel error={error} />
      </Box>
    );
  }

  if (loading || !value || value.length === 0) {
    return null;
  }

  return (
    <>
      <Alert severity="warning">
        This entity has relations to other entities, which can't be found in the catalog. <br />
        Entities not found are: {value.join(', ')}
      </Alert>
    </>
  );
}
