import { SearchQuery, SearchResultSet } from '@backstage/plugin-search-common';
import { createApiRef } from '@backstage/core-plugin-api';

/**
 * @public
 */
export const searchApiRef = createApiRef<SearchApi>({
  id: 'plugin.search.queryservice',
});

/**
 * @public
 */
export interface SearchApi {
  query(query: SearchQuery): Promise<SearchResultSet>;
}

/**
 * @public
 *
 * Search Api Mock that can be used in tests and storybooks
 */
export class MockSearchApi implements SearchApi {
  constructor(public mockedResults?: SearchResultSet) {}

  query(): Promise<SearchResultSet> {
    return Promise.resolve(this.mockedResults || { results: [] });
  }
}
