import { TemplateDirectoryAccess, TemplateFileAccess } from './types';

type WritableFileHandle = FileSystemFileHandle & {
  createWritable(): Promise<{
    write(data: string | Blob | BufferSource): Promise<void>;
    close(): Promise<void>;
  }>;
};

// A nicer type than the one from the TS lib
interface IterableDirectoryHandle extends FileSystemDirectoryHandle {
  values(): AsyncIterable<({ kind: 'file' } & WritableFileHandle) | ({ kind: 'directory' } & IterableDirectoryHandle)>;
}

const showDirectoryPicker = (window as any).showDirectoryPicker as (() => Promise<IterableDirectoryHandle>) | undefined;

class WebFileAccess implements TemplateFileAccess {
  constructor(readonly path: string, private readonly handle: WritableFileHandle) {}

  file(): Promise<File> {
    return this.handle.getFile();
  }

  async save(data: string | Blob | BufferSource): Promise<void> {
    const writable = await this.handle.createWritable();
    await writable.write(data);
    await writable.close();
  }
}

class WebDirectoryAccess implements TemplateDirectoryAccess {
  constructor(private readonly handle: IterableDirectoryHandle) {}

  async listFiles(): Promise<TemplateFileAccess[]> {
    const content = [];
    for await (const entry of this.listDirectoryContents(this.handle)) {
      content.push(entry);
    }
    return content;
  }

  private async *listDirectoryContents(dirHandle: IterableDirectoryHandle, basePath: string[] = []): AsyncIterable<TemplateFileAccess> {
    for await (const handle of dirHandle.values()) {
      if (handle.kind === 'file') {
        yield new WebFileAccess([...basePath, handle.name].join('/'), handle);
      } else if (handle.kind === 'directory') {
        // Skip git storage directory
        if (handle.name === '.git') {
          continue;
        }
        yield* this.listDirectoryContents(handle, [...basePath, handle.name]);
      }
    }
  }
}

/** @internal */
export class WebFileSystemAccess {
  static isSupported(): boolean {
    return Boolean(showDirectoryPicker);
  }

  static async requestDirectoryAccess(): Promise<TemplateDirectoryAccess> {
    if (!showDirectoryPicker) {
      throw new Error('File system access is not supported');
    }
    const handle = await showDirectoryPicker();
    return new WebDirectoryAccess(handle);
  }

  private constructor() {}
}
