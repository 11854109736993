import React, { CSSProperties, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { formInputStyles } from './__styles';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

/**
 * Displays an example.
 *
 * @remarks
 *
 * Longer descriptions should be put after the `@remarks` tag. That way the initial summary
 * will show up in the API docs overview section, while the longer description will only be
 * displayed on the page for the specific API.
 *
 * @public
 */
export const CustomFileInput = (props: FieldExtensionComponentProps<any, any>) => {
  const {
    onChange,
    required,
    uiSchema: { 'ui:options': options },
  } = props;
  const [fileName, setFileName] = useState('');

  return (
    <FormControl variant="standard" fullWidth style={{ marginTop: '16px' }}>
      <input
        id="file-input"
        onChange={event => {
          const file = event.target?.files?.[0];
          if (file) {
            setFileName(file.name);
            const reader = new FileReader();
            reader.onload = () => {
              onChange({
                name: file.name,
                size: file.size,
                content: `base64,${btoa(new Uint8Array(reader.result as ArrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''))}`,
              });
            };
            reader.readAsArrayBuffer(file);
          }
        }}
        required={required}
        style={formInputStyles.input}
        type="file"
        {...options}
      />
      <label htmlFor="file-input" style={formInputStyles.button as CSSProperties}>
        {fileName ? `Chosen: ${fileName}` : 'Choose File'}
      </label>
    </FormControl>
  );
};
