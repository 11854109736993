import { Components } from '@mui/material/styles';
import colors from '../colors';

export const Tab: Pick<Components, 'MuiTabs' | 'MuiTab'> = {
  MuiTabs: {
    styleOverrides: {
      root: ({ theme }: any) => ({
        backgroundColor: `${theme.palette.mode === 'dark' ? colors.backgrounds.dark.main : colors.backgrounds.light.main} !important`,
      }),
    },
  },
  MuiTab: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: ({ theme }: any) => ({
        color: `${theme.palette.mode === 'dark' ? colors.backgrounds.light.main : colors.backgrounds.dark.main} !important`,
        padding: '24px 24px',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '1.66',
        textTransform: 'uppercase',
        borderRadius: 0,
        '&:hover': {
          backgroundColor: `${theme.palette.mode === 'dark' ? colors.backgrounds.dark.dark : colors.backgrounds.light.dark} !important`,
        },
        [theme.breakpoints.up('md')]: {
          minWidth: 120,
          fontSize: theme.typography.pxToRem(14),
          fontWeight: 500,
        },
      }),
    },
  },
};
