import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CSS from 'csstype';
import React from 'react';

type Props = CSS.Properties & {
  shorthand?: boolean;
  alpha?: boolean;
};

export type LifecycleClassKey = 'alpha' | 'beta';

const useStyles = makeStyles(
  theme => ({
    alpha: {
      color: theme.palette.common.white,
      fontFamily: 'serif',
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
    beta: {
      color: '#4d65cc',
      fontFamily: 'serif',
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
  }),
  { name: 'BackstageLifecycle' },
);

export function Lifecycle(props: Props) {
  const classes = useStyles(props);
  const { shorthand, alpha } = props;
  return shorthand ? (
    <Typography component="span" className={classes[alpha ? 'alpha' : 'beta']} style={{ fontSize: '120%' }}>
      {alpha ? <>&alpha;</> : <>&beta;</>}
    </Typography>
  ) : (
    <Typography component="span" className={classes[alpha ? 'alpha' : 'beta']}>
      {alpha ? 'Alpha' : 'Beta'}
    </Typography>
  );
}
