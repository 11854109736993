import React, { createContext } from 'react';

/**
 * Type of `MobileSidebarContext`
 *
 * @internal
 */
export type MobileSidebarContextType = {
  selectedMenuItemIndex: number;
  setSelectedMenuItemIndex: React.Dispatch<React.SetStateAction<number>>;
};

/**
 * Context on which `SidebarGroup` is currently selected
 *
 * @internal
 */
export const MobileSidebarContext = createContext<MobileSidebarContextType>({
  selectedMenuItemIndex: -1,
  setSelectedMenuItemIndex: () => {},
});
