import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme, WithStyles } from '@material-ui/core/styles';
import React from 'react';

/** @public */
export type ItemCardGridClassKey = 'root';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(22em, 1fr))',
      gridAutoRows: '1fr',
      gridGap: theme.spacing(2),
    },
  });

const useStyles = makeStyles(styles, { name: 'BackstageItemCardGrid' });

/** @public */
export type ItemCardGridProps = Partial<WithStyles<typeof styles>> & {
  /**
   * The Card items of the grid.
   */
  children?: React.ReactNode;
};

/**
 * A default grid to use when arranging "item cards" - cards that let users
 * select among several options.
 *
 * @remarks
 * The immediate children are expected to be Material UI Card components.
 *
 * Styles for the grid can be overridden using the `classes` prop, e.g.:
 *
 * `<ItemCardGrid title="Hello" classes={{ root: myClassName }} />`
 *
 * This can be useful for e.g. overriding gridTemplateColumns to adapt the
 * minimum size of the cells to fit the content better.
 *
 * @public
 */
export function ItemCardGrid(props: ItemCardGridProps) {
  const { children, ...otherProps } = props;
  const classes = useStyles(otherProps);
  return (
    <Box className={classes.root} {...otherProps}>
      {children}
    </Box>
  );
}
