import React from 'react';
import missingAnnotation from './assets/missingAnnotation.svg';
import noInformation from './assets/noInformation.svg';
import createComponent from './assets/createComponent.svg';
import noBuild from './assets/noBuild.svg';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  missing: 'field' | 'info' | 'content' | 'data';
};

/** @public */
export type EmptyStateImageClassKey = 'generalImg';

const useStyles = makeStyles(
  {
    generalImg: {
      width: '95%',
      zIndex: 2,
      position: 'relative',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, 15%)',
    },
  },
  { name: 'BackstageEmptyStateImage' },
);

/** @public */
export const EmptyStateImage = ({ missing }: Props) => {
  const classes = useStyles();
  switch (missing) {
    case 'field':
      return <img src={missingAnnotation} className={classes.generalImg} alt="annotation is missing" />;
    case 'info':
      return <img src={noInformation} alt="no Information" className={classes.generalImg} />;
    case 'content':
      return <img src={createComponent} alt="create Component" className={classes.generalImg} />;
    case 'data':
      return <img src={noBuild} alt="no Build" className={classes.generalImg} />;
    default:
      return null;
  }
};
