import { Entity } from '@backstage/catalog-model';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import { assertError } from '@backstage/errors';

interface DeleteEntityDialogProps {
  open: boolean;
  onClose: () => any;
  onConfirm: () => any;
  entity: Entity;
}

export function DeleteEntityDialog(props: DeleteEntityDialogProps) {
  const { open, onClose, onConfirm, entity } = props;
  const [busy, setBusy] = useState(false);
  const catalogApi = useApi(catalogApiRef);
  const alertApi = useApi(alertApiRef);

  const onDelete = async () => {
    setBusy(true);
    try {
      const uid = entity.metadata.uid;
      await catalogApi.removeEntityByUid(uid!);
      onConfirm();
    } catch (err) {
      assertError(err);
      alertApi.post({ message: err.message });
    } finally {
      setBusy(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="responsive-dialog-title">Are you sure you want to delete this entity?</DialogTitle>
      <DialogActions>
        <Button variant="contained" color="secondary" disabled={busy} onClick={onDelete}>
          Delete
        </Button>
        <Button variant="outlined" onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
