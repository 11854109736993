import { LayoutOptions } from '../../layouts';
import { type ParsedTemplateSchema } from './useTemplateSchema';

interface Options {
  layouts?: LayoutOptions[];
}

export const useTransformSchemaToProps = (step: ParsedTemplateSchema, options: Options = {}): ParsedTemplateSchema => {
  const { layouts = [] } = options;
  const objectFieldTemplate = step?.uiSchema['ui:ObjectFieldTemplate'] as string | undefined;

  if (typeof objectFieldTemplate !== 'string') {
    return step;
  }

  const Layout = layouts.find(layout => layout.name === objectFieldTemplate)?.component;

  if (!Layout) {
    return step;
  }

  return {
    ...step,
    uiSchema: {
      ...step.uiSchema,
      ['ui:ObjectFieldTemplate']: Layout,
    },
  };
};
