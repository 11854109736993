import { z } from 'zod';
import { makeFieldSchemaFromZod } from '../utils';

/**
 * @public
 */
export const OwnedEntityPickerFieldSchema = makeFieldSchemaFromZod(
  z.string(),
  z.object({
    allowedKinds: z.array(z.string()).optional().describe('List of kinds of entities to derive options from'),
    defaultKind: z.string().optional().describe('The default entity kind. Options of this kind will not be prefixed.'),
    allowArbitraryValues: z.boolean().optional().describe('Whether to allow arbitrary user input. Defaults to true'),
    defaultNamespace: z
      .union([z.string(), z.literal(false)])
      .optional()
      .describe('The default namespace. Options with this namespace will not be prefixed.'),
  }),
);

/**
 * The input props that can be specified under `ui:options` for the
 * `OwnedEntityPicker` field extension.
 *
 * @public
 */
export type OwnedEntityPickerUiOptions = typeof OwnedEntityPickerFieldSchema.uiOptionsType;

export type OwnedEntityPickerProps = typeof OwnedEntityPickerFieldSchema.type;

export const OwnedEntityPickerSchema = OwnedEntityPickerFieldSchema.schema;
