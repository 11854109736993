import { QueryEntitiesInitialRequest } from '@backstage/catalog-client';
import { useApi } from '@backstage/core-plugin-api';
import { compact, isEqual } from 'lodash';
import { useMemo, useRef } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { catalogApiRef } from '../../api';
import { useEntityList } from '../../hooks';
import { reduceCatalogFilters } from '../../utils';

export function useAllEntitiesCount() {
  const catalogApi = useApi(catalogApiRef);
  const { filters } = useEntityList();

  const prevRequest = useRef<QueryEntitiesInitialRequest>();
  const request = useMemo(() => {
    const { user, ...allFilters } = filters;
    const compacted = compact(Object.values(allFilters));
    const filter = reduceCatalogFilters(compacted);
    const newRequest: QueryEntitiesInitialRequest = {
      filter,
      limit: 0,
    };

    if (Object.keys(filter).length === 0) {
      prevRequest.current = undefined;
      return prevRequest.current;
    }

    if (isEqual(newRequest, prevRequest.current)) {
      return prevRequest.current;
    }
    prevRequest.current = newRequest;
    return newRequest;
  }, [filters]);

  const { value: count, loading } = useAsync(async () => {
    if (request === undefined) {
      return 0;
    }
    const { totalItems } = await catalogApi.queryEntities(request);

    return totalItems;
  }, [request]);

  return { count, loading };
}
