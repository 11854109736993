import React, { Fragment, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { BackstageTheme } from '@backstage/theme';
import { ActionExample, scaffolderApiRef } from '@backstage/plugin-scaffolder-react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import { JSONSchema7, JSONSchema7Definition } from 'json-schema';
import classNames from 'classnames';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { useApi } from '@backstage/core-plugin-api';
import { CodeSnippet, Content, ErrorPage, Header, MarkdownContent, Page, Progress } from '@backstage/core-components';
import Chip from '@mui/material/Chip';

const useStyles = makeStyles(() => {
  const theme = useTheme<BackstageTheme>();
  return {
    code: {
      fontFamily: 'Menlo, monospace',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[300],
      display: 'inline-block',
      borderRadius: 5,
      border: `1px solid ${theme.palette.grey[500]}`,
      position: 'relative',
    },
    codeRequired: {
      '&::after': {
        position: 'absolute',
        content: '"*"',
        top: 0,
        right: theme.spacing(0.5),
        fontWeight: 'bolder',
        color: theme.palette.error.light,
      },
    },
  };
});

const ExamplesTable = (props: { examples: ActionExample[] }) => {
  return (
    <Grid container>
      {props.examples.map((example, index) => {
        return (
          <Fragment key={`example-${index}`}>
            <Grid item lg={3}>
              <Box padding={4}>
                <Typography>{example.description}</Typography>
              </Box>
            </Grid>
            <Grid item lg={9}>
              <Box padding={1}>
                <CodeSnippet text={example.example} showLineNumbers showCopyCodeButton language="yaml" />
              </Box>
            </Grid>
          </Fragment>
        );
      })}
    </Grid>
  );
};

export const ActionsPage = () => {
  const api = useApi(scaffolderApiRef);
  const classes = useStyles();
  const { loading, value, error } = useAsync(async () => {
    return api.listActions();
  });
  const [isExpanded, setIsExpanded] = useState<{ [key: string]: boolean }>({});

  if (loading) {
    return <Progress />;
  }

  if (error) {
    return <ErrorPage statusMessage="Failed to load installed actions" status="500" />;
  }

  const renderTable = (rows?: JSX.Element[]) => {
    if (!rows || rows.length < 1) {
      return <Typography>No schema defined</Typography>;
    }
    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
    );
  };

  const getTypes = (properties: JSONSchema7) => {
    if (!properties.type) {
      return ['unknown'];
    }

    if (properties.type !== 'array') {
      return [properties.type].flat();
    }

    return [`${properties.type}(${(properties.items as JSONSchema7 | undefined)?.type ?? 'unknown'})`];
  };

  const formatRows = (parentId: string, input?: JSONSchema7) => {
    const properties = input?.properties;
    if (!properties) {
      return undefined;
    }

    return Object.entries(properties).map(entry => {
      const [key] = entry;
      const id = `${parentId}.${key}`;
      const props = entry[1] as unknown as JSONSchema7;
      const codeClassname = classNames(classes.code, {
        [classes.codeRequired]: input.required?.includes(key),
      });
      const types = getTypes(props);

      return (
        <React.Fragment key={id}>
          <TableRow key={id}>
            <TableCell>
              <div className={codeClassname}>{key}</div>
            </TableCell>
            <TableCell>{props.title}</TableCell>
            <TableCell>{props.description}</TableCell>
            <TableCell>
              {types.map(type =>
                type.includes('object') ? (
                  <Chip
                    label={type}
                    key={type}
                    icon={isExpanded[id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    variant="outlined"
                    onClick={() =>
                      setIsExpanded(prevState => {
                        const state = { ...prevState };
                        state[id] = !prevState[id];
                        return state;
                      })
                    }
                  />
                ) : (
                  <Chip label={type} key={type} variant="outlined" />
                ),
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={isExpanded[id]} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Typography variant="h6" component="div">
                    {key}
                  </Typography>
                  {renderTable(
                    formatRows(
                      id,
                      props.type === 'array'
                        ? ({
                            properties: (props.items as JSONSchema7 | undefined)?.properties ?? {},
                          } as unknown as JSONSchema7 | undefined)
                        : props,
                    ),
                  )}
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      );
    });
  };

  const renderTables = (name: string, id: string, input?: JSONSchema7Definition[]) => {
    if (!input) {
      return undefined;
    }

    return (
      <>
        <Typography variant="h6" component="h4">
          {name}
        </Typography>
        {input.map((i, index) => (
          <div key={index}>{renderTable(formatRows(`${id}.${index}`, i as unknown as JSONSchema7))}</div>
        ))}
      </>
    );
  };

  const items = value?.map(action => {
    if (action.id.startsWith('legacy:')) {
      return undefined;
    }

    const oneOf = renderTables('oneOf', `${action.id}.input`, action.schema?.input?.oneOf);
    return (
      <Box pb={4} key={action.id}>
        <Typography variant="h4" component="h2" className={classes.code}>
          {action.id}
        </Typography>
        {action.description && <MarkdownContent content={action.description} />}
        {action.schema?.input && (
          <Box pb={2}>
            <Typography variant="h5" component="h3">
              Input
            </Typography>
            {renderTable(formatRows(`${action.id}.input`, action?.schema?.input))}
            {oneOf}
          </Box>
        )}
        {action.schema?.output && (
          <Box pb={2}>
            <Typography variant="h5" component="h3">
              Output
            </Typography>
            {renderTable(formatRows(`${action.id}.output`, action?.schema?.output))}
          </Box>
        )}
        {action.examples && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5" component="h3">
                Examples
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box pb={2}>
                <ExamplesTable examples={action.examples} />
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
    );
  });

  return (
    <Page themeId="home">
      <Header pageTitleOverride="Create a New Component" title="Installed actions" subtitle="This is the collection of all installed actions" />
      <Content>{items}</Content>
    </Page>
  );
};
