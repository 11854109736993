import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import FilterListIcon from '@mui/icons-material/FilterList';

/** @public */
export const Filters = (props: {
  children: React.ReactNode;
  options?: {
    drawerBreakpoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | number;
    drawerAnchor?: 'left' | 'right' | 'top' | 'bottom';
  };
}) => {
  const isScreenSmallerThanBreakpoint = useMediaQuery<Theme>(theme => theme.breakpoints.down(props.options?.drawerBreakpoint ?? 'md'));
  const theme = useTheme<Theme>();
  const [filterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(false);

  return isScreenSmallerThanBreakpoint ? (
    <>
      <Button style={{ marginTop: theme.spacing(1), marginLeft: theme.spacing(1) }} onClick={() => setFilterDrawerOpen(true)} startIcon={<FilterListIcon />}>
        Filters
      </Button>
      <Drawer open={filterDrawerOpen} onClose={() => setFilterDrawerOpen(false)} anchor={props.options?.drawerAnchor ?? 'left'} disableAutoFocus keepMounted variant="temporary">
        <Box m={2}>
          <Typography variant="h6" component="h2" style={{ marginBottom: theme.spacing(1) }}>
            Filters
          </Typography>
          {props.children}
        </Box>
      </Drawer>
    </>
  ) : (
    <Grid item lg={2}>
      {props.children}
    </Grid>
  );
};

/** @public */
export const Content = (props: { children: React.ReactNode }) => {
  return (
    <Grid item xs={12} lg={10}>
      {props.children}
    </Grid>
  );
};

/** @public */
export const CatalogFilterLayout = (props: { children: React.ReactNode }) => {
  return (
    <Grid container style={{ position: 'relative' }}>
      {props.children}
    </Grid>
  );
};

CatalogFilterLayout.Filters = Filters;
CatalogFilterLayout.Content = Content;
