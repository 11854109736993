import { SearchClient } from './apis';
import { searchApiRef } from '@backstage/plugin-search-react';
import { createApiFactory, createPlugin, createRouteRef, createRoutableExtension, discoveryApiRef, createComponentExtension, identityApiRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'search',
});

/**
 * @public
 */
export const searchPlugin = createPlugin({
  id: 'search',
  apis: [
    createApiFactory({
      api: searchApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) => {
        return new SearchClient({ discoveryApi, identityApi });
      },
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

/**
 * @public
 */
export const SearchPage = searchPlugin.provide(
  createRoutableExtension({
    name: 'SearchPage',
    component: () => import('./components/SearchPage').then(m => m.SearchPage),
    mountPoint: rootRouteRef,
  }),
);

/**
 * @public
 */
export const SidebarSearchModal = searchPlugin.provide(
  createComponentExtension({
    name: 'SidebarSearchModal',
    component: {
      lazy: () => import('./components/SidebarSearchModal').then(m => m.SidebarSearchModal),
    },
  }),
);

/**
 * @public
 */
export const HomePageSearchBar = searchPlugin.provide(
  createComponentExtension({
    name: 'HomePageSearchBar',
    component: {
      lazy: () => import('./components/HomePageComponent').then(m => m.HomePageSearchBar),
    },
  }),
);
