import { ScmIntegrationRegistry } from '@backstage/integration';
import parseGitUrl from 'git-url-parse';

export const getGithubIntegrationConfig = (scmIntegrationsApi: ScmIntegrationRegistry, location: string) => {
  const integration = scmIntegrationsApi.github.byUrl(location);
  if (!integration) {
    return undefined;
  }

  const { name: repo, owner } = parseGitUrl(location);
  return {
    repo,
    owner,
    githubIntegrationConfig: integration.config,
  };
};
