import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { DefaultNode } from './DefaultNode';
import { DependencyGraphTypes as Types } from './types';
import { NODE_TEST_ID } from './constants';
import dagre from 'dagre';

/** @public */
export type DependencyGraphNodeClassKey = 'node';

const useStyles = makeStyles(
  theme => ({
    node: {
      transition: `${theme.transitions.duration.shortest}ms`,
    },
  }),
  { name: 'BackstageDependencyGraphNode' },
);

export type GraphNode<T> = dagre.Node<Types.DependencyNode<T>>;

export type NodeComponentProps<T> = {
  node: GraphNode<T>;
  render?: Types.RenderNodeFunction<T>;
  setNode: dagre.graphlib.Graph['setNode'];
};

const renderDefault = (props: Types.RenderNodeProps) => <DefaultNode {...props} />;

export function Node<T>({ render = renderDefault, setNode, node }: NodeComponentProps<T>) {
  const { width, height, x = 0, y = 0 } = node;
  const nodeProps: Types.DependencyNode<T> = node;
  const classes = useStyles();
  const nodeRef = React.useRef<SVGGElement | null>(null);

  React.useLayoutEffect(() => {
    // set the node width to the actual rendered width to properly layout graph
    if (nodeRef.current) {
      let { height: renderedHeight, width: renderedWidth } = nodeRef.current.getBBox();
      renderedHeight = Math.round(renderedHeight);
      renderedWidth = Math.round(renderedWidth);

      if (renderedHeight !== height || renderedWidth !== width) {
        setNode(node.id, {
          ...node,
          height: renderedHeight,
          width: renderedWidth,
        });
      }
    }
  }, [node, width, height, setNode]);

  return (
    <g ref={nodeRef} data-testid={NODE_TEST_ID} className={classes.node} transform={`translate(${x - width / 2},${y - height / 2})`}>
      {render({ node: nodeProps })}
    </g>
  );
}
