import React, { PropsWithChildren } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import CategoryIcon from '@mui/icons-material/Category';
// import RuleIcon from '@mui/icons-material/AssignmentTurnedIn';
// import MapIcon from '@mui/icons-material/MyLocation';
// import LayersIcon from '@mui/icons-material/Layers';
// import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
// import DevOpsIcon from '@mui/icons-material/AllInclusive';
import CreateComponentIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import AppsIcon from '@material-ui/icons/Apps';
// import MoneyIcon from '@mui/icons-material/MonetizationOn';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import Box from '@mui/material/Box';
// import { GraphiQLIcon } from '@backstage/plugin-graphiql';
import { Settings as SidebarSettings, UserSettingsSignInAvatar } from '@backstage/plugin-user-settings';
import TuneIcon from '@mui/icons-material/Tune';
import { SidebarSearchModal } from '@backstage/plugin-search';
import { Shortcuts } from '@backstage/plugin-shortcuts';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  Link,
  useSidebarOpenState,
  SidebarSubmenu,
  SidebarSubmenuItem,
} from '@backstage/core-components';
import { SearchModal } from '../search/SearchModal';
// import Score from '@mui/icons-material/Score';
import { Disclaimer } from '../../Disclaimer';
import { useTenantSettings } from '@tmatic/tenant-settings';
import { useApp } from '@backstage/core-plugin-api';
import BuildIcon from '@mui/icons-material/Build';
import ApiIcon from '@mui/icons-material/Api';
import ExtensionIcon from '@mui/icons-material/Extension';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import GamepadIcon from '@mui/icons-material/Gamepad';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

const SidebarLogo = () => {
  const { isOpen } = useSidebarOpenState();

  return (
    <Box
      sx={{
        width: '100%',
        height: 3 * sidebarConfig.logoHeight,
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        marginBottom: '-14px',
      }}
    >
      <Link
        to="/"
        underline="none"
        aria-label="Home"
        style={{
          width: sidebarConfig.drawerWidthClosed,
          marginLeft: '24px',
        }}
      >
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </Box>
  );
};

type IconComponent = any;

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal>{({ toggleModal }) => <SearchModal toggleModal={toggleModal} />}</SidebarSearchModal>
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        <SidebarItem icon={HomeIcon as IconComponent} to="/" text="Home" />
        <SidebarItem icon={ApiIcon as IconComponent} to="apis" text="APIs" />
        <SidebarItem icon={ExtensionIcon as IconComponent} to="components" text="Components" />
        <SidebarItem icon={AllInboxIcon as IconComponent} to="artifacts" text="Artifacts" />
        <SidebarItem icon={GamepadIcon as IconComponent} to="control-planes" text="Control Planes" />
        <SidebarItem icon={AccountTreeIcon as IconComponent} to="k8s-clusters" text="Clusters" />
        <SidebarItem icon={AppsIcon as IconComponent} to="applications" text="Applications" />
        {/* Global nav, not org-specific */}
        <SidebarItem icon={CategoryIcon as IconComponent} to="catalog" text="Other">
          <SidebarSubmenu title="Other">
            <SidebarSubmenuItem title="Domains" to="catalog?filters[kind]=Domain" icon={useApp().getSystemIcon('kind:domain') as IconComponent} />
            <SidebarSubmenuItem title="Systems" to="catalog?filters[kind]=System" icon={useApp().getSystemIcon('kind:system') as IconComponent} />
            <SidebarSubmenuItem title="Components" to="catalog?filters[kind]=Component" icon={ExtensionIcon as IconComponent} />
            {/* <SidebarSubmenuItem title="APIs" to="catalog?filters[kind]=api" icon={useApp().getSystemIcon('kind:api')} / >*/}
            {/* <SidebarItem icon={useApp().getSystemIcon('kind:api')!} to="catalog?filters[kind]=API" text="APIs" />*/}
            {/* <SidebarItem icon={useApp().getSystemIcon('kind:resource')!} to="catalog?filters[kind]=Resource&filters[type]=k8s-cluster" text="Clusters" />*/}
            <SidebarDivider />
            <SidebarSubmenuItem title="Resources" to="catalog?filters[kind]=Resource" icon={useApp().getSystemIcon('kind:resource') as IconComponent} />
            <SidebarDivider />
            <SidebarSubmenuItem title="Groups" to="catalog?filters[kind]=Group" icon={useApp().getSystemIcon('kind:group') as IconComponent} />
            <SidebarSubmenuItem title="Users" to="catalog?filters[kind]=User" icon={useApp().getSystemIcon('kind:user') as IconComponent} />
          </SidebarSubmenu>
        </SidebarItem>
        {/* <MyGroupsSidebarItem singularTitle="My Squad" pluralTitle="My Squads" icon={useApp().getSystemIcon('group')!} />*/}
        <SidebarItem icon={CreateComponentIcon as IconComponent} to="create" text="Create..." />
        {/* End global nav */}
        <SidebarDivider />
        <Shortcuts allowExternalLinks />
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup label="Settings" icon={<UserSettingsSignInAvatar />} to="/settings">
        <SidebarSettings />
        {useTenantSettings().configuratorVisible && <SidebarItem icon={TuneIcon as IconComponent} to="configurator" text="Configurator" />}
        {localStorage.getItem('devToolsMenuEnabled') !== 'true' ? null : <SidebarItem icon={BuildIcon as IconComponent} to="devtools" text="DevTools" />}
      </SidebarGroup>
    </Sidebar>
    <Disclaimer />
    {children}
  </SidebarPage>
);
