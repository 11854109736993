import React, { useState } from 'react';
import { Content, Header, Page } from '@backstage/core-components';
import { TemplateDirectoryAccess, WebFileSystemAccess } from '../../lib/filesystem';
import { CustomFieldExplorer } from './CustomFieldExplorer';
import { TemplateEditor } from './TemplateEditor';
import { TemplateFormPreviewer } from './TemplateFormPreviewer';
import { type LayoutOptions } from '@backstage/plugin-scaffolder-react';
import { NextFieldExtensionOptions } from '@backstage/plugin-scaffolder-react/alpha';
import { TemplateEditorIntro } from '../../components/TemplateEditorPage/TemplateEditorIntro';

type Selection =
  | {
      type: 'local';
      directory: TemplateDirectoryAccess;
    }
  | {
      type: 'form';
    }
  | {
      type: 'field-explorer';
    };

interface TemplateEditorPageProps {
  defaultPreviewTemplate?: string;
  customFieldExtensions?: NextFieldExtensionOptions<any, any>[];
  layouts?: LayoutOptions[];
}

export function TemplateEditorPage(props: TemplateEditorPageProps) {
  const [selection, setSelection] = useState<Selection>();

  let content: JSX.Element | null = null;
  if (selection?.type === 'local') {
    content = <TemplateEditor directory={selection.directory} fieldExtensions={props.customFieldExtensions} onClose={() => setSelection(undefined)} layouts={props.layouts} />;
  } else if (selection?.type === 'form') {
    content = (
      <TemplateFormPreviewer
        defaultPreviewTemplate={props.defaultPreviewTemplate}
        customFieldExtensions={props.customFieldExtensions}
        onClose={() => setSelection(undefined)}
        layouts={props.layouts}
      />
    );
  } else if (selection?.type === 'field-explorer') {
    content = <CustomFieldExplorer customFieldExtensions={props.customFieldExtensions} onClose={() => setSelection(undefined)} />;
  } else {
    content = (
      <Content>
        <TemplateEditorIntro
          onSelect={option => {
            if (option === 'local') {
              WebFileSystemAccess.requestDirectoryAccess()
                .then(directory => setSelection({ type: 'local', directory }))
                .catch(() => {});
            } else if (option === 'form') {
              setSelection({ type: 'form' });
            } else if (option === 'field-explorer') {
              setSelection({ type: 'field-explorer' });
            }
          }}
        />
      </Content>
    );
  }

  return (
    <Page themeId="home">
      <Header title="Template Editor" subtitle="Edit, preview, and try out templates and template forms" />
      {content}
    </Page>
  );
}
