import { BackstageTheme } from '@backstage/theme';
import { useSearch } from '@backstage/plugin-search-react';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import React, { ChangeEvent } from 'react';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import { SearchTypeAccordion, SearchTypeAccordionProps } from './SearchType.Accordion';
import { SearchTypeTabs, SearchTypeTabsProps } from './SearchType.Tabs';

const useStyles = makeStyles(() => ({
  label: {
    textTransform: 'capitalize',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: useTheme<BackstageTheme>().spacing(1),
  },
  chip: {
    margin: 2,
  },
}));

/**
 * Props for {@link SearchType}.
 *
 * @public
 */
export type SearchTypeProps = {
  className?: string;
  name: string;
  values?: string[];
  defaultValue?: string[] | string | null;
};

/**
 * @public
 */
const SearchType = (props: SearchTypeProps) => {
  const { className, defaultValue, name, values = [] } = props;
  const classes = useStyles();
  const { types, setTypes } = useSearch();

  useEffectOnce(() => {
    if (!types.length) {
      if (defaultValue && Array.isArray(defaultValue)) {
        setTypes(defaultValue);
      } else if (defaultValue) {
        setTypes([defaultValue]);
      }
    }
  });

  const handleChange = (e: ChangeEvent<{ value: unknown }>) => {
    const value = e.target.value as string[];
    setTypes(value as string[]);
  };

  return (
    <FormControl className={className} variant="filled" fullWidth data-testid="search-typefilter-next">
      <InputLabel className={classes.label} margin="dense">
        {name}
      </InputLabel>
      <Select
        multiple
        variant="outlined"
        value={types}
        onChange={handleChange}
        placeholder="All Results"
        renderValue={selected => (
          <div className={classes.chips}>
            {(selected as string[]).map(value => (
              <Chip key={value} label={value} className={classes.chip} size="small" />
            ))}
          </div>
        )}
      >
        {values.map((value: string) => (
          <MenuItem key={value} value={value}>
            <Checkbox checked={types.indexOf(value) > -1} />
            <ListItemText primary={value} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

/**
 * A control surface for the search query's "types" property, displayed as a
 * single-select collapsible accordion suitable for use in faceted search UIs.
 * @public
 */
SearchType.Accordion = (props: SearchTypeAccordionProps) => {
  return <SearchTypeAccordion {...props} />;
};

/**
 * A control surface for the search query's "types" property, displayed as a
 * tabs suitable for use in faceted search UIs.
 * @public
 */
SearchType.Tabs = (props: SearchTypeTabsProps) => {
  return <SearchTypeTabs {...props} />;
};

export { SearchType };
export type { SearchTypeAccordionProps, SearchTypeTabsProps };
