import { parseRepoPickerUrl } from './utils';
import { FromDataPatch, RepoUrlPickerState } from './types';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import { scmAuthApiRef, scmIntegrationsApiRef } from '@backstage/integration-react';
import { useTemplateSecrets } from '@backstage/plugin-scaffolder-react';
import { useAsync } from 'react-use';
import axios from 'axios';
import { RepoUrlPickerProps } from './schema';

export const useRepoUrlPickerCommonContext = (props: RepoUrlPickerProps) => {
  const { uiSchema, formData, formContext } = props;
  let parsedData = useMemo(() => parseRepoPickerUrl(formData), [formData]);
  if (formContext?.formData) {
    const patch: FromDataPatch = {};
    if (_.isString(formContext.formData.gitRepositoryName)) {
      patch.repoName = formContext.formData.gitRepositoryName;
    }
    if (_.isString(formContext.formData.gitRepositoryOwner)) {
      patch.owner = formContext.formData.gitRepositoryOwner;
    }
    if (!_.isEmpty(patch)) {
      parsedData = _.merge(parsedData, patch);
    }
  }

  const [state, setState] = useState<RepoUrlPickerState>(parsedData);
  const alertApi = useApi(alertApiRef);
  const integrationApi = useApi(scmIntegrationsApiRef);
  const scmAuthApi = useApi(scmAuthApiRef);
  const { setSecrets } = useTemplateSecrets();
  const allowedHosts = useMemo(() => uiSchema?.['ui:options']?.allowedHosts ?? [], [uiSchema]);
  const allowedOrganizations = useMemo(() => uiSchema?.['ui:options']?.allowedOrganizations ?? [], [uiSchema]);
  const allowedOwners = useMemo(() => uiSchema?.['ui:options']?.allowedOwners ?? [], [uiSchema]);
  const allowedProjects = useMemo(() => uiSchema?.['ui:options']?.allowedProjects ?? [], [uiSchema]);
  const allowedRepos = useMemo(() => uiSchema?.['ui:options']?.allowedRepos ?? [], [uiSchema]);
  const disableInputs = !!uiSchema?.['ui:options']?.disabled;

  const { owner, organization, project, repoName } = state;

  /* we deal with calling the repo setting here instead of in each components for ease */
  useEffect(() => {
    if (allowedOrganizations.length > 0 && !organization) {
      setState(prevState => ({
        ...prevState,
        organization: allowedOrganizations[0],
      }));
    }
  }, [setState, allowedOrganizations, organization]);

  useEffect(() => {
    if (allowedOwners.length > 0 && !owner) {
      setState(prevState => ({
        ...prevState,
        owner: allowedOwners[0],
      }));
    }
  }, [setState, allowedOwners, owner]);

  useEffect(() => {
    if (allowedProjects.length > 0 && !project) {
      setState(prevState => ({
        ...prevState,
        project: allowedProjects[0],
      }));
    }
  }, [setState, allowedProjects, project]);

  useEffect(() => {
    if (allowedRepos.length > 0 && !repoName) {
      setState(prevState => ({ ...prevState, repoName: allowedRepos[0] }));
    }
  }, [setState, allowedRepos, repoName]);

  const updateLocalState = useCallback(
    (newState: RepoUrlPickerState) => {
      setState(prevState => ({ ...prevState, ...newState }));
    },
    [setState],
  );

  const { value } = useAsync(async () => {
    const { requestUserCredentials } = uiSchema?.['ui:options'] ?? {};

    const workspace = state.owner ? state.owner : state.project;
    if (!requestUserCredentials) {
      return;
    }

    // previously, we were encodeURI for state.host, workspace and state.repoName separately.
    // That created an issue where GitLab workspace can be nested like groupA/subgroupB
    // when we encodeURi separately and then join, the URL will be malformed and
    // resulting in 400 request error from GitLab API
    const [encodedHost, encodedRepoName] = [state.host || '', state.repoName || ''].map(encodeURIComponent);

    // user has requested that we use the users credentials
    // so lets grab them using the scmAuthApi and pass through
    // any additional scopes from the ui:options
    const { token } = await scmAuthApi.getCredentials({
      url: `https://${encodedHost}/${workspace}/${encodedRepoName}`,
      additionalScope: {
        repoWrite: true,
        customScopes: requestUserCredentials.additionalScopes,
      },
    });

    // set the secret using the key provided in the ui:options for use
    // in the templating the manifest with ${{ secrets[secretsKey] }}
    setSecrets({ [requestUserCredentials.secretsKey]: token });
    if (token) {
      if (disableInputs) {
        alertApi.post({
          message: 'Access verified',
          severity: 'success',
          display: 'transient',
        });
      }
      // eslint-disable-next-line default-case
      switch (state.host) {
        case 'github.com': {
          const { data } = await axios.get('https://api.github.com/user/orgs', { headers: { Authorization: `Bearer ${token}` } });
          // eslint-disable-next-line consistent-return
          return _.map(data, 'login');
        }
      }
    }
    return;
  }, [state, uiSchema]);

  const hostType = (state.host && integrationApi.byHost(state.host)?.type) ?? null;

  return {
    parsedData,
    allowedHosts,
    allowedOrganizations,
    allowedOwners: value || allowedOwners,
    allowedProjects,
    allowedRepos,
    disableInputs,
    hostType,
    updateLocalState,
    state,
    setState,
  };
};
