import { NextCustomFieldValidator, NextFieldExtensionOptions, NextFieldExtensionComponentProps, NextFieldExtensionUiSchema } from './types';
import { Extension, attachComponentData } from '@backstage/core-plugin-api';
import { UIOptionsType } from '@rjsf/utils';
import { FIELD_EXTENSION_KEY } from '../../extensions/keys';
import { FieldExtensionComponent } from '@backstage/plugin-scaffolder-react';

/**
 * Method for creating field extensions that can be used in the scaffolder
 * frontend form.
 * @alpha
 */
export function createNextScaffolderFieldExtension<TReturnValue = unknown, TInputProps extends UIOptionsType = {}>(
  options: NextFieldExtensionOptions<TReturnValue, TInputProps>,
): Extension<FieldExtensionComponent<TReturnValue, TInputProps>> {
  return {
    expose() {
      const FieldExtensionDataHolder: any = () => null;

      attachComponentData(FieldExtensionDataHolder, FIELD_EXTENSION_KEY, options);

      return FieldExtensionDataHolder;
    },
  };
}

export type { NextCustomFieldValidator, NextFieldExtensionOptions, NextFieldExtensionComponentProps, NextFieldExtensionUiSchema };
