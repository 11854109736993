import { IconComponent } from '@backstage/core-plugin-api';
import { BackstageTheme } from '@backstage/theme';
import { Link } from '@backstage/core-components';
import { makeStyles } from '@material-ui/core';
import React from 'react';

interface CardLinkProps {
  icon: IconComponent;
  text: string;
  url: string;
}

const useStyles = makeStyles<BackstageTheme>(() => ({
  linkText: {
    display: 'inline-flex',
    alignItems: 'center',
  },
}));

export const CardLink = ({ icon: Icon, text, url }: CardLinkProps) => {
  const styles = useStyles();

  return (
    <div className={styles.linkText}>
      <Icon fontSize="small" />
      <Link style={{ marginLeft: '8px' }} to={url}>
        {text || url}
      </Link>
    </div>
  );
};
