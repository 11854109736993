import React from 'react';

import { BackstageTheme } from '@backstage/theme';
import { CircularProgress, makeStyles, StepIconProps } from '@material-ui/core';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye';
import classNames from 'classnames';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import ErrorOutline from '@material-ui/icons/ErrorOutline';

const useStepIconStyles = makeStyles((theme: BackstageTheme) => ({
  root: {
    color: theme.palette.text.disabled,
  },
  completed: {
    color: theme.palette.status.ok,
  },
  error: {
    color: theme.palette.status.error,
  },
}));

export const StepIcon = (props: StepIconProps & { skipped: boolean }) => {
  const classes = useStepIconStyles();
  const { active, completed, error, skipped } = props;

  const getMiddle = () => {
    if (active) {
      return <CircularProgress size="20px" />;
    }
    if (completed) {
      return <CheckCircleOutline />;
    }

    if (error) {
      return <ErrorOutline />;
    }

    if (skipped) {
      return <RemoveCircleOutline />;
    }

    return <PanoramaFishEyeIcon />;
  };

  return (
    <div
      className={classNames(classes.root, {
        [classes.completed]: completed,
        [classes.error]: error,
      })}
    >
      {getMiddle()}
    </div>
  );
};
