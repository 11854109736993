import React from 'react';
import { CustomFieldExtensionSchema, CustomFieldValidator, FieldExtensionOptions, FieldExtensionComponentProps } from './types';
import { Extension, attachComponentData } from '@backstage/core-plugin-api';
import { FIELD_EXTENSION_KEY, FIELD_EXTENSION_WRAPPER_KEY } from './keys';

/**
 * The type used to wrap up the Layout and embed the input props
 *
 * @public
 */
export type FieldExtensionComponent<_TReturnValue, _TInputProps> = () => null;

/**
 * Method for creating field extensions that can be used in the scaffolder
 * frontend form.
 * @public
 */
export function createScaffolderFieldExtension<TReturnValue = unknown, TInputProps = unknown>(
  options: FieldExtensionOptions<TReturnValue, TInputProps>,
): Extension<FieldExtensionComponent<TReturnValue, TInputProps>> {
  return {
    expose() {
      const FieldExtensionDataHolder: any = () => null;

      attachComponentData(FieldExtensionDataHolder, FIELD_EXTENSION_KEY, options);

      return FieldExtensionDataHolder;
    },
  };
}

/**
 * The Wrapping component for defining fields extensions inside
 *
 * @public
 */
export const ScaffolderFieldExtensions: React.ComponentType<React.PropsWithChildren<{}>> = (): JSX.Element | null => null;

attachComponentData(ScaffolderFieldExtensions, FIELD_EXTENSION_WRAPPER_KEY, true);

export type { CustomFieldExtensionSchema, CustomFieldValidator, FieldExtensionOptions, FieldExtensionComponentProps };
