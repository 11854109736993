import React from 'react';
import { LinearProgress, makeStyles } from '@material-ui/core';
import { BackstageTheme } from '@backstage/theme';

const useStyles = makeStyles((theme: BackstageTheme) => ({
  failed: {
    backgroundColor: theme.palette.error.main,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
}));

/**
 * The visual progress of the task event stream
 */
export const TaskBorder = (props: { isComplete: boolean; isError: boolean }) => {
  const styles = useStyles();

  if (!props.isComplete) {
    return <LinearProgress variant="indeterminate" />;
  }

  return <LinearProgress variant="determinate" classes={{ bar: props.isError ? styles.failed : styles.success }} value={100} />;
};
