import React, { useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { Select, SelectItem } from '@backstage/core-components';
import { RepoUrlPickerState } from './types';

/**
 * The underlying component that is rendered in the form for the `BitbucketRepoPicker`
 * field extension.
 *
 * @public
 * @param allowedOwners - Allowed workspaces for the Bitbucket cloud repository
 * @param allowedProjects - Allowed projects for the Bitbucket cloud repository
 *
 */
export const BitbucketRepoPicker = (props: {
  allowedOwners?: string[];
  allowedProjects?: string[];
  onChange: (state: RepoUrlPickerState) => void;
  state: RepoUrlPickerState;
  rawErrors: string[];
}) => {
  const { allowedOwners = [], allowedProjects = [], onChange, rawErrors, state } = props;
  const { host, workspace, project } = state;
  const ownerItems: SelectItem[] = allowedOwners ? allowedOwners?.map(i => ({ label: i, value: i })) : [];
  const projectItems: SelectItem[] = allowedProjects ? allowedProjects?.map(i => ({ label: i, value: i })) : [];

  useEffect(() => {
    if (host === 'bitbucket.org' && allowedOwners.length) {
      onChange({ workspace: allowedOwners[0] });
    }
  }, [allowedOwners, host, onChange]);

  return (
    <>
      {host === 'bitbucket.org' && (
        <FormControl margin="normal" required error={rawErrors?.length > 0 && !workspace}>
          {allowedOwners?.length ? (
            <Select
              native
              label="Allowed Workspaces"
              onChange={s => onChange({ workspace: String(Array.isArray(s) ? s[0] : s) })}
              disabled={allowedOwners.length === 1}
              selected={workspace}
              items={ownerItems}
            />
          ) : (
            <>
              <InputLabel htmlFor="workspaceInput">Workspace</InputLabel>
              <Input id="workspaceInput" onChange={e => onChange({ workspace: e.target.value })} value={workspace} />
            </>
          )}
          <FormHelperText>The Workspace that this repo will belong to</FormHelperText>
        </FormControl>
      )}
      <FormControl margin="normal" required error={rawErrors?.length > 0 && !project}>
        {allowedProjects?.length ? (
          <Select
            native
            label="Allowed Projects"
            onChange={s => onChange({ project: String(Array.isArray(s) ? s[0] : s) })}
            disabled={allowedProjects.length === 1}
            selected={project}
            items={projectItems}
          />
        ) : (
          <>
            <InputLabel htmlFor="projectInput">Project</InputLabel>
            <Input id="projectInput" onChange={e => onChange({ project: e.target.value })} value={project} />
          </>
        )}
        <FormHelperText>The Project that this repo will belong to</FormHelperText>
      </FormControl>
    </>
  );
};
