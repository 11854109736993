import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { BackstageTheme } from '@backstage/theme';
import { DependencyGraphTypes as Types } from './types';

/** @public */
export type DependencyGraphDefaultLabelClassKey = 'text';

const useStyles = makeStyles(
  (theme: BackstageTheme) => ({
    text: {
      fill: theme.palette.textContrast,
    },
  }),
  { name: 'BackstageDependencyGraphDefaultLabel' },
);

/** @public */
export function DefaultLabel({ edge: { label } }: Types.RenderLabelProps) {
  const classes = useStyles();
  return (
    <text className={classes.text} textAnchor="middle">
      {label}
    </text>
  );
}
