import type { FieldValidation } from '@rjsf/utils';
import { KubernetesValidatorFunctions } from '@backstage/catalog-model';
import { ApiHolder, configApiRef } from '@backstage/core-plugin-api';
import { JsonObject } from '@backstage/config';

export const argocdAppNamePickerValidation = async (value: string, validation: FieldValidation, { apiHolder, formData }: { apiHolder: ApiHolder; formData: JsonObject }) => {
  const configApi = apiHolder.get(configApiRef);
  const backendUrl = configApi?.getString('backend.baseUrl');
  const fetchUrl = `${backendUrl}/api/argocd/allArgoApps/${formData?.argocdInstanceName}/${value}`;

  const resp = await fetch(`${fetchUrl}`).then(res => {
    if (!res.ok) {
      throw new Error(`ArgoCD integration error. Check integration settings.`);
    }
    return res.json();
  });

  if (!resp?.status) {
    validation.addError('Application name is already in use. Please choose another name.');
  }

  if (!KubernetesValidatorFunctions.isValidObjectName(value)) {
    validation.addError(
      'Must start and end with an alphanumeric character, and contain only alphanumeric characters, hyphens, underscores, and periods. Maximum length is 63 characters.',
    );
  }
};
