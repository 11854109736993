import { Progress } from '@backstage/core-components';
import React, { Suspense } from 'react';

// The graphql component, graphql and related CSS has a significant size, only
// load it if the element is actually used.
const LazyGraphQlDefinition = React.lazy(() =>
  import('./GraphQlDefinition').then(m => ({
    default: m.GraphQlDefinition,
  })),
);

/** @public */
export type GraphQlDefinitionWidgetProps = {
  definition: string;
};

/** @public */
export const GraphQlDefinitionWidget = (props: GraphQlDefinitionWidgetProps) => {
  return (
    <Suspense fallback={<Progress />}>
      <LazyGraphQlDefinition {...props} />
    </Suspense>
  );
};
