import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { Select, SelectItem } from '@backstage/core-components';
import { RepoUrlPickerState } from './types';

export interface GitlabRepoPickerProps {
  allowedOwners?: string[];
  allowedRepos?: string[];
  state: RepoUrlPickerState;
  onChange: (state: RepoUrlPickerState) => void;
  rawErrors: string[];
  disabled?: boolean;
}

export const GitlabRepoPicker = (props: GitlabRepoPickerProps) => {
  const { allowedOwners = [], state, onChange, rawErrors, disabled } = props;
  const ownerItems: SelectItem[] = allowedOwners ? allowedOwners.map(i => ({ label: i, value: i })) : [{ label: 'Loading...', value: 'loading' }];

  const { owner } = state;

  return (
    <FormControl margin="normal" required error={rawErrors?.length > 0 && !owner}>
      {allowedOwners?.length ? (
        <Select
          native
          label="Owner Available"
          onChange={selected =>
            onChange({
              owner: String(Array.isArray(selected) ? selected[0] : selected),
            })
          }
          disabled={allowedOwners.length === 1 || disabled}
          selected={owner}
          items={ownerItems}
        />
      ) : (
        <>
          <InputLabel htmlFor="ownerInput">Owner</InputLabel>
          <Input id="ownerInput" onChange={e => onChange({ owner: e.target.value })} value={owner} disabled={disabled} />
        </>
      )}
      <FormHelperText>GitLab namespace where this repository will belong to. It can be the name of organization, group, subgroup, user, or the project.</FormHelperText>
    </FormControl>
  );
};
