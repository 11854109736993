import { Location, Path } from 'history';
import { isEqual, isMatch } from 'lodash';
import qs from 'qs';

export function isLocationMatch(currentLocation: Location, toLocation: Path, exact: boolean = false) {
  const toDecodedSearch = new URLSearchParams(toLocation.search).toString();
  const toQueryParameters = qs.parse(toDecodedSearch);

  const currentDecodedSearch = new URLSearchParams(currentLocation.search).toString();
  const currentQueryParameters = qs.parse(currentDecodedSearch);

  const queryStringMatcher = exact ? isEqual : isMatch;

  const matching = isEqual(toLocation.pathname, currentLocation.pathname) && queryStringMatcher(currentQueryParameters, toQueryParameters);

  return matching;
}
