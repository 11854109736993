import { BackstageTheme } from '@backstage/theme';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandLess';
import { usePrevious } from '@react-hookz/web';
import React, { useEffect, useState } from 'react';
import { useDryRun } from '../DryRunContext';
import { DryRunResultsList } from './DryRunResultsList';
import { DryRunResultsView } from './DryRunResultsView';

const useStyles = makeStyles(() => ({
  header: {
    height: 48,
    minHeight: 0,
    '&.Mui-expanded': {
      height: 48,
      minHeight: 0,
    },
  },
  content: {
    display: 'grid',
    background: useTheme<BackstageTheme>().palette.background.default,
    gridTemplateColumns: '180px auto 1fr',
    gridTemplateRows: '1fr',
    padding: 0,
    height: 400,
  },
}));

export function DryRunResults() {
  const classes = useStyles();
  const dryRun = useDryRun();
  const [expanded, setExpanded] = useState(false);
  const [hidden, setHidden] = useState(true);

  const resultsLength = dryRun.results.length;
  const prevResultsLength = usePrevious(resultsLength);
  useEffect(() => {
    if (prevResultsLength === 0 && resultsLength === 1) {
      setHidden(false);
      setExpanded(true);
    } else if (prevResultsLength === 1 && resultsLength === 0) {
      setExpanded(false);
    }
  }, [prevResultsLength, resultsLength]);

  return (
    <>
      <Accordion
        variant="outlined"
        expanded={expanded}
        hidden={resultsLength === 0 && hidden}
        onChange={(_, exp) => setExpanded(exp)}
        onTransitionEnd={() => resultsLength === 0 && setHidden(true)}
      >
        <AccordionSummary className={classes.header} expandIcon={<ExpandMoreIcon />}>
          <Typography>Dry-run results</Typography>
        </AccordionSummary>
        <Divider orientation="horizontal" />
        <AccordionDetails className={classes.content}>
          <DryRunResultsList />
          <Divider orientation="horizontal" />
          <DryRunResultsView />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
