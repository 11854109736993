import React from 'react';
import { LogViewer } from '@backstage/core-components';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
});

/**
 * The text of the event stream
 *
 * @alpha
 */
export const TaskLogStream = (props: { logs: { [k: string]: string[] } }) => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <LogViewer
        text={Object.values(props.logs)
          .map(l => l.join('\n'))
          .filter(Boolean)
          .join('\n')}
      />
    </div>
  );
};
