import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MicDropSvgUrl from './mic-drop.svg';

const useStyles = makeStyles(
  theme => ({
    micDrop: {
      maxWidth: '60%',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        maxWidth: '96%',
        bottom: 'unset',
        right: 'unset',
        margin: theme.spacing(10, 'auto', 4),
      },
    },
  }),
  { name: 'BackstageErrorPageMicDrop' },
);

export type MicDropClassKey = 'micDrop';

export const MicDrop = () => {
  const classes = useStyles();
  return <img src={MicDropSvgUrl} className={classes.micDrop} alt="Girl dropping mic from her hands" />;
};
