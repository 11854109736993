import { Entity, parseEntityRef } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import { catalogApiRef } from '../api';

/**
 * Fetches all entities that appear in the entity's relations, optionally
 * filtered by relation type and kind.
 *
 * @public
 */
export function useRelatedEntities(
  entity: Entity,
  relationFilter: { type?: string; kind?: string },
): {
  entities: Entity[] | undefined;
  loading: boolean;
  error: Error | undefined;
} {
  const filterByTypeLower = relationFilter?.type?.toLocaleLowerCase('en-US');
  const filterByKindLower = relationFilter?.kind?.toLocaleLowerCase('en-US');
  const catalogApi = useApi(catalogApiRef);

  const {
    loading,
    value: entities,
    error,
  } = useAsync(async () => {
    const relations = entity.relations?.filter(
      r => (!filterByTypeLower || r.type.toLocaleLowerCase('en-US') === filterByTypeLower) && (!filterByKindLower || parseEntityRef(r.targetRef).kind === filterByKindLower),
    );

    if (!relations?.length) {
      return [];
    }

    const { items } = await catalogApi.getEntitiesByRefs({
      entityRefs: relations.map(r => r.targetRef),
    });

    return items.filter((x): x is Entity => Boolean(x));
  }, [entity, filterByTypeLower, filterByKindLower]);

  return {
    entities,
    loading,
    error,
  };
}
