import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { TenantSettings } from '@tmatic/tenant-settings-common';
import React, { PropsWithChildren } from 'react';
import { useAsync } from 'react-use';
import { TenantSettingsContext } from './context';

const DEFAULT_TENANT_SETTINGS: TenantSettings = {
  configuratorVisible: undefined,
};

export const TenantSettingsProvider = ({ children }: PropsWithChildren) => {
  const catalogClient = useApi(catalogApiRef);
  const tenantSettings = useAsync(async () => {
    return catalogClient.performCustomRequest<undefined, TenantSettings>({
      method: 'GET',
      url: { suffix: 'tenant-settings' },
    });
  });

  return <TenantSettingsContext.Provider value={tenantSettings.value || DEFAULT_TENANT_SETTINGS}>{children}</TenantSettingsContext.Provider>;
};
