import { createApiRef } from '@backstage/core-plugin-api';
import { GetExploreToolsRequest, GetExploreToolsResponse } from '@backstage/plugin-explore-common';

/**
 * Interface for the explore plugin.
 *
 * @public
 */
export interface ExploreApi {
  /**
   * Returns a list of explore tools.
   *
   * @param request - The The request query options
   */
  getTools(request?: GetExploreToolsRequest): Promise<GetExploreToolsResponse>;
}

/**
 * @public
 */
export const exploreApiRef = createApiRef<ExploreApi>({
  id: 'plugin.explore.service',
});
