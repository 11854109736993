import { Entity } from '@backstage/catalog-model';

/**
 * Returns true if the given entity has labels annotation given by the
 * catalog. For use by EntitySwitch
 *
 * @public
 */
export function hasLabels(entity: Entity) {
  return entity?.metadata?.labels ? Object.keys(entity?.metadata?.labels).some(Boolean) : false;
}
