import { IconComponent } from '@backstage/core-plugin-api';
import { defaultEntityPresentation } from '@backstage/plugin-catalog-react';
import { HumanDuration } from '@backstage/types';
import ApartmentIcon from '@material-ui/icons/Apartment';
import BusinessIcon from '@material-ui/icons/Business';
import ExtensionIcon from '@material-ui/icons/Extension';
import HelpIcon from '@material-ui/icons/Help';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MemoryIcon from '@material-ui/icons/Memory';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import { DefaultEntityPresentationApiRenderer } from './DefaultEntityPresentationApi';

export const DEFAULT_CACHE_TTL: HumanDuration = { seconds: 10 };

export const DEFAULT_BATCH_DELAY: HumanDuration = { milliseconds: 50 };

export const UNKNOWN_KIND_ICON: IconComponent = HelpIcon;

export const DEFAULT_ICONS: Record<string, IconComponent> = {
  api: ExtensionIcon,
  component: MemoryIcon,
  system: BusinessIcon,
  domain: ApartmentIcon,
  location: LocationOnIcon,
  user: PersonIcon,
  group: PeopleIcon,
  template: LibraryAddIcon,
};

export function createDefaultRenderer(options: { async: boolean }): DefaultEntityPresentationApiRenderer {
  return {
    async: options.async,

    render: ({ entityRef, entity, context }) => {
      const presentation = defaultEntityPresentation(entity || entityRef, context);
      return {
        snapshot: presentation,
        loadEntity: options.async,
      };
    },
  };
}
