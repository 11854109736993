import { Entity, CompoundEntityRef, stringifyEntityRef } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { useCallback, useMemo } from 'react';
import useObservable from 'react-use/lib/useObservable';
import { starredEntitiesApiRef } from '../apis';

function getEntityRef(entityOrRef: Entity | CompoundEntityRef | string): string {
  return typeof entityOrRef === 'string' ? entityOrRef : stringifyEntityRef(entityOrRef);
}

/** @public */
export function useStarredEntities(): {
  starredEntities: Set<string>;
  toggleStarredEntity: (entityOrRef: Entity | CompoundEntityRef | string) => void;
  isStarredEntity: (entityOrRef: Entity | CompoundEntityRef | string) => boolean;
} {
  const starredEntitiesApi = useApi(starredEntitiesApiRef);

  const starredEntities = useObservable(
    useMemo(() => starredEntitiesApi.starredEntitie$(), [starredEntitiesApi]),
    new Set<string>(),
  );

  const isStarredEntity = useCallback((entityOrRef: Entity | CompoundEntityRef | string) => starredEntities.has(getEntityRef(entityOrRef)), [starredEntities]);

  const toggleStarredEntity = useCallback(
    (entityOrRef: Entity | CompoundEntityRef | string) => starredEntitiesApi.toggleStarred(getEntityRef(entityOrRef)).then(),
    [starredEntitiesApi],
  );

  return {
    starredEntities,
    toggleStarredEntity,
    isStarredEntity,
  };
}
