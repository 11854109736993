import React, { useCallback, useState } from 'react';
import useInterval from 'react-use/lib/useInterval';
import { DateTime, Interval } from 'luxon';
import humanizeDuration from 'humanize-duration';
import { Typography } from '@material-ui/core';
import { useMountEffect } from '@react-hookz/web';

export const StepTime = (props: {
  step: {
    id: string;
    name: string;
    startedAt?: string;
    endedAt?: string;
  };
}) => {
  const [time, setTime] = useState('');
  const { step } = props;

  const calculate = useCallback(() => {
    if (!step.startedAt) {
      setTime('');
      return;
    }

    const end = step.endedAt ? DateTime.fromISO(step.endedAt) : DateTime.local();

    const startedAt = DateTime.fromISO(step.startedAt);
    const formatted = Interval.fromDateTimes(startedAt, end).toDuration().valueOf();

    setTime(humanizeDuration(formatted, { round: true }));
  }, [step.endedAt, step.startedAt]);

  useMountEffect(() => calculate());

  useInterval(() => !step.endedAt && calculate(), 1000);

  return <Typography variant="caption">{time}</Typography>;
};
