import { BackstageTheme } from '@backstage/theme';
import { sidebarConfig } from '@backstage/core-components';
import { Theme, useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useUserProfile } from '../useUserProfileInfo';

const useStyles = makeStyles<Theme, { size: number }>(() => ({
  avatar: {
    width: ({ size }) => size,
    height: ({ size }) => size,
    fontSize: ({ size }) => size * 0.7,
    border: `1px solid ${useTheme<BackstageTheme>().palette.textSubtle}`,
  },
}));

/** @public */
export const UserSettingsSignInAvatar = (props: { size?: number }) => {
  const { size } = props;

  const { iconSize } = sidebarConfig;
  const classes = useStyles(size ? { size } : { size: iconSize });
  const { profile } = useUserProfile();

  return <Avatar src={profile.picture} className={classes.avatar} alt="Profile picture" />;
};
