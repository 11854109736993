import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { PropsWithChildren, ReactNode } from 'react';
import { Helmet } from 'react-helmet';

/**
 * TODO: favoriteable capability
 */

/** @public */
export type ContentHeaderClassKey = 'container' | 'leftItemsBox' | 'rightItemsBox' | 'description' | 'title';

const useStyles = (props: ContentHeaderProps) =>
  makeStyles(
    theme => ({
      container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        textAlign: props.textAlign,
      },
      leftItemsBox: {
        flex: '1 1 auto',
        minWidth: 0,
        overflow: 'visible',
      },
      rightItemsBox: {
        flex: '0 1 auto',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginLeft: theme.spacing(1),
        minWidth: 0,
        overflow: 'visible',
      },
      description: {},
      title: {
        display: 'inline-flex',
        marginBottom: 0,
      },
    }),
    { name: 'BackstageContentHeader' },
  );

type ContentHeaderTitleProps = {
  title?: string;
  className?: string;
};

const ContentHeaderTitle = ({ title = 'Unknown page', className }: ContentHeaderTitleProps) => (
  <Typography variant="h4" component="h2" className={className} data-testid="header-title">
    {title}
  </Typography>
);

type ContentHeaderProps = {
  title?: ContentHeaderTitleProps['title'];
  titleComponent?: ReactNode;
  description?: string;
  textAlign?: 'left' | 'right' | 'center';
};

/**
 *  A header at the top inside a {@link Content}.
 *
 * @public
 *
 */

export function ContentHeader(props: PropsWithChildren<ContentHeaderProps>) {
  const { description, title, titleComponent: TitleComponent = undefined, children, textAlign = 'left' } = props;
  const classes = useStyles({ textAlign })();

  const renderedTitle = TitleComponent ? TitleComponent : <ContentHeaderTitle title={title} className={classes.title} />;

  return (
    <>
      <Helmet title={title} />
      <Box className={classes.container}>
        <Box className={classes.leftItemsBox}>
          {renderedTitle}
          {description && (
            <Typography className={classes.description} variant="body2">
              {description}
            </Typography>
          )}
        </Box>
        <Box className={classes.rightItemsBox}>{children}</Box>
      </Box>
    </>
  );
}
