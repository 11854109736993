import { InfoCardVariants, Table, TableColumn, TableOptions } from '@backstage/core-components';
import { Entity } from '@backstage/catalog-model';
import makeStyles from '@mui/styles/makeStyles';
import { BackstageTheme } from '@backstage/theme';
import { useTheme } from '@mui/material/styles';
import { MaterialTableProps } from '@material-table/core';
import React, { ReactNode } from 'react';
import { columnFactories } from './columns';
import { componentEntityColumns, componentTMaticEntityColumns, systemEntityColumns } from './presets';

/**
 * Props for {@link EntityTable}.
 *
 * @public
 */
export interface EntityTableProps<T extends Entity> {
  title: string;
  variant?: InfoCardVariants;
  entities: T[];
  emptyContent?: ReactNode;
  columns: TableColumn<T>[];
  actions?: MaterialTableProps<T>['actions'];
  tableOptions?: TableOptions;
}

const useStyles = makeStyles(() => ({
  empty: {
    padding: useTheme<BackstageTheme>().spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));

/**
 * A general entity table component, that can be used for composing more
 * specific entity tables.
 *
 * @public
 */
export const EntityTable = <T extends Entity>(props: EntityTableProps<T>) => {
  const { entities, title, emptyContent, variant = 'gridItem', columns, actions, tableOptions = {} } = props;

  const classes = useStyles();
  const tableStyle: React.CSSProperties = {
    minWidth: '0',
    width: '100%',
  };

  if (variant === 'gridItem') {
    tableStyle.height = 'calc(100% - 10px)';
  }

  return (
    <Table<T>
      columns={columns}
      title={title}
      style={tableStyle}
      emptyContent={emptyContent && <div className={classes.empty}>{emptyContent}</div>}
      options={{
        // TODO: Toolbar padding if off compared to other cards, should be: padding: 16px 24px;
        search: false,
        paging: false,
        actionsColumnIndex: -1,
        padding: 'dense',
        draggable: false,
        ...tableOptions,
      }}
      data={entities}
      actions={actions as any}
    />
  );
};

EntityTable.columns = columnFactories;

EntityTable.systemEntityColumns = systemEntityColumns;

EntityTable.componentEntityColumns = componentEntityColumns;

EntityTable.componentTMaticEntityColumns = componentTMaticEntityColumns;
