import { KebabToSnakeCase } from '@tmatic/utils';

export const TMATIC_ANNOTATION_GENERATOR_THREAD_ID = 'tmatic.io/generator-thread';

export const TMATIC_ANNOTATION_LINK_NPM: 'tmatic.io/link-npm' = 'tmatic.io/link-npm';
export const TMATIC_ANNOTATION_LINK_NPM_API: 'tmatic.io/link-npm-api' = 'tmatic.io/link-npm-api';
export const TMATIC_ANNOTATION_LINK_ECR: 'tmatic.io/link-ecr' = 'tmatic.io/link-ecr'; // deprecated
export const TMATIC_ANNOTATION_LINK_CR: 'tmatic.io/link-cr' = 'tmatic.io/link-cr';
export const TMATIC_ANNOTATION_LINK_APPLICATION: 'tmatic.io/link-application' = 'tmatic.io/link-application';
export const TMATIC_ANNOTATION_REPOSITORY_URI: 'tmatic.io/repository-uri' = 'tmatic.io/repository-uri';
export const TMATIC_ANNOTATION_APPLICATION_PORT_HTTP: 'tmatic.io/application-port-http' = 'tmatic.io/application-port-http';
export const TMATIC_ANNOTATION_APPLICATION_ENTRYPOINT_HTTP: 'tmatic.io/entrypoint-http' = 'tmatic.io/entrypoint-http';

export const TMATIC_ANNOTATION_RESOURCE_DATABASE_ENGINE: 'tmatic.io/resource-database-engine' = 'tmatic.io/resource-database-engine';
export const TMATIC_ANNOTATION_RESOURCE_DATABASE_HOST: 'tmatic.io/resource-database-host' = 'tmatic.io/resource-database-host';
export const TMATIC_ANNOTATION_RESOURCE_DATABASE_PORT: 'tmatic.io/resource-database-port' = 'tmatic.io/resource-database-port';
export const TMATIC_ANNOTATION_RESOURCE_DATABASE_NAME: 'tmatic.io/resource-database-name' = 'tmatic.io/resource-database-name';
export const TMATIC_ANNOTATION_RESOURCE_DATABASE_CREDENTIALS: 'tmatic.io/resource-database-credentials' = 'tmatic.io/resource-database-credentials';
export const TMATIC_ANNOTATION_RESOURCE_DATABASE_CREDENTIALS_PATH: 'tmatic.io/resource-database-credentials-path' = 'tmatic.io/resource-database-credentials-path';
export const TMATIC_ANNOTATION_RESOURCE_DATABASE_USERNAME: 'tmatic.io/resource-database-username' = 'tmatic.io/resource-database-username';
export const TMATIC_ANNOTATION_RESOURCE_DATABASE_PASSWORD: 'tmatic.io/resource-database-password' = 'tmatic.io/resource-database-password';

export const TMATIC_ANNOTATION_RESOURCE_CLUSTER_CONFIG_PATH: 'tmatic.io/resource-k8s-cluster-config-path' = 'tmatic.io/resource-k8s-cluster-config-path';
export const TMATIC_ANNOTATION_RESOURCE_CLUSTER_URL: 'tmatic.io/resource-k8s-cluster-url' = 'tmatic.io/resource-k8s-cluster-url';
export const TMATIC_ANNOTATION_RESOURCE_CLUSTER_GROUP: 'tmatic.io/resource-k8s-cluster-group' = 'tmatic.io/resource-k8s-cluster-group'; // internal | external

export const TMATIC_ANNOTATION_COMPONENT_GIT_REPO_OWNER: 'tmatic.io/git-repo-owner' = 'tmatic.io/git-repo-owner';
export const TMATIC_ANNOTATION_COMPONENT_GIT_REPO_NAME: 'tmatic.io/git-repo-name' = 'tmatic.io/git-repo-name';
export const TMATIC_ANNOTATION_COMPONENT_ORG_PREFIX: 'tmatic.io/org-prefix' = 'tmatic.io/org-prefix';
export const TMATIC_ANNOTATION_COMPONENT_GIT_REPO_DEFAULT_BRANCH: 'tmatic.io/git-repo-default-branch' = 'tmatic.io/git-repo-default-branch';
export const TMATIC_ANNOTATION_ARTIFACT_SOURCE: 'artifacts/source' = 'artifacts/source';
export const TMATIC_ANNOTATION_ARTIFACT_NAME: 'artifacts/name' = 'artifacts/name';
export const TMATIC_ANNOTATION_ARTIFACT_BRANCH: 'artifacts/branch' = 'artifacts/branch';
export const TMATIC_ANNOTATION_ARTIFACT_STRATEGY: 'artifacts/strategy' = 'artifacts/strategy';
export const TMATIC_ANNOTATION_ARTIFACT_VERSION: 'artifacts/version' = 'artifacts/version';

export const TMATIC_ANNOTATION_COMPONENT_CI_SETTINGS_BUILD_BRANCHES: 'tmatic.io/ci-settings-build-branches' = 'tmatic.io/ci-settings-build-branches';

export const TMATIC_ANNOTATION_APPLICATION_ARGOCD_INSTANCE: 'tmatic.io/application-argocd-instance' = 'tmatic.io/application-argocd-instance';
export const TMATIC_ANNOTATION_APPLICATION_ARGOCD_PROJECT: 'tmatic.io/application-argocd-project' = 'tmatic.io/application-argocd-project';
export const TMATIC_ANNOTATION_APPLICATION_CLUSTER_NAME: 'tmatic.io/application-cluster-name' = 'tmatic.io/application-cluster-name';
export const TMATIC_ANNOTATION_APPLICATION_ARTIFACT_REF: 'tmatic.io/application-artifact-ref' = 'tmatic.io/application-artifact-ref';
export const TMATIC_ANNOTATION_APPLICATION_COMPONENT_REF: 'tmatic.io/application-component-ref' = 'tmatic.io/application-component-ref';
export const TMATIC_ANNOTATION_APPLICATION_SUBDOMAIN: 'tmatic.io/application-subdomain' = 'tmatic.io/application-subdomain';

export interface TMaticAnnotation {
  CONTROL_PLANE_NAME: 'tmatic.io/control-plane-name';
  CONTROL_PLANE_ENTITY_REF: 'tmatic.io/control-plane-entity-ref';
  CROSSPLANE_RESOURCE_ORIGINAL_NAMESPACE: 'tmatic.io/crossplane-resource-original-namespace';
  CROSSPLANE_RESOURCE_SPEC_GROUP: 'tmatic.io/crossplane-resource-spec-group';
  CROSSPLANE_RESOURCE_KIND: 'tmatic.io/crossplane-resource-kind';
  CROSSPLANE_RESOURCE_API_KIND: 'tmatic.io/crossplane-resource-api-kind';
  CROSSPLANE_RESOURCE_SPEC_CLAIM_KIND: 'tmatic.io/crossplane-resource-spec-claim-kind'; // applicable for CRD
  CROSSPLANE_RESOURCE_COMPOSITE_RESOURCE_DEFINITION_REF: 'tmatic.io/crossplane-resource-composite-resource-definition-ref'; // applicable for claims and composite resources
  CROSSPLANE_RESOURCE_CLAIM_REF: 'tmatic.io/crossplane-resource-claim-ref'; // applicable for composite resources and managed resources
  CROSSPLANE_RESOURCE_COMPOSITE_RESOURCE_REF: 'tmatic.io/crossplane-resource-composite-resource-ref'; // applicable for managed resources
  CROSSPLANE_RESOURCE_SPEC_VERSIONS_SCHEMA: 'tmatic.io/crossplane-resource-spec-versions-schema';
  CROSSPLANE_READY_STATUS: 'tmatic.io/crossplane-ready-status';
  CROSSPLANE_VERSION: 'tmatic.io/crossplane-version';
}

export const TMATIC_ANNOTATION: TMaticAnnotation = {
  CONTROL_PLANE_NAME: 'tmatic.io/control-plane-name',
  CONTROL_PLANE_ENTITY_REF: 'tmatic.io/control-plane-entity-ref',
  CROSSPLANE_RESOURCE_ORIGINAL_NAMESPACE: 'tmatic.io/crossplane-resource-original-namespace',
  CROSSPLANE_RESOURCE_SPEC_GROUP: 'tmatic.io/crossplane-resource-spec-group',
  CROSSPLANE_RESOURCE_KIND: 'tmatic.io/crossplane-resource-kind',
  CROSSPLANE_RESOURCE_API_KIND: 'tmatic.io/crossplane-resource-api-kind',
  CROSSPLANE_RESOURCE_SPEC_CLAIM_KIND: 'tmatic.io/crossplane-resource-spec-claim-kind',
  CROSSPLANE_RESOURCE_COMPOSITE_RESOURCE_DEFINITION_REF: 'tmatic.io/crossplane-resource-composite-resource-definition-ref',
  CROSSPLANE_RESOURCE_CLAIM_REF: 'tmatic.io/crossplane-resource-claim-ref',
  CROSSPLANE_RESOURCE_COMPOSITE_RESOURCE_REF: 'tmatic.io/crossplane-resource-composite-resource-ref',
  CROSSPLANE_RESOURCE_SPEC_VERSIONS_SCHEMA: 'tmatic.io/crossplane-resource-spec-versions-schema',
  CROSSPLANE_READY_STATUS: 'tmatic.io/crossplane-ready-status',
  CROSSPLANE_VERSION: 'tmatic.io/crossplane-version',
};

export interface TMaticAnnotationScorecardCriteriaFields {
  CHAT_CHANNEL: 'tmatic.io/criteria-field--chat-channel';
  DASHBOARD: 'tmatic.io/criteria-field--dashboard';
  DESCRIPTION: 'tmatic.io/criteria-field--description';
  ON_CALL: 'tmatic.io/criteria-field--on-call';
  OWNER: 'tmatic.io/criteria-field--owner';
  PROJECT: 'tmatic.io/criteria-field--project';
  REPOSITORY: 'tmatic.io/criteria-field--repository';
}

export const TMATIC_ANNOTATION_SCORECARD_CRITERIA_FIELDS: TMaticAnnotationScorecardCriteriaFields = {
  CHAT_CHANNEL: 'tmatic.io/criteria-field--chat-channel',
  DASHBOARD: 'tmatic.io/criteria-field--dashboard',
  DESCRIPTION: 'tmatic.io/criteria-field--description',
  ON_CALL: 'tmatic.io/criteria-field--on-call',
  OWNER: 'tmatic.io/criteria-field--owner',
  PROJECT: 'tmatic.io/criteria-field--project',
  REPOSITORY: 'tmatic.io/criteria-field--repository',
};

export type TMaticAnnotationArtifactSource = 'aws-ecr' | 'docker-hub';

export const TMATIC_ANNOTATION_ARTIFACT_SOURCES_MAP: Record<Uppercase<KebabToSnakeCase<TMaticAnnotationArtifactSource>>, TMaticAnnotationArtifactSource> = {
  AWS_ECR: 'aws-ecr',
  DOCKER_HUB: 'docker-hub',
};

export interface TMaticAnnotationsArtifact {
  REGISTRY_UID: 'tmatic.io/registry-uid';
}

export const TMATIC_ANNOTATIONS_ARTIFACT: TMaticAnnotationsArtifact = {
  REGISTRY_UID: 'tmatic.io/registry-uid',
};
