import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const LogoFull = () => {
  return (
    <SvgIcon
      viewBox="0 0 1264 350"
      x="0px"
      y="0px"
      style={{
        width: 'auto',
        height: '30px',
      }}
    >
      <g>
        <g>
          <g>
            <path
              fill="#7df3e1"
              d="M866.24,226.08c-9.68-23.09-19.17-45.72-28.88-68.88c-0.52,1.03-0.77,1.46-0.97,1.91 c-2.76,6.37-5.58,12.71-8.24,19.13c-1.73,4.19-3.13,8.51-4.83,12.71c-0.83,2.04-2.09,3.89-3.01,5.89 c-0.63,1.38-1.01,2.88-1.48,4.33c-0.12,0.38-0.12,0.81-0.28,1.17c-1.92,4.26-3.83,8.52-5.84,12.74 c-0.28,0.59-0.96,1.15-1.58,1.38c-1.18,0.44-2.45,0.85-3.69,0.89c-4.89,0.16-9.78,0.25-14.67,0.23c-5.95-0.02-7.86-5.87-6.1-9.89 c2.6-5.94,5.42-11.77,7.96-17.73c2.38-5.59,4.52-11.28,6.75-16.92c0.1-0.25,0.13-0.52,0.25-0.76c2.58-5.58,5.26-11.11,7.73-16.74 c2.04-4.65,3.79-9.42,5.73-14.11c1.12-2.72,2.36-5.39,3.54-8.09c1.42-3.25,2.95-6.45,4.22-9.76c1.14-2.97,3.44-4.05,6.27-4.11 c5.56-0.11,11.12-0.03,16.68,0.02c0.65,0.01,1.29,0.31,1.94,0.47c2.22,0.54,3.48,1.96,4.29,4.09c1.73,4.56,3.57,9.08,5.49,13.56 c1.92,4.48,4.05,8.87,5.99,13.34c3.16,7.3,6.26,14.63,9.32,21.97c2.44,5.86,4.65,11.81,7.17,17.63 c2.44,5.64,5.21,11.14,7.73,16.75c1.17,2.61,2.01,5.37,3.11,8.02c2.51,6.06,5.07,12.09,7.64,18.12c1.26,2.95,2.69,5.84,3.92,8.81 c2.46,5.92-0.38,10.17-6.81,10.17c-20.45,0.02-40.9,0-61.34,0c-17.63,0-35.26,0.02-52.9,0.03c-1.14,0-2.3,0.09-3.41-0.1 c-1.61-0.27-3.41,0.52-4.72-1.39c-1.89-2.76-2.61-5.42-1.07-8.59c1.75-3.6,3.47-7.24,4.89-10.99c1.41-3.71,4.31-4.81,7.75-4.9 c6.97-0.17,13.94-0.15,20.91-0.16c18.7-0.01,37.41,0.01,56.11-0.01C863.06,226.34,864.32,226.2,866.24,226.08z"
            />
            <path
              fill="#7df3e1"
              d="M713.06,165.47c-2.47,3.45-4.91,6.93-7.43,10.35c-1.78,2.42-3.63,4.78-5.51,7.12 c-2.82,3.53-5.74,6.98-8.52,10.54c-2.55,3.26-4.97,6.63-7.45,9.95c-0.96,1.29-1.78,2.7-2.9,3.83c-2.95,2.99-5.95,3.39-9.66,1.4 c-0.68-0.37-1.32-0.95-1.78-1.57c-2.88-3.86-5.7-7.77-8.55-11.64c-4.84-6.57-9.63-13.19-14.56-19.69 c-3.63-4.79-7.54-9.38-11.18-14.17c-2.67-3.51-5.09-7.21-7.68-10.78c-1.49-2.06-3.11-4.02-4.65-6.05 c-3.64-4.8-7.23-9.63-10.91-14.39c-1.98-2.56-1.91-5.29-0.65-7.96c1.16-2.46,3.6-3.06,6.12-3.05c4.69,0.03,9.38,0.08,14.07,0.23 c3.22,0.1,5.69,1.02,7.45,4.33c1.78,3.36,4.69,6.11,7.11,9.13c0.38,0.47,0.82,0.88,1.18,1.37c5.57,7.52,11.11,15.06,16.72,22.56 c3.6,4.82,7.31,9.57,10.97,14.34c0.15,0.2,0.39,0.33,0.71,0.59c0.42-0.51,0.82-0.94,1.16-1.41c2.86-3.93,5.7-7.87,8.55-11.8 c4.63-6.37,9.26-12.74,13.92-19.08c1.41-1.92,2.98-3.72,4.39-5.64c2.04-2.79,3.94-5.68,5.99-8.46c1.22-1.65,2.49-3.32,4.01-4.68 c0.86-0.77,2.26-1.28,3.44-1.31c4.96-0.15,9.92-0.12,14.88-0.04c1.24,0.02,2.53,0.34,3.69,0.8c3.1,1.24,4.05,2.82,4.05,6.14 c0.02,36.13,0.05,72.26,0.07,108.39c0,3.55,0.12,7.11-0.1,10.64c-0.25,4.02-3.18,6.56-7.16,6.56c-3.89,0-7.78,0-11.66,0 c-4.4,0-7.25-2.41-7.5-6.91c-0.27-4.74-0.12-9.51-0.13-14.27c-0.02-15.68-0.04-31.36-0.05-47.05c-0.01-6.09,0-12.18,0-18.27 C713.36,165.52,713.21,165.49,713.06,165.47z"
            />
            <path
              fill="#7df3e1"
              d="M439.94,145.77c0,1.02,0,1.75,0,2.48c0.01,32.18,0.02,64.35,0,96.53c0,1.19-0.1,2.43-0.45,3.56 c-1.22,3.94-2.68,5.08-6.81,5.2c-4.15,0.12-8.31,0.08-12.47,0.08c-4.75,0.01-7.05-2.61-7.24-7.51 c-0.14-3.68,0.02-7.37,0.02-11.06c0.01-36.13,0.04-72.26-0.02-108.39c-0.01-4.26,2.63-7.59,7.47-7.4 c3.82,0.15,7.64,0.13,11.46,0.13c13.41,0.02,26.81,0,40.22,0.05c1.8,0.01,3.61,0.19,5.38,0.45c2.53,0.37,4.13,2.16,4.24,4.47 c0.26,5.26,0.39,10.58-0.07,15.82c-0.34,3.89-2.97,5.39-6.93,5.33c-4.56-0.07-9.11,0.08-13.67,0.08c-5.7,0-11.39-0.08-17.09-0.09 C442.74,145.5,441.48,145.66,439.94,145.77z"
            />
            <path
              fill="#7df3e1"
              d="M964.54,185.41c0-17.97-0.01-35.94,0.01-53.91c0-2.21,0.05-4.43,0.23-6.63c0.2-2.41,1.37-4.21,3.84-4.83 c1.36-0.34,2.76-0.67,4.15-0.67c17.57-0.01,35.13,0.03,52.7,0.07c6.16,0.01,8.19,2.02,8.25,8.21c0.04,3.49,0.04,6.97,0.01,10.46 c-0.04,4.77-2.78,7.5-7.55,7.51c-10.05,0.02-20.11,0.01-30.16,0.01c-0.6,0-1.21,0.04-1.81,0.02c-1.94-0.06-2.72,0.79-2.7,2.8 c0.06,8.72-0.01,17.43,0,26.15c0.02,22.66,0.06,45.32,0.09,67.99c0,1.47-0.08,2.95-0.13,4.42c-0.11,3.55-2.5,6.34-6.09,6.51 c-4.68,0.23-9.39,0.25-14.07,0.04c-4.01-0.18-6.59-3.27-6.76-7.81c-0.14-3.61-0.03-7.24-0.03-10.86c0-16.49,0-32.99,0-49.48 C964.51,185.41,964.52,185.41,964.54,185.41z"
            />
            <path
              fill="#7df3e1"
              d="M1187.04,132.75c0,2.21,0.02,4.42-0.01,6.64c-0.05,3.96-1.46,6.17-5.18,7.43c-5.31,1.8-9.88,4.89-14.05,8.42 c-2.26,1.92-3.71,4.86-5.29,7.48c-5.91,9.86-6.95,20.53-5.38,31.73c0.59,4.21,1.14,8.45,3.08,12.18 c2.43,4.68,5.05,9.2,9.38,12.74c4.2,3.43,8.99,5.52,13.66,7.92c3.08,1.58,4.05,3.93,3.87,7.21c-0.2,3.61-0.13,7.23-0.17,10.85 c-0.01,0.67,0.06,1.34,0.02,2.01c-0.28,4.76-2.88,6.84-7.51,5.76c-9.85-2.29-18.95-6.09-26.4-13.23 c-1.83-1.76-4.13-3.07-5.77-4.98c-2.76-3.22-5.47-6.56-7.63-10.19c-2.35-3.96-4.45-8.16-5.92-12.51 c-1.51-4.47-2.54-9.2-3.07-13.9c-1.03-9-1.06-18.05,0.64-27.02c1.38-7.28,3.6-14.31,7.17-20.8c1.56-2.83,3.86-5.25,5.82-7.86 c0.2-0.26,0.5-0.46,0.68-0.73c4.02-6.45,9.91-10.78,16.35-14.49c1.5-0.87,2.92-1.88,4.41-2.78c0.74-0.44,1.52-0.88,2.34-1.11 c3.68-1.01,7.37-1.95,11.07-2.9c4.02-1.03,7.73,1.77,7.79,5.89c0.03,2.08,0.01,4.16,0.01,6.23 C1186.98,132.75,1187.01,132.75,1187.04,132.75z"
            />
            <path
              fill="#7df3e1"
              d="M1093.06,212.27c0,10.19,0.01,20.37-0.02,30.56c0,1.26-0.15,2.55-0.4,3.78c-0.84,4.05-2.55,5.66-6.72,5.81 c-4.55,0.16-9.11,0.1-13.67-0.05c-3.1-0.1-5.71-3.04-6.08-6.66c-0.18-1.79-0.12-3.61-0.12-5.42 c-0.04-18.09-0.08-36.19-0.11-54.28c0-2.14-0.02-4.3,0.18-6.43c0.41-4.38,3.25-6.89,7.62-6.91c3.28-0.02,6.57-0.03,9.85-0.04 c0.47,0,0.94,0,1.41,0.04c5.54,0.5,8.02,3.39,8.01,9.24c-0.02,10.12,0,20.24,0,30.36 C1093.03,212.27,1093.05,212.27,1093.06,212.27z"
            />
            <path
              fill="#7df3e1"
              d="M540.1,205.41c-7.51,0-15.01,0.01-22.52-0.01c-1.07,0-2.15-0.04-3.2-0.21c-3.13-0.51-5.04-2.79-5.07-5.94 c-0.04-4.42,0.12-8.86-0.19-13.26c-0.33-4.85,3.1-7.83,7.32-7.82c6.5,0.03,13,0,19.5-0.01c8.58,0,17.16-0.01,25.73,0 c1.27,0,2.55,0,3.81,0.16c3.05,0.38,5.41,2.34,5.61,5.37c0.35,5.46,0.45,10.96,0.32,16.44c-0.07,2.84-3.54,5.18-7.19,5.21 c-6.77,0.06-13.54,0.03-20.31,0.03c-1.27,0-2.55,0-3.82,0C540.1,205.38,540.1,205.4,540.1,205.41z"
            />
            <path
              fill="#7df3e1"
              d="M1241.99,168.75c-2.01,0-4.02-0.07-6.03,0.01c-3.87,0.16-6.62-1.29-8.44-4.87 c-1.39-2.72-3.09-5.34-5.04-7.68c-1.92-2.3-4.24-4.28-6.53-6.23c-3.02-2.56-6.81-3.35-10.56-4.04c-5.46-1-7.49-3.29-7.5-8.87 c0-3.68,0.11-7.37,0.14-11.05c0.03-3.72,2.73-6.76,6.43-6.39c3.83,0.38,7.73,1.12,11.32,2.45c5.06,1.87,10.19,3.92,14.7,6.82 c4.62,2.97,8.89,6.71,12.66,10.73c4.39,4.69,7.25,10.52,9.77,16.44c0.58,1.35,1.29,2.66,1.76,4.04c1.62,4.72-1.1,8.61-6.04,8.68 c-2.21,0.03-4.42,0.01-6.63,0.01C1241.99,168.78,1241.99,168.77,1241.99,168.75z"
            />
            <path
              fill="#7df3e1"
              d="M1197.49,241.56c0.39-2.9,0.59-5.84,1.23-8.68c0.57-2.5,2.26-4.12,4.92-4.75c2.4-0.57,4.76-1.35,7.09-2.19 c8.05-2.91,13.08-9.14,17.05-16.27c1.95-3.51,4.58-5.25,8.49-5.29c3.68-0.04,7.38-0.29,11.05-0.08c1.95,0.11,3.91,0.85,5.74,1.61 c0.69,0.29,1.38,1.42,1.45,2.21c0.15,1.77,0.23,3.66-0.23,5.34c-1.74,6.4-5.11,11.91-9.04,17.27 c-4.17,5.69-9.37,10.07-15.08,13.83c-7.04,4.63-14.83,7.72-23.18,9.18c-0.91,0.16-1.88,0.14-2.8,0.04 c-4.08-0.46-5.95-2.41-6.2-6.55c-0.11-1.87-0.02-3.75-0.02-5.63C1197.8,241.59,1197.65,241.57,1197.49,241.56z"
            />
            <path
              fill="#7df3e1"
              d="M1066.2,141.67c0-5.29-0.1-10.58,0.03-15.87c0.09-3.44,2.21-5.85,5.61-6.06c4.74-0.29,9.51-0.41,14.25-0.15 c4.45,0.25,6.94,3.08,6.96,7.74c0.04,10.51-0.07,21.03-0.29,31.54c-0.09,4.3-2.47,6.45-6.78,6.6c-3.81,0.13-7.63,0.2-11.45,0.18 c-5.31-0.02-8.54-3.28-8.62-8.73c-0.07-5.09-0.02-10.18-0.02-15.27C1066,141.67,1066.1,141.67,1066.2,141.67z"
            />
            <path
              fill="#7df3e1"
              d="M636.65,228.74c0,5.35,0.06,10.71-0.02,16.06c-0.08,5.14-2.3,7.22-7.48,7.23c-3.82,0-7.63,0.04-11.45-0.03 c-0.97-0.02-1.93-0.36-2.89-0.59c-3.44-0.84-4.43-3.48-4.45-6.55c-0.07-10.51-0.11-21.02,0.03-31.53 c0.06-4.71,2.54-6.85,7.32-6.9c4.02-0.04,8.03-0.07,12.05-0.1c4.23-0.03,6.99,2.8,6.93,6.95c-0.07,5.15-0.02,10.31-0.02,15.46 C636.67,228.74,636.66,228.74,636.65,228.74z"
            />
            <path
              fill="#7df3e1"
              d="M386.65,145.54c-3.21,0-6.43-0.01-9.64,0c-3.86,0.02-5.28-1.7-5.79-5.83c-0.6-4.88-0.28-9.9-0.07-14.85 c0.13-3.05,2.39-5.21,5.57-5.31c6.22-0.2,12.46-0.16,18.68-0.05c1.34,0.02,2.75,0.63,3.99,1.23c1.71,0.83,2.78,2.18,2.76,4.28 c-0.05,4.69,0.34,9.4-0.07,14.05c-0.35,3.93-2.35,6.28-6.39,6.4c-3.01,0.09-6.03,0.02-9.04,0.02 C386.65,145.51,386.65,145.53,386.65,145.54z"
            />
            <path
              fill="#7df3e1"
              d="M938.7,145.75c-3.93-0.21-7.89-0.19-11.78-0.7c-2.94-0.39-4.31-2.34-4.35-5.37 c-0.06-4.55-0.09-9.11,0.06-13.66c0.16-4.77,1.68-6.33,6.49-6.51c4.68-0.17,9.37-0.04,14.06-0.03c1.47,0,2.95,0.03,4.42,0.11 c3.61,0.18,6.05,2.75,6.06,6.38c0.01,4.29,0.07,8.57-0.01,12.86c-0.1,4.64-2.27,6.67-6.9,6.66c-2.68,0-5.36,0-8.04,0 C938.7,145.58,938.7,145.67,938.7,145.75z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FEC629"
              d="M85.57,303.27l132.19-74.09c0,0,9.07-4.13,21.89,2.79c11.63,6.28,36.28,20.68,40.96,23.8 c2.81,1.88,6.1,4.69,2.86,7.18c-2.84,2.19-108.64,62.49-130.45,74.91c-3.14,1.79-8.36,2.74-11.73,1.41 c-1.29-0.51-2.2-1.7-4.02-2.59c-9.11-4.45-47.97-28.34-47.97-28.34S85.2,306.68,85.57,303.27z"
            />
            <path
              fill="#01A5EE"
              d="M94.39,148.82l40.37,23.58c8.22,4.8,13.53,13.48,13.71,22.99c-0.12,11.66,0.33,140.49,0.34,141.06 c0,0,1.01,6.7-10.99,1.48c-9.33-4.07-38.21-22.1-49.53-29.62c-3.43-2.28-3.02-5.49-2.88-9.6c0.06-1.95,0.4-145,0.4-145 s-0.02-3.46,0.61-4.89C87.41,146.54,92.22,147.58,94.39,148.82z"
            />
          </g>
          <g>
            <path
              fill="#FEC629"
              d="M157.56,22.64l0.98,50.58c0,0,0.7,9.26,5.1,13.11c4.39,3.85,126.82,74.21,126.82,74.21s8.11,6.22,8.44-2.81 c0.34-9.04,2.94-50.86,0.94-55.82c-2-4.96-0.58-11.05-22.57-21.24c-21.99-10.19-112.81-64.72-112.81-64.72 S156.47,11.86,157.56,22.64z"
            />
            <path
              fill="#49CC2E"
              d="M175.91,165.66c-5,2.83-15.69,7.16-16.77,21.65c-0.43,13.27-0.25,39.78-0.2,45.69 c-0.2,4.19-1.19,15.33,12.11,7.77c12.94-7.62,99.69-57.06,116.43-66.6c8.6-5,13.22-14.88,13.63-28.13 c0.15-15.98-0.32-39.9-0.32-39.9s-0.05-13.17-8.8-8.4C264.73,112.59,186.23,159.49,175.91,165.66z"
            />
          </g>
          <g>
            <path
              fill="#FEC629"
              d="M12,237.73c0,0-1.16,13.36,6.37,8.67c5.78-3.6,30.89-18.58,42.26-25.36c5.82-3.47,10.41-8.73,12.79-15.07 c0.82-2.19,1.39-4.53,1.46-6.89c0.27-9.45-2.74-139.15-2.74-139.15s0.2-8.67-8.68-3.35C52.8,62.97,17.72,84,17.72,84 s-7.3-0.85-6.83,14.16C11.37,113.67,12,237.73,12,237.73z"
            />
            <path
              fill="#F26323"
              d="M134.65,159.24c0,0,12.85,10.16,25.23,4.33c12.37-5.83,45.97-26.09,45.97-26.09s8.93-3.56,1.51-8.3 S82.47,57.12,82.47,57.12s-9.37-6.8-21.24,0S15.06,84.34,15.06,84.34s-7.02,3.73,1.13,8.67 C27.78,100.04,134.65,159.24,134.65,159.24z"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default LogoFull;
