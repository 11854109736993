import colors from './colors';

/**
 * Built-in Backstage color palettes.
 *
 * @public
 */
export const palettes = {
  light: {
    type: 'light' as const,
    mode: 'light' as const,
    background: {
      default: colors.backgrounds.light.main,
      paper: colors.backgrounds.light.main,
    },
    common: {
      black: colors.backgrounds.dark.main,
      white: colors.backgrounds.light.main,
    },
    contrastThreshold: 1,
    tonalOffset: 1,
    secondary: colors.backgrounds.dark,
    primary: colors.primary,
    error: colors.system.red,
    warning: colors.system.orange,
    info: colors.system.blue,
    success: colors.system.green,
    status: {
      ok: colors.system.green.main,
      warning: colors.system.orange.main,
      error: colors.system.red.main,
      running: colors.system.blue.main,
      pending: colors.system.yellow.main,
      aborted: colors.system.orange.dark,
    },
    banner: {
      info: colors.system.blue.light,
      error: colors.system.red.main,
      text: colors.backgrounds.dark.main,
      link: colors.system.blue.dark,
      closeButtonColor: colors.backgrounds.dark.main,
      warning: colors.system.orange.main,
    },
    border: colors.backgrounds.dark.main,
    textContrast: colors.primary.main,
    textVerySubtle: colors.backgrounds.dark.dark,
    textSubtle: colors.backgrounds.dark.light,
    highlight: colors.backgrounds.dark.contrastText,
    errorBackground: colors.system.red.light,
    warningBackground: colors.system.orange.light,
    infoBackground: colors.primary.light,
    errorText: colors.backgrounds.dark.main,
    infoText: colors.backgrounds.dark.main,
    warningText: colors.backgrounds.dark.main,
    linkHover: colors.system.blue.main,
    link: colors.system.blue.dark,
    navigation: {
      background: colors.backgrounds.dark.main,
      indicator: colors.primary.light,
      color: colors.backgrounds.light.main,
      selectedColor: colors.system.yellow.main,
      navItem: {
        hoverBackground: colors.primary.light,
      },
      submenu: {
        background: colors.backgrounds.dark.light,
      },
    },
    pinSidebarButton: {
      icon: colors.primary.main,
      background: colors.backgrounds.light.main,
    },
    tabbar: {
      indicator: colors.primary.main,
    },
  },
  dark: {
    type: 'dark' as const,
    mode: 'dark' as const,
    background: {
      default: colors.backgrounds.dark.dark,
      paper: colors.backgrounds.dark.main,
    },
    primary: colors.primary,
    error: colors.system.red,
    warning: colors.system.orange,
    info: colors.system.blue,
    success: colors.system.green,
    status: {
      ok: colors.system.green.main,
      warning: colors.system.orange.main,
      error: colors.system.red.main,
      running: colors.system.blue.main,
      pending: colors.system.yellow.main,
      aborted: colors.system.orange.dark,
    },
    banner: {
      info: colors.system.blue.light,
      error: colors.system.red.main,
      text: colors.backgrounds.dark.main,
      link: colors.system.blue.dark,
      closeButtonColor: colors.backgrounds.dark.main,
      warning: colors.system.orange.main,
    },
    border: colors.primary.main,
    textContrast: colors.primary.main,
    textVerySubtle: colors.backgrounds.dark.dark,
    textSubtle: colors.backgrounds.light.main,
    highlight: colors.backgrounds.dark.contrastText,
    errorBackground: colors.system.red.light,
    warningBackground: colors.system.orange.light,
    infoBackground: colors.primary.light,
    errorText: colors.backgrounds.dark.main,
    infoText: colors.backgrounds.dark.main,
    warningText: colors.backgrounds.dark.main,
    linkHover: colors.system.blue.dark,
    link: colors.system.blue.main,
    navigation: {
      background: colors.backgrounds.dark.main,
      indicator: colors.primary.light,
      color: colors.backgrounds.light.main,
      selectedColor: colors.system.yellow.main,
      navItem: {
        hoverBackground: colors.primary.light,
      },
      submenu: {
        background: colors.backgrounds.dark.light,
      },
    },
    pinSidebarButton: {
      icon: colors.primary.main,
      background: colors.backgrounds.light.main,
    },
    tabbar: {
      indicator: colors.primary.main,
    },
  },
};
