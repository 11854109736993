import { Button } from './button';
import { DataDisplay } from './dataDisplay';
import { Input } from './input';
import { Tab } from './tab';
import { Table } from './table';
import { Typography } from './typography';

export const combinedComponents = {
  ...Button,
  ...Typography,
  ...Tab,
  ...DataDisplay,
  ...Input,
  ...Table,
};
