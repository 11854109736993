import { useAnalytics, useApiHolder } from '@backstage/core-plugin-api';
import { LayoutOptions, TemplateParameterSchema } from '@backstage/plugin-scaffolder-react';
import { JsonValue } from '@backstage/types';
import { Button, LinearProgress, makeStyles, Step as MuiStep, StepLabel as MuiStepLabel, Stepper as MuiStepper } from '@material-ui/core';
import { type IChangeEvent } from '@rjsf/core-v5';
import { ErrorSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import React, { type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { NextFieldExtensionOptions } from '../../extensions';
import { useTemplateSchema } from '../../hooks';
import { FormProps } from '../../types';
import { useTransformSchemaToProps } from '../../hooks/useTransformSchemaToProps';
import { ReviewState, type ReviewStateProps } from '../ReviewState';
import { Form } from '../Form';
import { createAsyncValidators, type FormValidation } from './createAsyncValidators';
import { hasErrors } from './utils';
import * as FieldOverrides from './FieldOverrides';
// eslint-disable-next-line no-restricted-imports
import qs from 'querystring';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(1),
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    marginTop: theme.spacing(2),
  },
  formWrapper: {
    padding: theme.spacing(2),
  },
}));

/**
 * The Props for {@link Stepper} component
 * @alpha
 */
export type StepperProps = {
  manifest: TemplateParameterSchema;
  extensions: NextFieldExtensionOptions<any, any>[];
  templateName?: string;
  FormProps?: FormProps;
  initialState?: Record<string, JsonValue>;
  onCreate: (values: Record<string, JsonValue>) => Promise<void>;
  components?: {
    ReviewStateComponent?: (props: ReviewStateProps) => JSX.Element;
    createButtonText?: ReactNode;
    reviewButtonText?: ReactNode;
  };
  layouts?: LayoutOptions[];
};

// TODO: completely rewrite component
// component uses multiple re-renders. incorrect state handling is caused by two sources of truth - state and query
const queryToObject = (search: string) => qs.parse(search.replace(/^(\?)/, ''));
const parse = (search: string, defaults: Record<string, string>) => _.merge(defaults, queryToObject(search));

/**
 * The `Stepper` component is the Wizard that is rendered when a user selects a template
 * @alpha
 */
export const Stepper = (stepperProps: StepperProps) => {
  const { layouts = [], components = {}, ...props } = stepperProps;
  const { ReviewStateComponent = ReviewState, createButtonText = 'Create', reviewButtonText = 'Review' } = components;
  const analytics = useAnalytics();
  const { steps } = useTemplateSchema(props.manifest);
  const apiHolder = useApiHolder();

  const location = useLocation();
  const [URLSearchParams, setSearchParams] = useSearchParams();

  const [formState, setFormState] = useState(safeJsonParse(URLSearchParams.get('formData')) || props.initialState);
  const formDataStr = JSON.stringify(formState);

  useEffect(() => {
    setSearchParams(parse(location.search, { formData: formDataStr, activeStep: '0' }));
  }, [setSearchParams, location.search, formDataStr]);

  const activeStep = +(URLSearchParams.get('activeStep') as string) || 0;
  const handleChange = useCallback(
    (e: IChangeEvent) => {
      setFormState(e.formData);
      setSearchParams({
        ...parse(location.search, {}),
        formData: JSON.stringify(e.formData),
      });
    },
    [setSearchParams, location.search],
  );

  const [isValidating, setIsValidating] = useState(false);

  const [errors, setErrors] = useState<undefined | FormValidation>();
  const styles = useStyles();

  const extensions = useMemo(() => {
    return Object.fromEntries(props.extensions.map(({ name, component }) => [name, component]));
  }, [props.extensions]);

  const validators = useMemo(() => {
    return Object.fromEntries(props.extensions.map(({ name, validation }) => [name, validation]));
  }, [props.extensions]);

  const validation = useMemo(() => {
    return createAsyncValidators(steps[activeStep]?.mergedSchema, validators, {
      apiHolder,
    });
  }, [steps, activeStep, validators, apiHolder]);

  const handleBack = () => {
    setSearchParams({
      formData: JSON.stringify(formState),
      activeStep: _.toString(activeStep - 1),
    });
  };

  const currentStep = useTransformSchemaToProps(steps[activeStep], { layouts });

  const handleNext = async ({ formData = {} }: { formData?: Record<string, JsonValue> }) => {
    // The validation should never throw, as the validators are wrapped in a try/catch.
    // This makes it fine to set and unset state without try/catch.
    setErrors(undefined);
    setIsValidating(true);

    const returnedValidation = await validation(formData);

    setIsValidating(false);

    const patch: Record<string, string> = {};

    if (hasErrors(returnedValidation)) {
      setErrors(returnedValidation);
    } else {
      setErrors(undefined);
      patch.activeStep = _.toString(activeStep + 1);
      // setActiveStep(activeStep + 1);
    }
    setSearchParams({
      ...parse(location.search, {}),
      formData: JSON.stringify(formData),
      ...patch,
    });
  };

  return (
    <>
      {isValidating && <LinearProgress variant="indeterminate" />}
      <MuiStepper activeStep={activeStep} alternativeLabel variant="elevation">
        {steps.map((step, index) => (
          <MuiStep key={index}>
            <MuiStepLabel>{step.title}</MuiStepLabel>
          </MuiStep>
        ))}
        <MuiStep>
          <MuiStepLabel>Review</MuiStepLabel>
        </MuiStep>
      </MuiStepper>
      <div className={styles.formWrapper}>
        {activeStep < steps.length ? (
          <Form
            validator={validator as any}
            extraErrors={errors as unknown as ErrorSchema}
            formData={formState}
            formContext={{ formData: formState }}
            schema={currentStep.schema}
            uiSchema={currentStep.uiSchema}
            onSubmit={handleNext}
            fields={{ ...FieldOverrides, ...extensions }}
            showErrorList={false}
            onChange={handleChange}
            {...(props.FormProps ?? {})}
          >
            <div className={styles.footer}>
              <Button onClick={handleBack} className={styles.backButton} disabled={activeStep < 1 || isValidating}>
                Back
              </Button>
              <Button variant="contained" color="primary" type="submit" disabled={isValidating}>
                {activeStep === steps.length - 1 ? reviewButtonText : 'Next'}
              </Button>
            </div>
          </Form>
        ) : (
          <>
            <ReviewStateComponent formState={formState} schemas={steps} />
            <div className={styles.footer}>
              <Button onClick={handleBack} className={styles.backButton} disabled={activeStep < 1}>
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  props.onCreate(formState);
                  const name = typeof formState.name === 'string' ? formState.name : undefined;
                  analytics.captureEvent('create', name ?? props.templateName ?? 'unknown');
                }}
              >
                {createButtonText}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

function safeJsonParse(data: string | null) {
  try {
    return JSON.parse(data || '');
  } catch (error) {
    return null;
  }
}
