import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import { ItemCardHeader } from '@backstage/core-components';
import { BackstageTheme } from '@backstage/theme';
import { TemplateEntityV1beta3 } from '@backstage/plugin-scaffolder-common';
import { FavoriteEntity } from '@backstage/plugin-catalog-react';

const useStyles = makeStyles<
  BackstageTheme,
  {
    cardFontColor: string;
    cardBackgroundImage: string;
  }
>(() => ({
  header: {
    backgroundImage: ({ cardBackgroundImage }) => cardBackgroundImage,
    color: ({ cardFontColor }) => cardFontColor,
  },
  subtitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

/**
 * Props for the CardHeader component
 */
export interface CardHeaderProps {
  template: TemplateEntityV1beta3;
}

/**
 * The Card Header with the background for the TemplateCard.
 */
export const CardHeader = (props: CardHeaderProps) => {
  const {
    template: {
      metadata: { title, name },
      spec: { type },
    },
  } = props;
  const { getPageTheme } = useTheme<BackstageTheme>();
  const themeForType = getPageTheme({ themeId: type });

  const styles = useStyles({
    cardFontColor: themeForType.fontColor,
    cardBackgroundImage: themeForType.backgroundImage,
  });

  const SubtitleComponent = (
    <div className={styles.subtitleWrapper}>
      <div>{type}</div>
      <div>
        <FavoriteEntity entity={props.template} style={{ padding: 0 }} />
      </div>
    </div>
  );

  return <ItemCardHeader title={title ?? name} subtitle={SubtitleComponent} classes={{ root: styles.header }} />;
};
