import { IconComponent, useApp, useRouteRef } from '@backstage/core-plugin-api';
import { entityRouteRef } from '@backstage/plugin-catalog-react';
import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import WebIcon from '@material-ui/icons/Web';
import { parseEntityRef } from '@backstage/catalog-model';
import { Link } from '@backstage/core-components';
import { ScaffolderTaskOutput } from '../../../api';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
});

export const LinkOutputs = (props: { output: ScaffolderTaskOutput }) => {
  const { links = [] } = props.output;
  const classes = useStyles();
  const app = useApp();
  const entityRoute = useRouteRef(entityRouteRef);

  const iconResolver = (key?: string): IconComponent => app.getSystemIcon(key!) ?? WebIcon;

  return (
    <>
      {links
        .filter(({ url, entityRef }) => url || entityRef)
        .map(({ url, entityRef, title, icon, target }) => {
          if (entityRef) {
            const entityName = parseEntityRef(entityRef);
            return { title, icon, url: entityRoute(entityName), target };
          }
          return { title, icon, url: url!, target };
        })
        .map(({ url, title, icon, target }, i) => {
          const Icon = iconResolver(icon);
          return (
            <Link to={url} key={i} classes={{ root: classes.root }} target={target}>
              <Button startIcon={<Icon />} component="div" color="primary">
                {title}
              </Button>
            </Link>
          );
        })}
    </>
  );
};
