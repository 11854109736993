/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// @ts-check
// NOTE: This file is intentionally .jsx, so that there is one file in this repo where we make sure .jsx files work.

import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const LogoIcon = () => {
  return (
    <SvgIcon x="0px" y="0px" viewBox="0 0 1264 350" style={{
      width: 'auto',
      height: '28px'
    }}>
      <path
        fill="#FEC629"
        d="M85.57,303.27l132.19-74.09c0,0,9.07-4.13,21.89,2.79c11.63,6.28,36.28,20.68,40.96,23.8 c2.81,1.88,6.1,4.69,2.86,7.18c-2.84,2.19-108.64,62.49-130.45,74.91c-3.14,1.79-8.36,2.74-11.73,1.41 c-1.29-0.51-2.2-1.7-4.02-2.59c-9.11-4.45-47.97-28.34-47.97-28.34S85.2,306.68,85.57,303.27z"
      />
      <path
        fill="#01A5EE"
        d="M94.39,148.82l40.37,23.58c8.22,4.8,13.53,13.48,13.71,22.99c-0.12,11.66,0.33,140.49,0.34,141.06 c0,0,1.01,6.7-10.99,1.48c-9.33-4.07-38.21-22.1-49.53-29.62c-3.43-2.28-3.02-5.49-2.88-9.6c0.06-1.95,0.4-145,0.4-145 s-0.02-3.46,0.61-4.89C87.41,146.54,92.22,147.58,94.39,148.82z"
      />
      <path
        fill="#FEC629"
        d="M157.56,22.64l0.98,50.58c0,0,0.7,9.26,5.1,13.11c4.39,3.85,126.82,74.21,126.82,74.21s8.11,6.22,8.44-2.81 c0.34-9.04,2.94-50.86,0.94-55.82c-2-4.96-0.58-11.05-22.57-21.24c-21.99-10.19-112.81-64.72-112.81-64.72 S156.47,11.86,157.56,22.64z"
      />
      <path
        fill="#49CC2E"
        d="M175.91,165.66c-5,2.83-15.69,7.16-16.77,21.65c-0.43,13.27-0.25,39.78-0.2,45.69 c-0.2,4.19-1.19,15.33,12.11,7.77c12.94-7.62,99.69-57.06,116.43-66.6c8.6-5,13.22-14.88,13.63-28.13 c0.15-15.98-0.32-39.9-0.32-39.9s-0.05-13.17-8.8-8.4C264.73,112.59,186.23,159.49,175.91,165.66z"
      />
      <path
        fill="#FEC629"
        d="M12,237.73c0,0-1.16,13.36,6.37,8.67c5.78-3.6,30.89-18.58,42.26-25.36c5.82-3.47,10.41-8.73,12.79-15.07 c0.82-2.19,1.39-4.53,1.46-6.89c0.27-9.45-2.74-139.15-2.74-139.15s0.2-8.67-8.68-3.35C52.8,62.97,17.72,84,17.72,84 s-7.3-0.85-6.83,14.16C11.37,113.67,12,237.73,12,237.73z"
      />
      <path
        fill="#F26323"
        d="M134.65,159.24c0,0,12.85,10.16,25.23,4.33c12.37-5.83,45.97-26.09,45.97-26.09s8.93-3.56,1.51-8.3 S82.47,57.12,82.47,57.12s-9.37-6.8-21.24,0S15.06,84.34,15.06,84.34s-7.02,3.73,1.13,8.67 C27.78,100.04,134.65,159.24,134.65,159.24z"
      />
    </SvgIcon>
  );
};

export default LogoIcon;
