import { ThemeOptions } from '@mui/material/styles';
import colorSet from '../base/colors';
import { combinedComponents } from '../base/components';

/**
 * A helper for creating theme overrides.
 *
 * @public
 */
export const defaultComponentThemes: ThemeOptions['components'] = {
  MuiCssBaseline: {
    styleOverrides: theme => ({
      html: {
        height: '100%',
        fontFamily: theme.typography.fontFamily,
      },
      body: {
        height: '100%',
        fontFamily: theme.typography.fontFamily,
        overscrollBehaviorY: 'none',
        fontSize: '0.875rem',
        lineHeight: 1.43,
      },
      a: {
        color: 'inherit',
        textDecoration: 'none',
      },
    }),
  },
  MuiGrid: {
    defaultProps: {
      spacing: 2,
    },
  },
  MuiSwitch: {
    defaultProps: {
      color: 'primary',
    },
  },
  MuiTableRow: {
    styleOverrides: {
      // Alternating row backgrounds
      root: ({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.background.default,
        },
      }),
      // Use pointer for hoverable rows
      hover: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
      // Alternating head backgrounds
      head: ({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.background.paper,
        },
      }),
    },
  },
  // Tables are more dense than default mui tables
  MuiTableCell: {
    styleOverrides: {
      root: ({ theme }) => ({
        wordBreak: 'break-word',
        overflow: 'hidden',
        verticalAlign: 'middle',
        lineHeight: '1',
        margin: 0,
        padding: theme.spacing(3, 2, 3, 2.5),
        borderBottom: 0,
      }),
      sizeSmall: ({ theme }) => ({
        padding: theme.spacing(1.5, 2, 1.5, 2.5),
      }),
      head: ({ theme }) => ({
        wordBreak: 'break-word',
        overflow: 'hidden',
        color: theme.palette.textSubtle,
        fontWeight: 'normal',
        lineHeight: '1',
      }),
    },
  },
  MuiTableSortLabel: {
    styleOverrides: {
      // No color change on hover, just rely on the arrow showing up instead.
      root: {
        color: 'inherit',
        '&:hover': {
          color: 'inherit',
        },
        '&:focus': {
          color: 'inherit',
        },
        // Bold font for highlighting selected column
        '&.Mui-active': {
          fontWeight: 'bold',
          color: 'inherit',
        },
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      dense: {
        // Default dense list items to adding ellipsis for really long str...
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: ({ raised, theme }) => {
        const shadowColor = theme.palette.mode === 'dark' ? colorSet.atomicColors.black : colorSet.backgrounds.dark.contrastText;
        return {
          ...(raised
            ? {
                boxShadow: `0 0 1px ${shadowColor}`,
              }
            : {
                boxShadow: `0 10px 10px ${shadowColor}`,
              }),
          display: 'flex',
          flexDirection: 'column',
        };
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        // Reduce padding between header and content
        paddingBottom: 0,
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        // When cards have a forced size, such as when they are arranged in a
        // CSS grid, the content needs to flex such that the actions (buttons
        // etc) end up at the bottom of the card instead of just below the body
        // contents.
        flexGrow: 1,
        '&:last-child': {
          paddingBottom: undefined,
        },
      },
    },
  },
  MuiCardActions: {
    styleOverrides: {
      root: {
        // We default to putting the card actions at the end
        justifyContent: 'flex-end',
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: 'hover',
    },
  },
  ...combinedComponents,
};
