import { useApi } from '@backstage/core-plugin-api';
import React from 'react';
import { scaffolderApiRef } from '@backstage/plugin-scaffolder-react';
import useAsync from 'react-use/lib/useAsync';
import { parseEntityRef } from '@backstage/catalog-model';
import { EntityRefLink } from '@backstage/plugin-catalog-react';

export const TemplateTitleColumn = ({ entityRef }: { entityRef?: string }) => {
  const scaffolder = useApi(scaffolderApiRef);
  const { value, loading, error } = useAsync(() => scaffolder.getTemplateParameterSchema(entityRef || ''), [scaffolder, entityRef]);

  if (loading || error || !entityRef) {
    return null;
  }

  return <EntityRefLink entityRef={parseEntityRef(entityRef)} title={value?.title} />;
};
