import { Entity, CompoundEntityRef, parseEntityRef } from '@backstage/catalog-model';

// TODO(freben): This should be returning entity refs instead
/**
 * Get the related entity references.
 *
 * @public
 */
export function getEntityRelations(entity: Entity | undefined, relationType: string, filter?: { kind: string }): CompoundEntityRef[] {
  let entityNames = entity?.relations?.filter(r => r.type === relationType).map(r => parseEntityRef(r.targetRef)) || [];

  if (filter?.kind) {
    entityNames = entityNames.filter(e => e.kind.toLocaleLowerCase('en-US') === filter.kind.toLocaleLowerCase('en-US'));
  }

  return entityNames;
}
