import { useAnalytics } from '@backstage/core-plugin-api';
import React, { useEffect } from 'react';
import usePrevious from 'react-use/lib/usePrevious';
import { useSearch } from '../../context';

function useFallingEdge(next: boolean) {
  const prev = usePrevious(next);
  return prev && !next;
}

/**
 * Capture search event on term change.
 */
export const TrackSearch = ({ children }: { children: React.ReactChild }) => {
  const analytics = useAnalytics();
  const { term, result } = useSearch();

  const numberOfResults = result.value?.numberOfResults ?? undefined;

  // Stops the analtyics event from firing before the new search engine response is returned
  const hasFinishedLoading = useFallingEdge(result.loading);

  useEffect(() => {
    if (term && hasFinishedLoading) {
      // Capture analytics search event with search term and numberOfResults (provided as value)
      analytics.captureEvent('search', term, {
        value: numberOfResults,
      });
    }
  }, [analytics, term, numberOfResults, hasFinishedLoading]);

  return <>{children}</>;
};
