import { StreamLanguage } from '@codemirror/language';
import { yaml as yamlSupport } from '@codemirror/legacy-modes/mode/yaml';
import { showPanel } from '@codemirror/view';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import { BackstageTheme } from '@backstage/theme';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';
import { useTheme } from '@mui/material/styles';
import { useKeyboardEvent } from '@react-hookz/web';
import CodeMirror from '@uiw/react-codemirror';
import React, { useMemo } from 'react';
import { useDirectoryEditor } from './DirectoryEditorContext';

const useStyles = makeStyles(() => {
  const theme = useTheme<BackstageTheme>();
  return {
    container: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    codeMirror: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    errorPanel: {
      color: theme.palette.error.main,
      lineHeight: 2,
      margin: theme.spacing(0, 1),
    },
    floatingButtons: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(3),
    },
    floatingButton: {
      padding: theme.spacing(1),
    },
  };
});

/** A wrapper around CodeMirror with an error panel and extra actions available */
export function TemplateEditorTextArea(props: { content?: string; onUpdate?: (content: string) => void; errorText?: string; onSave?: () => void; onReload?: () => void }) {
  const { errorText } = props;
  const classes = useStyles();

  const panelExtension = useMemo(() => {
    if (!errorText) {
      return showPanel.of(null);
    }

    const dom = document.createElement('div');
    dom.classList.add(classes.errorPanel);
    dom.textContent = errorText;
    return showPanel.of(() => ({ dom, bottom: true }));
  }, [classes, errorText]);

  useKeyboardEvent(
    e => e.key === 's' && (e.ctrlKey || e.metaKey),
    e => {
      e.preventDefault();
      if (props.onSave) {
        props.onSave();
      }
    },
  );

  return (
    <div className={classes.container}>
      <CodeMirror
        className={classes.codeMirror}
        theme="dark"
        height="100%"
        extensions={[StreamLanguage.define(yamlSupport), panelExtension]}
        value={props.content}
        onChange={props.onUpdate}
      />
      {(props.onSave || props.onReload) && (
        <div className={classes.floatingButtons}>
          <Paper>
            {props.onSave && (
              <Tooltip title="Save file">
                <IconButton className={classes.floatingButton} onClick={() => props.onSave?.()} size="large">
                  <SaveIcon />
                </IconButton>
              </Tooltip>
            )}
            {props.onReload && (
              <Tooltip title="Reload file">
                <IconButton className={classes.floatingButton} onClick={() => props.onReload?.()} size="large">
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            )}
          </Paper>
        </div>
      )}
    </div>
  );
}

/** A version of the TemplateEditorTextArea that is connected to the DirectoryEditor context */
export function TemplateEditorDirectoryEditorTextArea(props: { errorText?: string }) {
  const directoryEditor = useDirectoryEditor();

  const actions = directoryEditor.selectedFile?.dirty
    ? {
        onSave: () => directoryEditor.save(),
        onReload: () => directoryEditor.reload(),
      }
    : {
        onReload: () => directoryEditor.reload(),
      };

  return (
    <TemplateEditorTextArea
      errorText={props.errorText}
      content={directoryEditor.selectedFile?.content}
      onUpdate={content => directoryEditor.selectedFile?.updateContent(content)}
      {...actions}
    />
  );
}

TemplateEditorTextArea.DirectoryEditor = TemplateEditorDirectoryEditorTextArea;
