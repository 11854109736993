import React from 'react';

interface IPaginationBoldLabel {
  from: number;
  to: number;
  count: number;
}

export const PaginationBoldLabel = ({ from, to, count }: IPaginationBoldLabel) => {
  return (
    <>
      Showing:{' '}
      <b>
        {from}–{to}
      </b>{' '}
      of <b>{count !== -1 ? count : `more than ${to}`}</b>
    </>
  );
};
