import React, { Fragment, ReactElement } from 'react';
import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles';
import startCase from 'lodash/startCase';
import Typography from '@material-ui/core/Typography';
import yaml from 'js-yaml';

import { MetadataTable, MetadataTableItem, MetadataList, MetadataListItem } from './MetadataTable';

export type StructuredMetadataTableListClassKey = 'root';

const listStyle = createStyles({
  root: {
    margin: '0 0',
    listStyleType: 'none',
  },
});

export type StructuredMetadataTableNestedListClassKey = 'root';

const nestedListStyle = (theme: Theme) =>
  createStyles({
    root: {
      ...listStyle.root,
      paddingLeft: theme.spacing(1),
    },
  });

interface StyleProps extends WithStyles {
  children?: React.ReactNode;
}
// Sub Components
const StyledList = withStyles(listStyle, {
  name: 'BackstageStructuredMetadataTableList',
})(({ classes, children }: StyleProps) => <MetadataList classes={classes}>{children}</MetadataList>);
const StyledNestedList = withStyles(nestedListStyle, {
  name: 'BackstageStructuredMetadataTableNestedList',
})(({ classes, children }: StyleProps) => <MetadataList classes={classes}>{children}</MetadataList>);

function renderList(list: Array<any>, nested?: boolean) {
  const values = list.map((item: any, index: number) => <MetadataListItem key={index}>{toValue(item)}</MetadataListItem>);
  return nested ? <StyledNestedList>{values}</StyledNestedList> : <StyledList>{values}</StyledList>;
}

function renderMap(map: { [key: string]: any }, nested?: boolean, options?: any) {
  const values = Object.keys(map).map(key => {
    const value = toValue(map[key], true);
    const fmtKey = options && options.titleFormat ? options.titleFormat(key) : startCase(key);
    return (
      <MetadataListItem key={key}>
        <Typography variant="body2" component="span">
          {`${fmtKey}: `}
        </Typography>
        {value}
      </MetadataListItem>
    );
  });

  return nested ? <StyledNestedList>{values}</StyledNestedList> : <StyledList>{values}</StyledList>;
}

function toValue(value: ReactElement | object | Array<any> | boolean, options?: any, nested?: boolean) {
  if (React.isValidElement(value)) {
    return <Fragment>{value}</Fragment>;
  }

  if (typeof value === 'object' && !Array.isArray(value)) {
    return renderMap(value, options, nested);
  }

  if (Array.isArray(value)) {
    return renderList(value, nested);
  }

  if (typeof value === 'boolean') {
    return <Fragment>{value ? '✅' : '❌'}</Fragment>;
  }

  const customizer = options?.customizeValues || customizeValues;
  return (
    <Typography variant="body2" component="span">
      {customizer(value)}
    </Typography>
  );
}

const ItemValue = ({ value, options }: { value: any; options: any }) => <Fragment>{toValue(value, options)}</Fragment>;

const TableItem = ({ title, value, options }: { title: string; value: any; options: any }) => {
  return (
    <MetadataTableItem title={options && options.titleFormat ? options.titleFormat(title) : startCase(title)}>
      <ItemValue value={value} options={options} />
    </MetadataTableItem>
  );
};

function mapToItems(info: { [key: string]: string }, options: any) {
  return Object.keys(info).map(key => <TableItem key={key} title={key} value={info[key]} options={options} />);
}

type Props = {
  metadata: { [key: string]: any };
  dense?: boolean;
  options?: any;
};

export function StructuredMetadataTable(props: Props) {
  const { metadata, dense = true, options } = props;
  const metadataItems = mapToItems(metadata, options || {});
  return <MetadataTable dense={dense}>{metadataItems}</MetadataTable>;
}

// temporary crunch
// todo: extend StructuredMetadataTable, add ability to customize output on preview or add formatters
type Value = ReactElement | object | Array<any> | boolean | string;

function getPrettyYaml(input: string) {
  try {
    const validYaml = yaml.load(input);
    return validYaml instanceof Object ? <pre>{input}</pre> : false;
  } catch (e) {
    return false;
  }
}

function getPrettyJson(input: string) {
  try {
    const obj = JSON.parse(input);
    return <pre>{JSON.stringify(obj, null, 2)}</pre>;
  } catch (e) {
    return false;
  }
}

function customizeValues(value: Value): Value {
  if (typeof value !== 'string') {
    return value;
  }
  switch (true) {
    case value.startsWith('base64,'): {
      return <Typography>Base64 content</Typography>;
    }
    default: {
      return getPrettyJson(value) || getPrettyYaml(value) || value;
    }
  }
}
