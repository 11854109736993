import { FieldValidation } from '@rjsf/core';
import { KubernetesValidatorFunctions } from '@backstage/catalog-model';
import { EntityNamePickerProps } from './schema';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import _ from 'lodash';

export const entityNamePickerValidation = async (value: string, validation: FieldValidation, context: EntityNamePickerProps) => {
  if (!KubernetesValidatorFunctions.isValidObjectName(value)) {
    validation.addError(
      'Must start and end with an alphanumeric character, and contain only alphanumeric characters, hyphens, underscores, and periods. Maximum length is 63 characters.',
    );
  }
  const componentKind = (context?.schema as any)?.componentKind;
  // componentKind property added as the value and at the same time as the marker of the Entity name validation
  if (componentKind) {
    try {
      const entity = await context.apiHolder.get(catalogApiRef).getEntityByRef({
        name: value,
        namespace: 'default',
        kind: componentKind,
      });

      if (entity) {
        validation.addError(
          `${componentKind} with name "${value}" is already in use. To proceed, please provide a distinct name for your ${componentKind.toLocaleLowerCase('en-US')}.`,
        );
      }
    } catch (error) {
      // console.error(error);
    }
  }
};
