import { z } from 'zod';
import { makeFieldSchemaFromZod } from '../utils';

/**
 * Field schema for the MyGroupsPicker.
 * @public
 */

export const MyGroupsPickerFieldSchema = makeFieldSchemaFromZod(
  z.string(),
  z.object({
    title: z.string().default('Group').describe('Group'),
    description: z.string().default('A group you are part of').describe('The group to which the entity belongs'),
  }),
);

/**
 * UI options for the MyGroupsPicker.
 * @public
 */

export type MyGroupsPickerUiOptions = typeof MyGroupsPickerFieldSchema.uiOptionsType;
/**
 * Props for the MyGroupsPicker.
 * @public
 */

export type MyGroupsPickerProps = typeof MyGroupsPickerFieldSchema.type;

/**
 * Schema for the MyGroupsPicker.
 * @public
 */

export const MyGroupsPickerSchema = MyGroupsPickerFieldSchema.schema;
