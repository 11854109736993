import { PaletteColor } from '@mui/material/styles';

interface ColorSet {
  primary: PaletteColor;
  system: GroupedColorSet;
  backgrounds: GroupedColorSet;
}

interface GroupedColorSet {
  [key: string]: PaletteColor;
}

const colorSet: ColorSet = {
  primary: {
    main: '#3E7BFA',
    dark: '#3568D4',
    light: '#6698FA',
    contrastText: '#CCDDFF',
  },
  system: {
    red: {
      main: '#FF5C5C',
      dark: '#FF3B3B',
      light: '#FF8080',
      contrastText: '#FFE5E5',
    },
    green: {
      main: '#39D98A',
      dark: '#06C270',
      light: '#57EBA1',
      contrastText: '#E3FFF1',
    },
    blue: {
      main: '#5B8DEF',
      dark: '#0063F7',
      light: '#9DBFF9',
      contrastText: '#E5F0FF',
    },
    orange: {
      main: '#FDAC42',
      dark: '#FF8800',
      light: '#FCCC75',
      contrastText: '#FFF8E5',
    },
    yellow: {
      main: '#FDDD48',
      dark: '#FFCC00',
      light: '#FDED72',
      contrastText: '#FFFEE5',
    },
    teal: {
      main: '#73DFE7',
      dark: '#00CFDE',
      light: '#A9EFF2',
      contrastText: '#E5FFFF',
    },
    purple: {
      main: '#AC5DD9',
      dark: '#6600CC',
      light: '#DDA5E9',
      contrastText: '#FFE5FF',
    },
  },
  backgrounds: {
    light: {
      main: '#FFFFFF',
      dark: '#EBEBF0',
      light: '#F2F2F5',
      contrastText: '#FAFAFC',
    },
    dark: {
      main: '#28293D',
      dark: '#1C1C28',
      light: '#555770',
      contrastText: '#8F90A6',
    },
  },
};

export default colorSet;
