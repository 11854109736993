import { Entity } from '@backstage/catalog-model';
import { CodeSnippet } from '@backstage/core-components';
import DialogContentText from '@mui/material/DialogContentText';
import React from 'react';
import YAML from 'yaml';
import { sortKeys } from './util';

export function YamlPage(props: { entity: Entity }) {
  return (
    <>
      <DialogContentText variant="h2">Entity as YAML</DialogContentText>
      <DialogContentText>This is the raw entity data as received from the catalog, on YAML form.</DialogContentText>
      <DialogContentText>
        <div style={{ fontSize: '75%' }} data-testid="code-snippet">
          <CodeSnippet text={YAML.stringify(sortKeys(props.entity))} language="yaml" showCopyCodeButton />
        </div>
      </DialogContentText>
    </>
  );
}
