import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';

export const platformToolsPlugin = createPlugin({
  id: 'platform-tools',
  routes: {
    root: rootRouteRef,
  },
});

export const PlatformToolsPage = platformToolsPlugin.provide(
  createRoutableExtension({
    name: 'PlatformToolsPage',
    component: () => import('./components/ToolsPageComponent').then(m => m.ToolsPageComponent),
    mountPoint: rootRouteRef,
  }),
);
