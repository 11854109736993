import { Grid, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import { GithubRepoPicker } from './GithubRepoPicker';
import { GitlabRepoPicker } from './GitlabRepoPicker';
import { AzureRepoPicker } from './AzureRepoPicker';
import { BitbucketRepoPicker } from './BitbucketRepoPicker';
import { GerritRepoPicker } from './GerritRepoPicker';
import { RepoUrlPickerHost } from './RepoUrlPickerHost';
import { RepoUrlPickerRepoName } from './RepoUrlPickerRepoName';
import { serializeRepoPickerUrl } from './utils';
import { RepoUrlPickerProps } from './schema';
import { useRepoUrlPickerCommonContext } from './useRepoUrlPickerCommonContext';

export { RepoUrlPickerSchema } from './schema';

/**
 * The underlying component that is rendered in the form for the `RepoUrlPicker`
 * field extension.
 *
 * @public
 */
export const RepoUrlPicker = (props: RepoUrlPickerProps) => {
  const { onChange, rawErrors } = props;
  const { allowedHosts, allowedOrganizations, allowedOwners, allowedProjects, allowedRepos, disableInputs, hostType, updateLocalState, state, setState } =
    useRepoUrlPickerCommonContext(props);

  useEffect(() => {
    onChange(serializeRepoPickerUrl(state));
  }, [state, onChange]);

  const getOwnerInput = () => {
    switch (hostType) {
      case 'github': {
        return <GithubRepoPicker allowedOwners={allowedOwners} onChange={updateLocalState} rawErrors={rawErrors} state={state} disabled={disableInputs} />;
      }
      case 'gitlab': {
        return <GitlabRepoPicker allowedOwners={allowedOwners} rawErrors={rawErrors} state={state} onChange={updateLocalState} disabled={disableInputs} />;
      }
      case 'bitbucket': {
        return <BitbucketRepoPicker allowedOwners={allowedOwners} allowedProjects={allowedProjects} rawErrors={rawErrors} state={state} onChange={updateLocalState} />;
      }
      case 'azure': {
        return <AzureRepoPicker allowedOrganizations={allowedOrganizations} allowedOwners={allowedOwners} rawErrors={rawErrors} state={state} onChange={updateLocalState} />;
      }
      case 'gerrit': {
        return <GerritRepoPicker rawErrors={rawErrors} state={state} onChange={updateLocalState} />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Grid container component={Paper}>
      <Grid item xs={12} sm={6}>
        <RepoUrlPickerHost host={state.host} hosts={allowedHosts} onChange={host => setState(prevState => ({ ...prevState, host }))} rawErrors={rawErrors} />
      </Grid>
      <Grid item xs={12} sm={6}>
        {getOwnerInput()}
      </Grid>
      <Grid item xs={12}>
        <RepoUrlPickerRepoName
          repoName={state.repoName}
          allowedRepos={allowedRepos}
          onChange={repo => setState(prevState => ({ ...prevState, repoName: repo }))}
          rawErrors={rawErrors}
          disabled={disableInputs}
        />
      </Grid>
    </Grid>
  );
};
