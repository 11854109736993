import { createApiRef, DiscoveryApi, ConfigApi } from '@backstage/core-plugin-api';
import { Entity, Workflow } from './types';

export interface ArgoWorkflowsApi {
  workflowsForEntity(entityKind: string, entityName: string): Promise<Workflow[]>;
}

export const argoWorkflowsApiRef = createApiRef<ArgoWorkflowsApi>({
  id: 'plugin.workflow.service',
});

export type Options = {
  discoveryApi: DiscoveryApi;
  configApi: ConfigApi;
  proxyPath?: string;
};

const LOCAL_TENANT_ID = 'bkt-local-app';
const DEFAULT_PROXY_PATH = '/argo-workflow/api';

class Client {
  private readonly discoveryApi: DiscoveryApi;
  private readonly configApi: ConfigApi;
  private readonly proxyPath: string;

  constructor(options: Options) {
    this.discoveryApi = options.discoveryApi;
    this.configApi = options.configApi;
    this.proxyPath = options.proxyPath ?? DEFAULT_PROXY_PATH;
  }

  public async fetch<T = any>(input: string, init?: RequestInit): Promise<T> {
    const apiUrl = await this.apiUrl();
    const resp = await fetch(`${apiUrl}${input}`, init);
    if (!resp.ok) {
      throw new Error(`Request failed with ${resp.status} ${resp.statusText}`);
    }

    return await resp.json();
  }

  async listWorkflowsForEntity(entity: Entity): Promise<Workflow[]> {
    const tenantId = await this.getTenantId();
    const response = await this.fetch<any>(
      `/workflows/${tenantId}?listOptions.labelSelector=tmatic.io/argo-workflow-entity-name=${entity.name},tmatic.io/argo-workflow-entity-kind=${entity.kind}`,
    );
    return response.items ?? [];
  }

  async listArchivedWorkflowsForEntity(entity: Entity): Promise<Workflow[]> {
    const tenantId = await this.getTenantId();
    const response = await this.fetch<any>(
      `/archived-workflows?namespace=${tenantId}&listOptions.labelSelector=tmatic.io/argo-workflow-entity-name=${entity.name},tmatic.io/argo-workflow-entity-kind=${entity.kind}`,
    );
    return response.items ?? [];
  }

  private async apiUrl() {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    return proxyUrl + this.proxyPath;
  }

  private async getTenantId(): Promise<string> {
    const backendUrl = this.configApi.get('backend.baseUrl');
    const resp = await fetch(`${backendUrl}/api/configurator/infoWithTenant`).then(res => res.json());
    return resp.tenantId ?? LOCAL_TENANT_ID;
  }
}

export class ArgoWorkflowApiClient implements ArgoWorkflowsApi {
  private readonly client: Client;

  constructor(opts: Options) {
    this.client = new Client(opts);
  }

  async workflowsForEntity(entityKind: string, entityName: string): Promise<Workflow[] | []> {
    const workflows = await this.client.listWorkflowsForEntity({ name: entityName, kind: entityKind });
    const archivedWorkflows = await this.client.listArchivedWorkflowsForEntity({ name: entityName, kind: entityKind });
    const mergedArray = this.mergeArraysAndIgnoreDuplicates(archivedWorkflows, workflows);

    return mergedArray ?? [];
  }

  private mergeArraysAndIgnoreDuplicates(arr1: Workflow[], arr2: Workflow[]): Workflow[] {
    const mergedMap = new Map<number | string, Workflow>();

    // Add elements from the first array
    arr1.forEach(item => {
      const keyValue = item.metadata.uid;
      mergedMap.set(keyValue, item);
    });

    // Add elements from the second array, ignoring duplicates
    arr2.forEach(item => {
      const keyValue = item.metadata.uid;
      if (!mergedMap.has(keyValue)) {
        mergedMap.set(keyValue, item);
      }
    });

    // Convert the map values back to an array
    const mergedArray: Workflow[] = Array.from(mergedMap.values());

    mergedArray.sort((a, b) => (a.status.startedAt < b.status.startedAt ? 1 : -1));

    return mergedArray;
  }
}
