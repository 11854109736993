import React from 'react';
import { PaginationBoldLabel } from './ComponentsLibrary';
import { Components } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import colors from '../colors';

export const Table: Pick<Components, 'MuiTable' | 'MuiTableHead' | 'MuiTablePagination' | 'MuiTableRow' | 'MuiTableContainer'> = {
  MuiTableContainer: {
    defaultProps: {
      component: Paper,
    },
  },
  MuiTable: {
    defaultProps: {
      size: 'small',
    },
    styleOverrides: {
      root: ({ theme }) => ({
        '.v5-MuiButtonBase-root svg': {
          fill: theme.palette.mode === 'light' ? colors.backgrounds.dark.dark : colors.backgrounds.light.main,
        },
        '.v5-MuiButtonBase-root.Mui-disabled svg': {
          fill: theme.palette.mode === 'light' ? colors.backgrounds.dark.contrastText : colors.backgrounds.dark.light,
        },
      }),
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: ({ theme }) => ({
        th: {
          backgroundColor: theme.palette.mode === 'light' ? colors.backgrounds.light.main : colors.atomicColors.darkBackground,
          color: theme.palette.mode === 'light' ? colors.backgrounds.dark.dark : colors.backgrounds.light.main,
          fontWeight: 700,
        },
      }),
    },
  },
  MuiTablePagination: {
    defaultProps: {
      labelDisplayedRows: function defaultLabelDisplayedRows({ from, to, count }) {
        return <PaginationBoldLabel from={from} to={to} count={count} />;
      },
    },
    styleOverrides: {
      root: {
        width: '99.9%',
        clear: 'both',
      },
      toolbar: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
      },
      spacer: {
        order: 1,
        display: 'none',
      },
      displayedRows: {
        order: 2,
        marginRight: '2rem',
      },
      selectLabel: {
        order: 3,
      },
      selectRoot: {
        order: 4,
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: ({ theme }) => ({
        border: `1px solid #E4E4EB`,
        backgroundColor: theme.palette.mode === 'light' ? colors.backgrounds.light.main : colors.atomicColors.darkBackground,
        color: theme.palette.mode === 'light' ? colors.backgrounds.dark.dark : colors.backgrounds.light.main,
      }),
    },
  },
};
