import { KebabToSnakeCase } from '@tmatic/utils';

export type Tools = 'argo-cd' | 'ci';
export type ToolProcessingStatus = 'running' | 'succeeded' | 'failed' | 'processing';

export interface Tool {
  name: Tools;
  description: string;
  on: boolean;
  off: boolean;
}

export const TOOLS_MAP: Record<Uppercase<KebabToSnakeCase<Tools>>, Tool> = {
  ARGO_CD: {
    name: 'argo-cd',
    description: 'Run ARGO CD',
    on: true,
    off: false,
  },
  CI: {
    name: 'ci',
    description: 'Enable CI',
    on: true,
    off: true,
  },
};

export const TOOLS: Tool[] = Object.values(TOOLS_MAP);
