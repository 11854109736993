import Box from '@material-ui/core/Box';
import MaterialBreadcrumbs from '@material-ui/core/Breadcrumbs';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { ComponentProps, Fragment } from 'react';

type Props = ComponentProps<typeof MaterialBreadcrumbs>;

/** @public */
export type BreadcrumbsClickableTextClassKey = 'root';

const ClickableText = withStyles(
  {
    root: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  { name: 'BackstageBreadcrumbsClickableText' },
)(Typography);

/** @public */
export type BreadcrumbsStyledBoxClassKey = 'root';

const StyledBox = withStyles(
  {
    root: {
      textDecoration: 'underline',
      color: 'inherit',
    },
  },
  { name: 'BackstageBreadcrumbsStyledBox' },
)(Box);

/**
 * Breadcrumbs component to show navigation hierarchical structure
 *
 * @public
 *
 */
export function Breadcrumbs(props: Props) {
  const { children, ...restProps } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const childrenArray = React.Children.toArray(children);

  const [firstPage, secondPage, ...expandablePages] = childrenArray;
  const currentPage = expandablePages.length ? expandablePages.pop() : childrenArray[childrenArray.length - 1];
  const hasHiddenBreadcrumbs = childrenArray.length > 3;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Fragment>
      <MaterialBreadcrumbs aria-label="breadcrumb" {...restProps}>
        {childrenArray.length > 1 && <StyledBox clone>{firstPage}</StyledBox>}
        {childrenArray.length > 2 && <StyledBox clone>{secondPage}</StyledBox>}
        {hasHiddenBreadcrumbs && <ClickableText onClick={handleClick}>...</ClickableText>}
        <Box style={{ fontStyle: 'italic' }}>{currentPage}</Box>
      </MaterialBreadcrumbs>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List>
          {expandablePages.map((pageLink, index) => (
            <ListItem key={index} button>
              <StyledBox clone>{pageLink}</StyledBox>
            </ListItem>
          ))}
        </List>
      </Popover>
    </Fragment>
  );
}
