import { exploreToolsConfigRef } from '@backstage/plugin-explore-react';
import { catalogEntityRouteRef, exploreRouteRef } from './routes';
import { createApiFactory, createPlugin, discoveryApiRef, fetchApiRef } from '@backstage/core-plugin-api';
import { createSearchResultListItemExtension, SearchResultListItemExtensionProps } from '@backstage/plugin-search-react';
import { ExploreClient, exploreApiRef } from './api';
import { ToolSearchResultListItemProps } from './components/ToolSearchResultListItem';
// import { exampleTools } from './util/examples';

/** @public */
export const explorePlugin = createPlugin({
  id: 'explore',
  apis: [
    createApiFactory({
      api: exploreApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
        exploreToolsConfig: exploreToolsConfigRef,
      },
      factory: ({ discoveryApi, fetchApi, exploreToolsConfig }) =>
        // NOTE: The exploreToolsConfig is for backwards compatibility and will be removed in the future
        new ExploreClient({ discoveryApi, fetchApi, exploreToolsConfig }),
    }),
    /**
     * @deprecated Use ExploreApi from `@backstage/plugin-explore` instead
     *
     * Register a default for exploreToolsConfigRef, you may want to override
     * the API locally in your app.
     */
    createApiFactory({
      api: exploreToolsConfigRef,
      deps: {},
      factory: () => ({
        async getTools() {
          // Returning `undefined` will enable the explore-backend to be used via the ExploreClient.
          // If this API has been customized and returns data it will be respected first.
          return undefined as any;
        },
      }),
    }),
  ],
  routes: {
    explore: exploreRouteRef,
  },
  externalRoutes: {
    catalogEntity: catalogEntityRouteRef,
  },
});

/** @public */
export const ToolSearchResultListItem: (props: SearchResultListItemExtensionProps<ToolSearchResultListItemProps>) => JSX.Element | null = explorePlugin.provide(
  createSearchResultListItemExtension({
    name: 'ToolSearchResultListItem',
    component: () => import('./components/ToolSearchResultListItem').then(m => m.ToolSearchResultListItem),
    predicate: result => result.type === 'tools',
  }),
);
