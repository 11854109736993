import { Link } from '@backstage/core-components';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import MuiListItemText from '@mui/material/ListItemText';
import MuiListSubheader from '@mui/material/ListSubheader';
import makeStyles from '@mui/styles/makeStyles';
import { BackstageTheme } from '@backstage/theme';
import { useTheme } from '@mui/material/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React from 'react';

const useStyles = makeStyles(() => {
  const theme = useTheme<BackstageTheme>();
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    marginTop: {
      marginTop: theme.spacing(2),
    },
    helpIcon: {
      marginLeft: theme.spacing(1),
      color: theme.palette.text.disabled,
    },
    monospace: {
      fontFamily: 'monospace',
    },
  };
});

export function ListItemText(props: { primary: React.ReactNode; secondary?: React.ReactNode }) {
  const classes = useStyles();
  return <MuiListItemText {...props} primaryTypographyProps={{ className: classes.monospace }} secondaryTypographyProps={{ className: classes.monospace }} />;
}

export function ListSubheader(props: { children?: React.ReactNode }) {
  const classes = useStyles();
  return <MuiListSubheader className={classes.monospace}>{props.children}</MuiListSubheader>;
}

export function Container(props: { title: React.ReactNode; helpLink?: string; children: React.ReactNode }) {
  return (
    <Box mt={2}>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {props.title}
            {props.helpLink && <HelpIcon to={props.helpLink} />}
          </Typography>
          {props.children}
        </CardContent>
      </Card>
    </Box>
  );
}

// Extracts a link from a value, if possible
function findLink(value: string): string | undefined {
  if (value.match(/^url:https?:\/\//)) {
    return value.slice('url:'.length);
  }
  if (value.match(/^https?:\/\//)) {
    return value;
  }
  return undefined;
}

export function KeyValueListItem(props: { indent?: boolean; entry: [string, string] }) {
  const [key, value] = props.entry;
  const link = findLink(value);

  return (
    <ListItem>
      {props.indent && <ListItemIcon />}
      <ListItemText primary={key} secondary={link ? <Link to={link}>{value}</Link> : value} />
    </ListItem>
  );
}

export function HelpIcon(props: { to: string }) {
  const classes = useStyles();
  return (
    <Link to={props.to} className={classes.helpIcon}>
      <HelpOutlineIcon fontSize="inherit" />
    </Link>
  );
}
