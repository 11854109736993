import { isAxiosError } from 'axios';
import util from 'util';

export class HttpError extends Error {
  // @ts-ignore
  private status: number;
  // @ts-ignore
  private context?: any;

  constructor(status: number, message: string, context?: any) {
    super(message);
    this.status = status;
    this.context = context;
  }

  static fromExternalError(error: unknown): HttpError {
    if (isAxiosError(error)) {
      return new HttpError(error.response?.status || error.status || 500, error.response?.statusText || error.message);
    } else if (error instanceof Error) {
      return new HttpError((error as any).status || 500, error.message, { origin: util.format(error) });
    }
    return new HttpError(500, 'Unknown error occurred', { origin: util.format(error) });
  }
}
