import { Progress, Select, SelectItem } from '@backstage/core-components';
import { BackstageTheme } from '@backstage/theme';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import _ from 'lodash';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import useAsync from 'react-use/lib/useAsync';
import { CustomFormLabel } from '../../CustomFormLabel';
import { AddClusterToControlPlaneModalProps } from './types';

const useStyles = makeStyles(() => {
  const theme = useTheme<BackstageTheme>();
  return {
    modal: {
      justifyContent: 'center',
      [theme.breakpoints.up('xs')]: {
        minWidth: '480px',
      },
      [theme.breakpoints.up('md')]: {
        minWidth: '640px',
      },
      [theme.breakpoints.up('lg')]: {
        minWidth: '800px',
      },
    },
  };
});

export const AddClusterToControlPlaneModal = (props: AddClusterToControlPlaneModalProps) => {
  const classes = useStyles();
  const { open, title = 'Add new', toggleModal, onSubmit, existingClustersList } = props;
  const [form, setForm] = useState<{ clusterName: string }>({
    clusterName: '',
  });

  const [loading, setLoading] = useState(false);

  const catalogApi = useApi(catalogApiRef);
  const { value: entities } = useAsync(async () => {
    const { items } = await catalogApi.getEntities({ filter: { kind: 'Resource', 'spec.type': 'k8s-cluster' } });
    return items;
  }, []);

  const dict = _.keyBy(existingClustersList, 'clusterName');
  const selectItems = _.reduce(
    entities,
    (acc: SelectItem[], item) => {
      if (!dict[item.metadata.name]) {
        acc.push({ label: item.metadata.name, value: item.metadata.name });
      }
      return acc;
    },
    [],
  );

  return (
    <Dialog style={{ padding: '2rem' }} onClose={toggleModal} aria-labelledby="search-modal-title" maxWidth="md" open={!!open}>
      {loading && <Progress />}
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.modal}>
        <Grid style={{ backgroundImage: 'none', boxShadow: 'none' }} container component={Paper} paddingX={2}>
          <Grid item xs={12}>
            <Select
              label={(<CustomFormLabel text="Cluster Name *" pull="right" />) as unknown as string}
              items={selectItems || []}
              onChange={value => setForm({ clusterName: _.toString(value) })}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: '0 2rem 2rem 2rem' }}>
        <Button variant="contained" onClick={toggleModal} color="secondary" disabled={loading}>
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setLoading(true);
            setForm({ clusterName: '' });
            onSubmit(form).then(() => {
              setLoading(false);
              toggleModal();
            });
          }}
          disabled={_.isEmpty(form.clusterName) || loading}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
