import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { EmptyStateImage } from './EmptyStateImage';

/** @public */
export type EmptyStateClassKey = 'root' | 'action' | 'imageContainer';

const useStyles = makeStyles(
  theme => ({
    root: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2, 0, 0, 0),
    },
    action: {
      marginTop: theme.spacing(2),
    },
    imageContainer: {
      position: 'relative',
    },
  }),
  { name: 'BackstageEmptyState' },
);

type Props = {
  title: string;
  description?: string | JSX.Element;
  missing: 'field' | 'info' | 'content' | 'data' | { customImage: JSX.Element };
  action?: JSX.Element;
};

/**
 * Various placeholder views for empty state pages
 *
 * @public
 *
 */
export function EmptyState(props: Props) {
  const { title, description, missing, action } = props;
  const classes = useStyles();
  return (
    <Grid container direction="row" justifyContent="space-around" alignItems="flex-start" className={classes.root} spacing={2}>
      <Grid item xs={12} md={6}>
        <Grid container direction="column">
          <Grid item xs>
            <Typography variant="h5">{title}</Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body1">{description}</Typography>
          </Grid>
          {/* <Grid item xs className={classes.action}>
            {action}
          </Grid> */}
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} className={classes.imageContainer}>
        {typeof missing === 'string' ? <EmptyStateImage missing={missing} /> : missing.customImage}
      </Grid>
    </Grid>
  );
}
