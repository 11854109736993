import React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { settingsRouteRef } from '../plugin';
import { SidebarItem } from '@backstage/core-components';
import { useRouteRef, IconComponent } from '@backstage/core-plugin-api';

/** @public */
export const Settings = (props: { icon?: IconComponent }) => {
  const routePath = useRouteRef(settingsRouteRef);
  const Icon = props.icon ? props.icon : SettingsIcon;
  return <SidebarItem text="Settings" to={routePath()} icon={Icon} />;
};
