import { FormProps, withTheme } from '@rjsf/core-v5';
import React from 'react';
import { PropsWithChildren } from 'react';
import { FieldTemplate } from './FieldTemplate';
import { DescriptionFieldTemplate } from './DescriptionFieldTemplate';

// TODO(blam): We require here, as the types in this package depend on @rjsf/core explicitly
// which is what we're using here as the default types, it needs to depend on @rjsf/core-v5 because
// of the re-writing we're doing. Once we've migrated, we can import this the exact same as before.
const WrappedForm = withTheme(require('@rjsf/material-ui-v5').Theme);

/**
 * The Form component
 * @alpha
 */
export const Form = (props: PropsWithChildren<FormProps>) => {
  const templates = {
    FieldTemplate,
    DescriptionFieldTemplate,
    ...props.templates,
  };
  return <WrappedForm {...props} templates={templates} />;
};
