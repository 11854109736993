import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { RepoUrlPickerState } from './types';
import { Select, SelectItem } from '@backstage/core-components';

export const AzureRepoPicker = (props: {
  allowedOrganizations?: string[];
  allowedOwners?: string[];
  rawErrors: string[];
  state: RepoUrlPickerState;
  onChange: (state: RepoUrlPickerState) => void;
}) => {
  const { allowedOrganizations = [], allowedOwners = [], rawErrors, state, onChange } = props;

  const organizationItems: SelectItem[] = allowedOrganizations ? allowedOrganizations.map(i => ({ label: i, value: i })) : [{ label: 'Loading...', value: 'loading' }];

  const ownerItems: SelectItem[] = allowedOwners ? allowedOwners.map(i => ({ label: i, value: i })) : [{ label: 'Loading...', value: 'loading' }];

  const { organization, owner } = state;

  return (
    <>
      <FormControl margin="normal" required error={rawErrors?.length > 0 && !organization}>
        {allowedOrganizations?.length ? (
          <Select
            native
            label="Organization"
            onChange={s => onChange({ organization: String(Array.isArray(s) ? s[0] : s) })}
            disabled={allowedOrganizations.length === 1}
            selected={organization}
            items={organizationItems}
          />
        ) : (
          <>
            <InputLabel htmlFor="orgInput">Organization</InputLabel>
            <Input id="orgInput" onChange={e => onChange({ organization: e.target.value })} value={organization} />
          </>
        )}
        <FormHelperText>The Organization that this repo will belong to</FormHelperText>
      </FormControl>
      <FormControl margin="normal" required error={rawErrors?.length > 0 && !owner}>
        {allowedOwners?.length ? (
          <Select
            native
            label="Owner"
            onChange={s => onChange({ owner: String(Array.isArray(s) ? s[0] : s) })}
            disabled={allowedOwners.length === 1}
            selected={owner}
            items={ownerItems}
          />
        ) : (
          <>
            <InputLabel htmlFor="ownerInput">Project</InputLabel>
            <Input id="ownerInput" onChange={e => onChange({ owner: e.target.value })} value={owner} />
          </>
        )}
        <FormHelperText>The Project that this repo will belong to</FormHelperText>
      </FormControl>
    </>
  );
};
