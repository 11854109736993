import { Entity, CompoundEntityRef, getCompoundEntityRef, ANNOTATION_ORIGIN_LOCATION } from '@backstage/catalog-model';
import { catalogApiRef } from '../../api';
import { useCallback } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { useApi } from '@backstage/core-plugin-api';

/**
 * Each distinct state that the dialog can be in at any given time.
 */
export type UseUnregisterEntityDialogState =
  | {
      type: 'loading';
    }
  | {
      type: 'error';
      error: Error;
    }
  | {
      type: 'bootstrap';
      location: string;
      deleteEntity: () => Promise<void>;
    }
  | {
      type: 'unregister';
      location: string;
      colocatedEntities: CompoundEntityRef[];
      unregisterLocation: () => Promise<void>;
      deleteEntity: () => Promise<void>;
    }
  | {
      type: 'only-delete';
      deleteEntity: () => Promise<void>;
    };

/**
 * Houses the main logic for unregistering entities and their locations.
 */
export function useUnregisterEntityDialogState(entity: Entity): UseUnregisterEntityDialogState {
  const catalogApi = useApi(catalogApiRef);
  const locationRef = entity.metadata.annotations?.[ANNOTATION_ORIGIN_LOCATION];
  const uid = entity.metadata.uid;
  const isBootstrap = locationRef === 'bootstrap:bootstrap';

  // Load the prerequisite data: what entities that are colocated with us, and
  // what location that spawned us
  const prerequisites = useAsync(async () => {
    const locationPromise = catalogApi.getLocationByRef(locationRef!);

    let colocatedEntitiesPromise: Promise<Entity[]>;
    if (!locationRef) {
      colocatedEntitiesPromise = Promise.resolve([]);
    } else {
      const locationAnnotationFilter = `metadata.annotations.${ANNOTATION_ORIGIN_LOCATION}`;
      colocatedEntitiesPromise = catalogApi
        .getEntities({
          filter: { [locationAnnotationFilter]: locationRef },
          fields: ['kind', 'metadata.uid', 'metadata.name', 'metadata.namespace'],
        })
        .then(response => response.items);
    }

    return Promise.all([locationPromise, colocatedEntitiesPromise]).then(([location, colocatedEntities]) => ({
      location,
      colocatedEntities,
    }));
  }, [catalogApi, entity]);

  // Unregisters the underlying location which will remove all of the entities that are spawned from
  // it. Can only ever be called when the prerequisites have finished loading successfully, and if
  // there was a matching location.
  const unregisterLocation = useCallback(
    async function unregisterLocationFn() {
      const { location } = prerequisites.value!;
      await catalogApi.removeLocationById(location!.id);
    },
    [catalogApi, prerequisites],
  );

  // Just removes the entity, without affecting locations in any way.
  const deleteEntity = useCallback(
    async function deleteEntityFn() {
      // Due to the increasing catalog features there is a need to clean up some content after the entity is unregistered
      // for this purpose entity deletion process is customized and wrapped with some extra business logic
      // and that is why passed through entity-management plugin
      // eslint-disable-next-line new-cap
      await catalogApi.CUSTOM_removeEntityByUid(uid!);
    },
    [catalogApi, uid],
  );

  // If this is a bootstrap location entity, don't even block on loading
  // prerequisites. We know that all that we will do is to offer to remove the
  // entity, and that doesn't require anything from the prerequisites.
  if (isBootstrap) {
    return { type: 'bootstrap', location: locationRef!, deleteEntity };
  }

  // Return early if prerequisites still loading or failing
  const { loading, error, value } = prerequisites;
  if (loading) {
    return { type: 'loading' };
  } else if (error) {
    return { type: 'error', error };
  }

  const { location, colocatedEntities } = value!;
  if (!location) {
    return { type: 'only-delete', deleteEntity };
  }
  return {
    type: 'unregister',
    location: locationRef!,
    colocatedEntities: colocatedEntities.map(getCompoundEntityRef),
    unregisterLocation,
    deleteEntity,
  };
}
