import React from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import { BackstageTheme } from '@backstage/theme';
import { WebFileSystemAccess } from '../../lib/filesystem';

const useStyles = makeStyles(() => {
  const theme = useTheme<BackstageTheme>();
  return {
    introText: {
      textAlign: 'center',
      marginTop: theme.spacing(2),
    },
    card: {
      position: 'relative',
      maxWidth: 340,
      marginTop: theme.spacing(4),
      margin: theme.spacing(0, 2),
    },
    infoIcon: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
  };
});

interface EditorIntroProps {
  style?: JSX.IntrinsicElements['div']['style'];
  onSelect?: (option: 'local' | 'form' | 'field-explorer') => void;
}

export function TemplateEditorIntro(props: EditorIntroProps) {
  const classes = useStyles();
  const supportsLoad = WebFileSystemAccess.isSupported();

  const cardLoadLocal = (
    <Card className={classes.card} elevation={4}>
      <CardActionArea disabled={!supportsLoad} onClick={() => props.onSelect?.('local')}>
        <CardContent>
          <Typography variant="h4" component="h3" gutterBottom color={supportsLoad ? undefined : 'textSecondary'} style={{ display: 'flex', flexFlow: 'row nowrap' }}>
            Load Template Directory
          </Typography>
          <Typography variant="body1" color={supportsLoad ? undefined : 'textSecondary'}>
            Load a local template directory, allowing you to both edit and try executing your own template.
          </Typography>
        </CardContent>
      </CardActionArea>
      {!supportsLoad && (
        <div className={classes.infoIcon}>
          <Tooltip placement="top" title="Only supported in some Chromium-based browsers">
            <InfoOutlinedIcon />
          </Tooltip>
        </div>
      )}
    </Card>
  );

  const cardFormEditor = (
    <Card className={classes.card} elevation={4}>
      <CardActionArea onClick={() => props.onSelect?.('form')}>
        <CardContent>
          <Typography variant="h4" component="h3" gutterBottom>
            Edit Template Form
          </Typography>
          <Typography variant="body1">Preview and edit a template form, either using a sample template or by loading a template from the catalog.</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );

  const cardFieldExplorer = (
    <Card className={classes.card} elevation={4}>
      <CardActionArea onClick={() => props.onSelect?.('field-explorer')}>
        <CardContent>
          <Typography variant="h4" component="h3" gutterBottom>
            Custom Field Explorer
          </Typography>
          <Typography variant="body1">View and play around with available installed custom field extensions.</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );

  return (
    <div style={props.style}>
      <Typography variant="h4" component="h2" className={classes.introText}>
        Get started by choosing one of the options below
      </Typography>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          alignItems: 'flex-start',
          justifyContent: 'center',
          alignContent: 'flex-start',
        }}
      >
        {supportsLoad && cardLoadLocal}
        {cardFormEditor}
        {!supportsLoad && cardLoadLocal}
        {cardFieldExplorer}
      </div>
    </div>
  );
}
