import { createUnifiedTheme, genPageTheme, shapes } from '@backstage/theme';
import colorSet from './palettes/colors';
import darkThemePalette from './palettes/darkThemePalette';

const tmaticDarkTheme = createUnifiedTheme({
  palette: {
    ...darkThemePalette,
  },
  defaultPageTheme: 'home',
  fontFamily: 'Inter',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({
      colors: [colorSet.system.blue.dark, colorSet.system.red.dark],
      shape: shapes.round,
    }),
  },
});

export default tmaticDarkTheme;
