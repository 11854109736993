import { BackstageTheme } from '@backstage/theme';
import Grid from '@material-ui/core/Grid';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { PropsWithChildren } from 'react';
import { Link } from '../../components';

/** @public */
export type HeaderLabelClassKey = 'root' | 'label' | 'value';

const useStyles = makeStyles<BackstageTheme>(
  theme => ({
    root: {
      textAlign: 'left',
    },
    label: {
      color: theme.page.fontColor,
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: 0,
      fontSize: theme.typography.fontSize,
      marginBottom: theme.spacing(1) / 2,
      lineHeight: 1,
    },
    value: {
      color: alpha(theme.page.fontColor, 0.8),
      fontSize: theme.typography.fontSize,
      lineHeight: 1,
    },
  }),
  { name: 'BackstageHeaderLabel' },
);

type HeaderLabelContentProps = PropsWithChildren<{
  value: React.ReactNode;
  className: string;
}>;

const HeaderLabelContent = ({ value, className }: HeaderLabelContentProps) => {
  return (
    <Typography component={typeof value === 'string' ? 'p' : 'span'} className={className}>
      {value}
    </Typography>
  );
};

type HeaderLabelProps = {
  label: string;
  value?: HeaderLabelContentProps['value'];
  url?: string;
};

/**
 * Additional label to main {@link Header}
 *
 * @public
 *
 */
export function HeaderLabel(props: HeaderLabelProps) {
  const { label, value, url } = props;
  const classes = useStyles();
  const content = <HeaderLabelContent className={classes.value} value={value || '<Unknown>'} />;
  return (
    <Grid item>
      <Typography component="span" className={classes.root}>
        <Typography className={classes.label}>{label}</Typography>
        {url ? <Link to={url}>{content}</Link> : content}
      </Typography>
    </Grid>
  );
}
