import { BackstageTheme } from '@backstage/theme';
import { Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { CodeSnippet } from '@backstage/core-components';

const ENTITY_YAML = `metadata:
  name: example
  labels:
    javaVersion: 1.2.3`;

const useStyles = makeStyles<BackstageTheme>(
  theme => ({
    code: {
      borderRadius: 6,
      margin: theme.spacing(2, 0),
      background: theme.palette.type === 'dark' ? '#444' : theme.palette.common.white,
    },
  }),
  { name: 'PluginCatalogEntityLabelsEmptyState' },
);

export function EntityLabelsEmptyState() {
  const classes = useStyles();

  return (
    <>
      <Typography variant="body1">No labels defined for this entity. You can add labels to your entity YAML as shown in the highlighted example below:</Typography>
      <div className={classes.code}>
        <CodeSnippet text={ENTITY_YAML} language="yaml" showLineNumbers highlightedNumbers={[3, 4, 5, 6]} customStyle={{ background: 'inherit', fontSize: '115%' }} />
      </div>
      <Button variant="contained" color="primary" target="_blank" href="https://backstage.io/docs/features/software-catalog/descriptor-format#labels-optional">
        Read more
      </Button>
    </>
  );
}
