import { AlphaEntity } from '@backstage/catalog-model/alpha';
import Box from '@mui/material/Box';
import DialogContentText from '@mui/material/DialogContentText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import React from 'react';
import { EntityRefLink } from '../../EntityRefLink';
import { Container, KeyValueListItem, ListItemText, ListSubheader } from './common';
import { EntityKindIcon } from './EntityKindIcon';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export function OverviewPage(props: { entity: AlphaEntity }) {
  const classes = useStyles();
  const { apiVersion, kind, metadata, spec, relations = [], status = {} } = props.entity;

  const groupedRelations = groupBy(
    sortBy(relations, r => r.targetRef),
    'type',
  );

  return (
    <>
      <DialogContentText variant="h2">Overview</DialogContentText>
      <div className={classes.root}>
        <Container title="Identity">
          <List dense>
            <ListItem>
              <ListItemText primary="apiVersion" secondary={apiVersion} />
            </ListItem>
            <ListItem>
              <ListItemText primary="kind" secondary={kind} />
            </ListItem>
            {spec?.type && (
              <ListItem>
                <ListItemText primary="spec.type" secondary={spec.type?.toString()} />
              </ListItem>
            )}
            {metadata.uid && (
              <ListItem>
                <ListItemText primary="uid" secondary={metadata.uid} />
              </ListItem>
            )}
            {metadata.etag && (
              <ListItem>
                <ListItemText primary="etag" secondary={metadata.etag} />
              </ListItem>
            )}
          </List>
        </Container>

        <Container title="Metadata">
          {!!Object.keys(metadata.annotations || {}).length && (
            <List dense subheader={<ListSubheader>Annotations</ListSubheader>}>
              {Object.entries(metadata.annotations!).map(entry => (
                <KeyValueListItem key={entry[0]} indent entry={entry} />
              ))}
            </List>
          )}
          {!!Object.keys(metadata.labels || {}).length && (
            <List dense subheader={<ListSubheader>Labels</ListSubheader>}>
              {Object.entries(metadata.labels!).map(entry => (
                <KeyValueListItem key={entry[0]} indent entry={entry} />
              ))}
            </List>
          )}
          {!!metadata.tags?.length && (
            <List dense subheader={<ListSubheader>Tags</ListSubheader>}>
              {metadata.tags.map((tag, index) => (
                <ListItem key={`${tag}-${index}`}>
                  <ListItemIcon />
                  <ListItemText primary={tag} />
                </ListItem>
              ))}
            </List>
          )}
        </Container>

        {!!relations.length && (
          <Container title="Relations" helpLink="https://backstage.io/docs/features/software-catalog/well-known-relations">
            {Object.entries(groupedRelations).map(([type, groupRelations], index) => (
              <div key={index}>
                <List dense subheader={<ListSubheader>{type}</ListSubheader>}>
                  {groupRelations.map(group => (
                    <ListItem key={group.targetRef}>
                      <ListItemIcon>
                        <EntityKindIcon entityRef={group.targetRef} />
                      </ListItemIcon>
                      <ListItemText primary={<EntityRefLink entityRef={group.targetRef} />} />
                    </ListItem>
                  ))}
                </List>
              </div>
            ))}
          </Container>
        )}

        {!!status.items?.length && (
          <Container title="Status" helpLink="https://backstage.io/docs/features/software-catalog/well-known-statuses">
            {status.items.map((item, index) => (
              <div key={index}>
                <Typography>
                  {item.level}: {item.type}
                </Typography>
                <Box ml={2}>{item.message}</Box>
              </div>
            ))}
          </Container>
        )}
      </div>
    </>
  );
}
