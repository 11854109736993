import { WelcomeTitle, HeaderWorldClock, ClockConfig, HomePageStarredEntities } from '@backstage/plugin-home';
import { Content, Header, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';

// import { HomePageCalendar } from '@backstage/plugin-gcalendar';
// import { MicrosoftCalendarCard } from '@backstage/plugin-microsoft-calendar';
import React from 'react';
import Grid from '@mui/material/Grid';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
// import ListItemText from '@mui/material/ListItemText';
// import Switch from '@mui/material/Switch';
// import Tooltip from '@mui/material/Tooltip';
// import HomeIcon from '@mui/icons-material/Home';
// import { HomePagePagerDutyCard } from '@backstage/plugin-pagerduty';

const clockConfigs: ClockConfig[] = [
  {
    label: 'CST',
    timeZone: 'CST',
  },
  {
    label: 'UTC',
    timeZone: 'UTC',
  },
];

const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};

export const homePage = (
  <Page themeId="home">
    <Header title={<WelcomeTitle />} pageTitleOverride="Home">
      <HeaderWorldClock clockConfigs={clockConfigs} customTimeFormat={timeFormat} />
    </Header>
    <Content>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item lg={12} md={6} xs={12}>
          <HomePageSearchBar />
        </Grid>
        <Grid item md={6} xs={12}>
          <HomePageStarredEntities />
        </Grid>
      </Grid>
    </Content>
  </Page>
);
