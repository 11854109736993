import { BackstageTypography, PageTheme, PageThemeSelector } from './types';
import { pageTheme as defaultPageThemes } from './pageTheme';

const DEFAULT_HTML_FONT_SIZE = 16;
const DEFAULT_FONT_FAMILY = '"Helvetica Neue", Helvetica, Roboto, Arial, sans-serif';
const DEFAULT_PAGE_THEME = 'home';

/**
 * Options for {@link createBaseThemeOptions}.
 *
 * @public
 */
export interface BaseThemeOptionsInput<PaletteOptions> {
  palette: PaletteOptions;
  defaultPageTheme?: string;
  pageTheme?: Record<string, PageTheme>;
  fontFamily?: string;
  htmlFontSize?: number;
  typography?: BackstageTypography;
}

/**
 * A helper for creating theme options.
 *
 * @public
 */
export function createBaseThemeOptions<PaletteOptions>(options: BaseThemeOptionsInput<PaletteOptions>) {
  const { palette, htmlFontSize = DEFAULT_HTML_FONT_SIZE, fontFamily = DEFAULT_FONT_FAMILY, defaultPageTheme = DEFAULT_PAGE_THEME, pageTheme = defaultPageThemes } = options;

  if (!pageTheme[defaultPageTheme]) {
    throw new Error(`${defaultPageTheme} is not defined in pageTheme.`);
  }

  return {
    palette,
    typography: {
      htmlFontSize,
      fontFamily,
      h1: {
        fontSize: '40px',
        fontWeight: 700,
        lineHeight: '54px',
      },
      h2: {
        fontSize: '32px',
        fontWeight: 700,
        lineHeight: '44px',
      },
      h3: {
        fontSize: '28px',
        fontWeight: 700,
        lineHeight: '38px',
      },
      h4: {
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '32px',
      },
      h5: {
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '28px',
      },
      h6: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '22px',
      },
      body1: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
      },
      body2: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
      },
      overline: {
        fontSize: '14px',
        letterSpacing: 2,
        fontWeight: 400,
      },
      caption: {
        fontSize: '12px',
        fontWeight: 400,
      },
    },
    page: pageTheme[defaultPageTheme],
    getPageTheme: ({ themeId }: PageThemeSelector) => pageTheme[themeId] ?? pageTheme[defaultPageTheme],
  };
}
