import { BackstageTheme } from '@backstage/theme';
import { configApiRef, IconComponent, useApi } from '@backstage/core-plugin-api';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import StarIcon from '@mui/icons-material/Star';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { EntityUserFilter } from '../../filters';
import { useEntityList } from '../../hooks';
import { UserListFilterKind } from '../../types';
import { useOwnedEntitiesCount } from './useOwnedEntitiesCount';
import { useAllEntitiesCount } from './useAllEntitiesCount';
import { useStarredEntitiesCount } from './useStarredEntitiesCount';

/** @public */
export type CatalogReactUserListPickerClassKey = 'root' | 'title' | 'listIcon' | 'menuItem' | 'groupWrapper';

const useStyles = makeStyles(
  () => {
    const theme = useTheme<BackstageTheme>();
    return {
      root: {
        backgroundColor: 'rgba(0, 0, 0, .11)',
        boxShadow: 'none',
        margin: theme.spacing(1, 0, 1, 0),
        gap: 'unset !important',
        display: 'flex !important',
        alignItems: 'initial !important',
      },
      title: {
        margin: theme.spacing(1, 0, 0, 1),
        textTransform: 'uppercase',
        fontSize: 12,
        fontWeight: 'bold',
      },
      listIcon: {
        minWidth: 30,
        color: theme.palette.text.primary,
      },
      menuItem: {
        minHeight: theme.spacing(6),
        width: '100%',
      },
      groupWrapper: {
        margin: theme.spacing(1, 1, 2, 1),
        borderRadius: '8px',
        padding: '0 !important',
      },
      listRoot: {
        marginBottom: '-1px',
      },
    };
  },
  {
    name: 'CatalogReactUserListPicker',
  },
);

export type ButtonGroup = {
  name: string;
  items: {
    id: 'owned' | 'starred' | 'all';
    label: string;
    icon?: IconComponent;
  }[];
};

function getFilterGroups(orgName: string | undefined): ButtonGroup[] {
  return [
    {
      name: 'Personal',
      items: [
        {
          id: 'owned',
          label: 'Owned',
          icon: SettingsIcon as any,
        },
        {
          id: 'starred',
          label: 'Starred',
          icon: StarIcon as any,
        },
      ],
    },
    {
      name: orgName ?? 'Company',
      items: [
        {
          id: 'all',
          label: 'All',
        },
      ],
    },
  ];
}

/** @public */
export type UserListPickerProps = {
  initialFilter?: UserListFilterKind;
  availableFilters?: UserListFilterKind[];
};

/** @public */
export const UserListPicker = (props: UserListPickerProps) => {
  const { initialFilter, availableFilters } = props;
  const classes = useStyles();
  const configApi = useApi(configApiRef);
  const orgName = configApi.getOptionalString('organization.name') ?? 'Company';
  const {
    filters,
    updateFilters,
    queryParameters: { kind: kindParameter, user: userParameter },
  } = useEntityList();

  // Remove group items that aren't in availableFilters and exclude
  // any now-empty groups.
  const userAndGroupFilterIds = ['starred', 'all'];
  const filterGroups = getFilterGroups(orgName)
    .map(filterGroup => ({
      ...filterGroup,
      items: filterGroup.items.filter(({ id }) =>
        // TODO: avoid hardcoding kinds here
        ['group', 'user'].some(kind => kind === kindParameter) ? userAndGroupFilterIds.includes(id) : !availableFilters || availableFilters.includes(id),
      ),
    }))
    .filter(({ items }) => !!items.length);

  const { count: ownedEntitiesCount, loading: loadingOwnedEntities, filter: ownedEntitiesFilter } = useOwnedEntitiesCount();
  const { count: allCount } = useAllEntitiesCount();
  const { count: starredEntitiesCount, filter: starredEntitiesFilter, loading: loadingStarredEntities } = useStarredEntitiesCount();

  const queryParamUserFilter = useMemo(() => [userParameter].flat()[0], [userParameter]);

  const [selectedUserFilter, setSelectedUserFilter] = useState((queryParamUserFilter as UserListFilterKind) ?? initialFilter);

  const filterCounts = useMemo(() => {
    return {
      all: allCount,
      starred: starredEntitiesCount,
      owned: ownedEntitiesCount,
    };
  }, [starredEntitiesCount, ownedEntitiesCount, allCount]);

  // Set selected user filter on query parameter updates; this happens at initial page load and from
  // external updates to the page location.
  useEffect(() => {
    if (queryParamUserFilter) {
      setSelectedUserFilter(queryParamUserFilter as UserListFilterKind);
    }
  }, [queryParamUserFilter]);

  const loading = loadingOwnedEntities || loadingStarredEntities;

  useEffect(() => {
    if (!loading && !!selectedUserFilter && selectedUserFilter !== 'all' && filterCounts[selectedUserFilter] === 0) {
      setSelectedUserFilter('all');
    }
  }, [loading, filterCounts, selectedUserFilter, setSelectedUserFilter]);

  useEffect(() => {
    if (!selectedUserFilter) {
      return;
    }
    if (loading) {
      return;
    }

    const getFilter = () => {
      if (selectedUserFilter === 'owned') {
        return ownedEntitiesFilter;
      }
      if (selectedUserFilter === 'starred') {
        return starredEntitiesFilter;
      }
      return EntityUserFilter.all();
    };

    updateFilters({ user: getFilter() });
  }, [selectedUserFilter, starredEntitiesFilter, ownedEntitiesFilter, updateFilters, loading]);

  return (
    <Card className={classes.root}>
      {filterGroups.map(group => (
        <Fragment key={group.name}>
          <Typography variant="subtitle2" component="span" className={classes.title}>
            {group.name}
          </Typography>
          <Card className={classes.groupWrapper}>
            <List disablePadding dense role="menu" className={classes.listRoot} aria-label={group.name}>
              {group.items.map(item => (
                <MenuItem
                  role="none presentation"
                  key={item.id}
                  // button
                  divider
                  onClick={() => setSelectedUserFilter(item.id)}
                  selected={item.id === filters.user?.value}
                  className={classes.menuItem}
                  disabled={filterCounts[item.id] === 0}
                  data-testid={`user-picker-${item.id}`}
                  tabIndex={0}
                  // ContainerProps={{ role: 'menuitem' }}
                >
                  {item.icon && (
                    <ListItemIcon className={classes.listIcon}>
                      <item.icon fontSize="small" />
                    </ListItemIcon>
                  )}
                  <ListItemText>
                    <Typography variant="body1">{item.label} </Typography>
                  </ListItemText>
                  <ListItemSecondaryAction>{filterCounts[item.id] ?? '-'}</ListItemSecondaryAction>
                </MenuItem>
              ))}
            </List>
          </Card>
        </Fragment>
      ))}
    </Card>
  );
};
