import { createTheme } from './baseTheme';
import { palettes } from '../base';

/**
 * The old Material UI v4 Backstage light theme.
 *
 * @public
 * @deprecated Use {@link themes.light} instead.
 */
export const lightTheme = createTheme({
  palette: palettes.light,
});

/**
 * The old Material UI v4 Backstage dark theme.
 *
 * @public
 * @deprecated Use {@link themes.dark} instead.
 */
export const darkTheme = createTheme({
  palette: palettes.dark,
});
