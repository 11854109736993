import { makeStyles } from '@material-ui/core/styles';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import React, { useState } from 'react';
import TextTruncate, { TextTruncateProps } from 'react-text-truncate';
import { useIsMounted } from '@react-hookz/web';

type Props = {
  text: TextTruncateProps['text'];
  line?: TextTruncateProps['line'];
  element?: TextTruncateProps['element'];
  title?: TooltipProps['title'];
  placement?: TooltipProps['placement'];
};

export type OverflowTooltipClassKey = 'container';

const useStyles = makeStyles(
  {
    container: {
      overflow: 'visible !important',
    },
  },
  { name: 'BackstageOverflowTooltip' },
);

export function OverflowTooltip(props: Props) {
  const [hover, setHover] = useState(false);
  const isMounted = useIsMounted();
  const classes = useStyles();

  const handleToggled = (truncated: boolean) => {
    if (isMounted()) {
      setHover(truncated);
    }
  };

  return (
    <Tooltip title={props.title ?? (props.text || '')} placement={props.placement} disableHoverListener={!hover}>
      <TextTruncate text={props.text} line={props.line} onToggled={handleToggled} containerClassName={classes.container} />
    </Tooltip>
  );
}
