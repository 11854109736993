import { Entity, DEFAULT_NAMESPACE } from '@backstage/catalog-model';
import { createRouteRef } from '@backstage/core-plugin-api';
import { getOrCreateGlobalSingleton } from '@backstage/version-bridge';

/**
 * A stable route ref that points to the catalog page for an individual entity.
 *
 * This `RouteRef` can be imported and used directly, and does not need to be referenced
 * via an `ExternalRouteRef`.
 *
 * If you want to replace the `EntityPage` from `@backstage/catalog-plugin` in your app,
 * you need to use the `entityRouteRef` as the mount point instead of your own.
 * @public
 */
export const entityRouteRef = getOrCreateGlobalSingleton('catalog:entity-route-ref', () =>
  createRouteRef<{ name: string; namespace: string; kind: string }, 'name' | 'kind' | 'namespace'>({
    id: 'catalog:entity',
    params: ['namespace', 'kind', 'name'],
  }),
);

/**
 * Utility function to get suitable route params for entityRoute, given an
 * @public
 */
export function entityRouteParams(entity: Entity) {
  return {
    kind: entity.kind.toLocaleLowerCase('en-US'),
    namespace: entity.metadata.namespace?.toLocaleLowerCase('en-US') ?? DEFAULT_NAMESPACE,
    name: entity.metadata.name,
  } as const;
}
