import Box from '@mui/material/Box';
import { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useEffect, useMemo, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import { catalogApiRef } from '../../api';
import { EntityAutocompletePickerOption } from './EntityAutocompletePickerOption';
import { EntityAutocompletePickerInput } from './EntityAutocompletePickerInput';
import { DefaultEntityFilters, useEntityList } from '../../hooks/useEntityListProvider';
import { EntityFilter } from '../../types';

type KeysMatchingCondition<T, V, K> = T extends V ? K : never;
type KeysMatching<T, V> = {
  [K in keyof T]-?: KeysMatchingCondition<T[K], V, K>;
}[keyof T];

export type AllowedEntityFilters<T extends DefaultEntityFilters> = KeysMatching<T, EntityFilter & { values: string[] }>;

interface ConstructableFilter<T> {
  new (values: string[]): T;
}

/** @public */
export type EntityAutocompletePickerProps<T extends DefaultEntityFilters = DefaultEntityFilters, Name extends AllowedEntityFilters<T> = AllowedEntityFilters<T>> = {
  label: string;
  name: Name;
  path: string;
  showCounts?: boolean;
  Filter: ConstructableFilter<NonNullable<T[Name]>>;
  InputProps?: TextFieldProps;
  initialSelectedOptions?: string[];
};

/** @public */
export function EntityAutocompletePicker<T extends DefaultEntityFilters = DefaultEntityFilters, Name extends AllowedEntityFilters<T> = AllowedEntityFilters<T>>(
  props: EntityAutocompletePickerProps<T, Name>,
) {
  const { label, name, path, showCounts, Filter, InputProps, initialSelectedOptions = [] } = props;

  const {
    updateFilters,
    filters,
    queryParameters: { [name]: queryParameter },
  } = useEntityList<T>();

  const catalogApi = useApi(catalogApiRef);
  const { value: availableValues } = useAsync(async () => {
    const facet = path;
    const { facets } = await catalogApi.getEntityFacets({
      facets: [facet],
      filter: filters.kind?.getCatalogFilters(),
    });

    return Object.fromEntries(facets[facet].map(({ value, count }) => [value, count]));
  }, [filters.kind]);

  const queryParameters = useMemo(() => [queryParameter].flat().filter(Boolean) as string[], [queryParameter]);

  const [selectedOptions, setSelectedOptions] = useState(
    queryParameters.length ? queryParameters : (filters[name] as unknown as { values: string[] })?.values ?? initialSelectedOptions,
  );

  // Set selected options on query parameter updates; this happens at initial page load and from
  // external updates to the page location
  useEffect(() => {
    if (queryParameters.length) {
      setSelectedOptions(queryParameters);
    }
  }, [queryParameters]);

  const availableOptions = Object.keys(availableValues ?? {});
  const shouldAddFilter = selectedOptions.length && availableOptions.length;

  useEffect(() => {
    updateFilters({
      [name]: shouldAddFilter ? new Filter(selectedOptions) : undefined,
    } as Partial<T>);
  }, [name, shouldAddFilter, selectedOptions, Filter, updateFilters]);

  const filter = filters[name];
  if ((filter && typeof filter === 'object' && !('values' in filter)) || !availableOptions.length) {
    return null;
  }

  // Hide if there are 1 or fewer options; nothing to pick from
  if (availableOptions.length <= 1) return null;

  return (
    <Box pb={1} pt={1}>
      <Typography variant="button" component="label">
        {label}
        <Autocomplete
          multiple
          disableCloseOnSelect
          options={availableOptions}
          value={selectedOptions}
          onChange={(_event: object, options: string[]) => setSelectedOptions(options)}
          renderOption={(pickerProps, option, { selected }) => (
            <EntityAutocompletePickerOption pickerProps={pickerProps} selected={selected} value={option} availableOptions={availableValues} showCounts={!!showCounts} />
          )}
          size="small"
          popupIcon={<ExpandMoreIcon data-testid={`${String(name)}-picker-expand`} />}
          renderInput={params => <EntityAutocompletePickerInput {...params} {...InputProps} />}
        />
      </Typography>
    </Box>
  );
}
