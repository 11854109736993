import { makeStyles, Theme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import React, { useMemo, useState } from 'react';
import useObservable from 'react-use/lib/useObservable';
import LoginRequestListItem from './LoginRequestListItem';
import { useApi, configApiRef, oauthRequestApiRef } from '@backstage/core-plugin-api';
import Typography from '@material-ui/core/Typography';

export type OAuthRequestDialogClassKey = 'dialog' | 'title' | 'contentList' | 'actionButtons';

const useStyles = makeStyles<Theme>(
  theme => ({
    dialog: {
      paddingTop: theme.spacing(1),
    },
    title: {
      minWidth: 0,
    },
    titleHeading: {
      fontSize: theme.typography.h6.fontSize,
    },
    contentList: {
      padding: 0,
    },
    actionButtons: {
      padding: theme.spacing(2, 2),
    },
  }),
  { name: 'OAuthRequestDialog' },
);

export function OAuthRequestDialog(_props: {}) {
  const classes = useStyles();
  const [busy, setBusy] = useState(false);
  const oauthRequestApi = useApi(oauthRequestApiRef);
  const configApi = useApi(configApiRef);

  const authRedirect = configApi.getOptionalBoolean('enableExperimentalRedirectFlow') ?? false;

  const requests = useObservable(
    useMemo(() => oauthRequestApi.authRequest$(), [oauthRequestApi]),
    [],
  );

  const handleRejectAll = () => {
    requests.forEach(request => request.reject());
  };

  return (
    <Dialog open={Boolean(requests.length)} fullWidth maxWidth="xs" classes={{ paper: classes.dialog }} aria-labelledby="oauth-req-dialog-title">
      <main>
        <DialogTitle classes={{ root: classes.title }} id="oauth-req-dialog-title">
          <Typography className={classes.titleHeading} variant="h1" variantMapping={{ h1: 'span' }}>
            Login Required
          </Typography>
          {authRedirect ? <Typography>This will trigger a http redirect to OAuth Login.</Typography> : null}
        </DialogTitle>

        <DialogContent dividers classes={{ root: classes.contentList }}>
          <List>
            {requests.map(request => (
              <LoginRequestListItem key={request.provider.title} request={request} busy={busy} setBusy={setBusy} />
            ))}
          </List>
        </DialogContent>
      </main>

      <DialogActions classes={{ root: classes.actionButtons }}>
        <Button onClick={handleRejectAll} variant="outlined">
          Reject All
        </Button>
      </DialogActions>
    </Dialog>
  );
}
