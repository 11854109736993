import { DefaultShortcutsApi, shortcutsApiRef } from './api';
import { createApiFactory, createComponentExtension, createPlugin, storageApiRef } from '@backstage/core-plugin-api';

/** @public */
export const shortcutsPlugin = createPlugin({
  id: 'shortcuts',
  apis: [
    createApiFactory({
      api: shortcutsApiRef,
      deps: { storageApi: storageApiRef },
      factory: ({ storageApi }) => new DefaultShortcutsApi(storageApi.forBucket('shortcuts')),
    }),
  ],
});

/** @public */
export const Shortcuts = shortcutsPlugin.provide(
  createComponentExtension({
    name: 'Shortcuts',
    component: { lazy: () => import('./Shortcuts').then(m => m.Shortcuts) },
  }),
);
