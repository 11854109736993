import { CodeSnippet, InfoCard, Progress, Table, TableColumn, TableProps, WarningPanel } from '@backstage/core-components';
import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import makeStyles from '@mui/styles/makeStyles';
import { BackstageTheme } from '@backstage/theme';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@material-ui/core';
import _ from 'lodash';

const useStyles = makeStyles({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)',
    minHeight: '200px',
  },
  gridItemCardContent: { flex: 1 },
  defaultEmptyContent: {
    textAlign: 'center',
  },
});

const DEFAULT_TABLE_OPTIONS: TableProps['options'] = {
  toolbar: false,
  paging: false,
  search: false,
  draggable: false,
  sorting: false,
  padding: 'dense',
  emptyRowsWhenPaging: false,
};

export const CardTMatic = <T extends object>(props: {
  data: T[];
  cardTitle: string;
  tableTitle?: string;
  columns: TableColumn<T>[];
  options?: TableProps['options'];
  action?: React.JSX.Element & React.ReactNode;
  emptyText?: string;
  loading?: boolean;
  error?: Error;
}) => {
  const classes = useStyles();
  const theme = useTheme<BackstageTheme>();

  const styles: React.CSSProperties = {};
  if (theme.palette.type === 'dark') {
    styles.backgroundColor = '#28293c';
  }

  const defaultEmptyText = 'No records to display';

  const { data, cardTitle, columns, options = {}, tableTitle, action, emptyText = defaultEmptyText, loading, error } = props;

  const emptyContent = (
    <div className={classes.defaultEmptyContent} style={{ ...styles }}>
      <Typography variant="body1">{emptyText}</Typography>
    </div>
  );

  if (loading) {
    return (
      <InfoCard variant="gridItem" title={cardTitle}>
        <Progress />
      </InfoCard>
    );
  }

  if (error) {
    const message = <CodeSnippet text={`${error.cause?.message || error}`} language="text" />;
    return (
      <InfoCard variant="gridItem" title={cardTitle}>
        <WarningPanel severity="error" title={`Could not load ${cardTitle}`} message={message} />
      </InfoCard>
    );
  }

  let mergedOptions: {};
  if (_.size(data)) {
    mergedOptions = { ...DEFAULT_TABLE_OPTIONS, ...options };
  } else {
    mergedOptions = { ...DEFAULT_TABLE_OPTIONS };
  }

  return (
    <Card className={classes.gridItemCard}>
      <CardHeader action={action} style={{ paddingBottom: '15px', ...styles }} title={cardTitle} />
      <Divider />
      <CardContent className={classes.gridItemCardContent} style={{ ...styles }}>
        <Table<T> columns={columns} options={mergedOptions} data={data} title={tableTitle} emptyContent={emptyContent} />
      </CardContent>
    </Card>
  );
};
