import React, { useEffect } from 'react';
import { Select, SelectItem } from '@backstage/core-components';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { useCommonStyles } from './styles';
import { CustomFormLabel } from './CustomFormLabel';

export interface RepoUrlPickerRepoNameProps {
  repoName?: string;
  allowedRepos?: string[];
  onChange: (host: string) => void;
  rawErrors: string[];
  disabled?: boolean;
}

export const RepoUrlPickerRepoName = (props: RepoUrlPickerRepoNameProps) => {
  const classes = useCommonStyles();
  const { repoName, allowedRepos, onChange, rawErrors, disabled } = props;

  useEffect(() => {
    // If there is no repoName chosen currently
    if (!repoName) {
      // Set the first of the allowedRepos option if that available
      if (allowedRepos?.length) {
        onChange(allowedRepos[0]);
      }
    }
  }, [allowedRepos, repoName, onChange]);

  const repoItems: SelectItem[] = allowedRepos ? allowedRepos.map(i => ({ label: i, value: i })) : [{ label: 'Loading...', value: 'loading' }];

  return (
    <>
      <FormControl className={classes.fullWidthInput} margin="normal" required error={rawErrors?.length > 0 && !repoName}>
        {allowedRepos?.length ? (
          <Select
            className={classes.fullWidthInput}
            native
            label={(<CustomFormLabel text="Repositories Available *" pull="right" />) as unknown as string}
            onChange={selected => String(Array.isArray(selected) ? selected[0] : selected)}
            disabled={allowedRepos.length === 1 || disabled}
            selected={repoName}
            items={repoItems}
          />
        ) : (
          <TextField
            className={classes.fullWidthInput}
            id="repoNameInput"
            label={<CustomFormLabel text="Repository *" />}
            onChange={e => onChange(String(e.target.value))}
            value={repoName}
            disabled={disabled}
          />
        )}
        <FormHelperText>The name of the repository</FormHelperText>
      </FormControl>
    </>
  );
};
